import React, { useRef, useState, useContext, useEffect } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./DropZone";
import Component from "./Component";

//Mui Componants
import { Button, Menu, MenuItem, Stack } from "@mui/material";
import { showInfo, showSuccess } from "../../components/Messages";

//Mui Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PoolIcon from "@mui/icons-material/Pool";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAllEvent,
  copyAllEvent,
  pasteAllEvent,
  toggleComponentVisibility,
  handleselecteddayandWeek,
  getTrainingPlanMasterMappingByTrainingId,
  updateLastColumnDayInRow,
  updateColumnsinTrainingData,
} from "../../store/slices/MultiTrainingBlocksSlice";
import { IconPlus } from "@tabler/icons";
import GlobalContext from "../../context/GlobalContext";
import {
  copyPasteweeklyTrainingBlocks,
  createTrainingPlanMasterMaping,
  deleteTrainingPlanMasterMaping,
  getAllTrainingBlocksWorkout,
  updateTrainingPlanMasterMapingWorkoutStatusforHide,
} from "../../API/api-endpoint";
import { HiDotsVertical } from "react-icons/hi";
import Swal from "sweetalert2";
import ViewBloocks from "./ViewBlocks";
import ViewBlocks from "./ViewBlocks";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TrainingSummary from "./TrainingSummary";

const style = {};
const Column = ({ id,rowData,summaryModalVisible,setSummaryModalVisible, index, data, handleDrop, path, setCopyWorkoutData, CopyWorkoutData }) => {
  const [selecedCopyWorkoutData, setSelectedCopyWorkoutData] = React.useState();
  const [openCopyModal, setCopyOpen] = React.useState(null);
  const [isDeletedMappingId, setIsDeletedMappingId] = useState()
  const { setShowLibraryModal, setDaySelected, showLibraryModal } =
    useContext(GlobalContext);
  let dispatch = useDispatch();
  let CurrentTrainingBlock = useSelector(
    (state) => state.MultiTrainingBlocksSlice.CurrentTrainingBlock
  );

  let layout = useSelector((state) => state.MultiTrainingBlocksSlice.layout);
  let lastColumnDayInRow = useSelector(
    (state) => state.MultiTrainingBlocksSlice.lastColumnDayInRow
  );

  // console.log("layout", layout);
  const { confirm } = Modal;
  let counter = data.counter;
  let isZeroCondition = counter % 7 === 0;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSlotInfo, setSelectedSlotInfo] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [modalVisible, setModalVisible] = useState(false);
 const [openBox, setOpenBox] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [sectedCopyData, setSelectCopyData] = useState();
  
  

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const renderComponent = (component, currentPath) => {
    return <Component key={component.id} handlePastProgram={handlePastProgram} showDeleteConfirm={showDeleteConfirm} handleCopy={handleCopy} id={id} CurrentTrainingBlock={CurrentTrainingBlock} data={component} path={currentPath} openBox={openBox} setOpenBox={setOpenBox} isEditOpen={isEditOpen} setIsEditOpen={setIsEditOpen} sectedCopyData={sectedCopyData} setSelectCopyData={setSelectCopyData} selectedID={selectedID} setSelectedID={setSelectedID} />;
  };

  const handlePastProgram = async (currentPath,data) => {
    let workout_id = localStorage.getItem("workoutID")
    let week = currentPath.split('-')[0]
    let day = currentPath.split('-')[1]

    let newObject = {
      weekId: parseInt(week),
      workoutId: [workout_id],
      dayId: parseInt(day),
      trainingPlanId: CurrentTrainingBlock.id,
      status: 1,
    };
    if (workout_id) {
      console.log("currentPath", newObject, workout_id);
    let response = await createTrainingPlanMasterMaping(
      newObject
    );
    if (
      response.message === "Training plan master mapping created successfully"
    ) {
      console.log("called while pasting",data.trainingblock_id,data);
      showSuccess("Paste Successfully")
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(data.trainingblock_id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
      setTimeout(() => {
        
        localStorage.setItem("workoutID", workout_id)
      }, 2000);

    }
    }else{
      showInfo("Please copy any workout first!")
    }
    

  };

  //for getting mouse postion where modal is open
  const handleClickonMenuButton = (event, data) => {
    setIsDeletedMappingId(data?.children[0]?.training_plan_master_mapping_id)
    const xPos = event.pageX;
    const yPos = event.pageY;
    setSelectedSlotInfo({
      x: xPos,
      y: yPos,
      currentDivId: event.currentTarget.id,
    });
    setAnchorEl(event.currentTarget);

    setIsOpen(!isOpen);
  };

  const deleteAllEventFromSelectedSlot = async () => {
    // dispatch(deleteAllEvent(selectedSlotInfo.currentDivId));

    //trainingplanid  == CurrentTrainingBlock.id
    let week = id.slice(5, id.indexOf("-row"));
    let day = id.slice(id.indexOf("count-") + 6, id.indexOf("-col"));

    let newObject = {
      weekId: week,
      dayId: day,
      workout_id: null,
    };
    let response = await deleteTrainingPlanMasterMaping(
      isDeletedMappingId
    );
    if (
      response.message === "Training plan master mapping deleted successfully"
    ) {
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    }
    setAnchorEl(null);

  };

  const copyAllEventFromSelectedSlot = () => {
    //dispatch(copyAllEvent(selectedSlotInfo.currentDivId));
    localStorage.removeItem("workoutID")
    let workoutArray = [];
    let id = selectedSlotInfo.currentDivId;
    let colID = id;
    let rowID = colID.slice(0, colID.indexOf("-count"));
    // Find the row in layout
    const row = layout.find((item) => item.id === rowID);
    if (row) {
      // Find the column in the row
      const column = row.children.find((item) => item.id === colID);
      if (column) {
        // Access the children of the column
        const children = column.children;

        // Create a new array of workout_id from children
        workoutArray = children.map((child) => parseInt(child.workout_id));
        localStorage.setItem("workoutIDArray", JSON.stringify(workoutArray));
      }
    }
    setAnchorEl(null);

  };

  const pasteAllEventFromSelectedSlot = async () => {
    //dispatch(pasteAllEvent(selectedSlotInfo.currentDivId));
let workoutID = localStorage.getItem("workoutID")
    let id = selectedSlotInfo.currentDivId;
    let workoutIDArray = JSON.parse(localStorage.getItem("workoutIDArray"));
    let week = id.slice(5, id.indexOf("-row"));
    let day = id.slice(id.indexOf("count-") + 6, id.indexOf("-col"));
    

    let newObject = {
      weekId: parseInt(week),
      workoutId: workoutID?[workoutID]:workoutIDArray,
      dayId: parseInt(day),
      trainingPlanId: CurrentTrainingBlock.id,
      status: 1,
    };

    let response = await createTrainingPlanMasterMaping(newObject);
    
    if (
      response.message === "Training plan master mapping created successfully"
    ) {
      
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
      if (workoutID) {
        setTimeout(() => {
          localStorage.setItem("workoutID", workoutID)
        }, 3000);
      }
    }
    setAnchorEl(null);

  };

  const hideallEvent = async () => {
    //dispatch(toggleComponentVisibility(selectedSlotInfo.currentDivId));
    let id = selectedSlotInfo.currentDivId;
    let week = id.slice(5, id.indexOf("-row"));
    let day = id.slice(id.indexOf("count-") + 6, id.indexOf("-col"));

    let newobject = {
      status: 0, //0 for false 1 for true
      weekId: parseInt(week),
      dayId: parseInt(day),
    };

    let response = await updateTrainingPlanMasterMapingWorkoutStatusforHide(
      newobject,
      CurrentTrainingBlock.id
    );
    if (response.message == "Training plan updated successfully") {
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    }
    setAnchorEl(null);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const unhideallEvent = async () => {
    //dispatch(toggleComponentVisibility(selectedSlotInfo.currentDivId));
    let id = selectedSlotInfo.currentDivId;
    let week = id.slice(5, id.indexOf("-row"));
    let day = id.slice(id.indexOf("count-") + 6, id.indexOf("-col"));
    let newobject = {
      status: 1, //0 for false 1 for true
      weekId: parseInt(week),
      dayId: parseInt(day),
    };
    let response = await updateTrainingPlanMasterMapingWorkoutStatusforHide(
      newobject,
      CurrentTrainingBlock.id
    );

    if (response.message == "Training plan updated successfully") {
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    }
    setAnchorEl(null);

  };

  const handleLibrary = (id) => {
    const inputString = id;
    const regex = /week-(\d+)-row-(\d+)-count-(\d+)-col-(\d+)/;
    const match = inputString.match(regex);
    const weekNumber = match[1];
    const dayNumber = match[3];
    console.log("dayNumber",dayNumber,weekNumber);
    setDaySelected(1);
    if (dayNumber && weekNumber) {
      dispatch(handleselecteddayandWeek({ day: dayNumber, week: weekNumber }));
      
    }
    setShowLibraryModal(true);
  };

  useEffect(() => {
    console.log("CopyWorkoutData",CopyWorkoutData);
    
  }, [CopyWorkoutData])

  const handleCopyWeeklyWorkout = () => {
    const { pasteweekid, ...getAllGoalsData } = selecedCopyWorkoutData;
    setCopyWorkoutData(getAllGoalsData)
    setCopyOpen(false)
  }
  const PasteWeeklyWorkouts = async () => {
    let data = { ...CopyWorkoutData, pasteweekid: selecedCopyWorkoutData?.pasteweekid }
    let response = await copyPasteweeklyTrainingBlocks(data)
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setSelectedCopyWorkoutData()
      setCopyOpen(false)

      setCopyWorkoutData({})
      
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );

      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    } else {
      Swal.fire({
        title: "Error!!",
        text: response,
        icon: "error",
      });
    }


  }
  const handleCopy = (id) => {
      setSelectCopyData([id])
      localStorage.setItem("workoutID", id)
      localStorage.removeItem("workoutIDArray")
      showInfo("Copied")
    }

    const showDeleteConfirm = (data, process) => {
      confirm({
        title: 'Are you sure want to delete this workout?',
        icon: <ExclamationCircleFilled />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          deleteAllEventFromSelectedSlotOne(data, process)
          console.log("deleted",data);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };

    const deleteAllEventFromSelectedSlotOne = async (data) => {
      console.log("deleted",data);
      let response = await deleteTrainingPlanMasterMaping(
        data.training_plan_master_mapping_id
      );
      if (
        response.message === "Training plan master mapping deleted successfully"
      ) {
        dispatch(
          getTrainingPlanMasterMappingByTrainingId(data.trainingblock_id)
        );
        setTimeout(() => {
          dispatch(updateColumnsinTrainingData());
          dispatch(updateLastColumnDayInRow());
        }, 300);
      }
  
    };
    console.log("dataonday", data);
    console.log("rowdata", rowData);

    


  const open = Boolean(openCopyModal);
  const handleClick = (event, week, id) => {
    setSelectedCopyWorkoutData({ copyweekid: week, trainingblockid: id, pasteweekid: week })
    setCopyOpen(event.currentTarget);
  };
  return (
    <>
    <div
      ref={ref}
      onClick={() => {
        if (isMobileView) {
          setModalVisible(true)
          console.log("dayclicked",data?.children);
        }
      }}
      style={{ ...style, opacity,width:"10vw" }}
      className="base draggable calendar-day border border-gray-200 relative blockWidth"
    >
      {data?.isWeekCount ? (
        <div key={`empty-${counter}`} className="" id={data.id}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span
              style={{ fontWeight: "bold", marginTop: "5px" }}
              className="text-gray-500"
            >

              Week {data.id.slice(5, data.id.indexOf("-row"))}
            </span>
            <span>
              <HiDotsVertical style={{color:"#E67E22"}} onClick={(e) => {
                if (CurrentTrainingBlock.id) {
                  handleClick(e, data.id.slice(5, data.id.indexOf("-row")), CurrentTrainingBlock.id)
                }
              }}
                className='cursor-pointer' />
            </span>
          </div>
          <Menu
            id="basic-menu"
            anchorEl={openCopyModal}
            open={open}
            onClose={() => setCopyOpen(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleCopyWeeklyWorkout} >Copy Weekly Workout</MenuItem>
            {CopyWorkoutData && Object.keys(CopyWorkoutData).length > 0 && (
  <MenuItem onClick={PasteWeeklyWorkouts}>Paste Weekly Workout</MenuItem>
)}
          </Menu>
          {/* <PoolIcon />
          <PedalBikeIcon />
          <DirectionsRunIcon /> */}
          {lastColumnDayInRow.length !== 0 ? (
            lastColumnDayInRow.map((item) => {
              if (
                item.rowName === data.id.slice(0, data.id.indexOf("-count")) &&
                item.splitArray.length !== 0
              ) {
                return (
                  <div key={item.rowName}>
                    {" "}
                    {/* Add a unique key to the parent div */}
                    {item.splitArray.map((activity, index) => (
                      <table key={index} className="customCalenderTable">
                        <tbody>
                          <tr>
                            {/* <td className="customcalenderTdTh">
                              <PoolIcon />
                            </td> */}
                            <td className="customcalenderTdTh">
                              {Object.keys(activity)[0]}
                            </td>
                            <td className="customcalenderTdTh">
                               <b>{Number(parseFloat(Object.values(activity)[0]).toFixed(1))}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={isMobileView ? "pt-2" : "p-1"} onClick={() => handleLibrary(data.id)}>
            <IconPlus size={isMobileView ? 12 : undefined}  color="#E67E22" className="cursor-pointer" />
          </div>
          <header className="flex flex-col items-center text-slate-800">
            <p className="text-sm p-1 my-1 text-center text-slate-800 text-black m0">
              {counter}
            </p>
          </header>
          {!isMobileView &&
          <span>
            <button
              onClick={(e) => handleClickonMenuButton(e, data)}
              style={{
                border: "0px",
              }}
              id={data.id}
              className="text-gray-500 p-1"
            >
              <MoreHorizIcon style={{color:"#E67E22"}}/>{" "}
            </button>
          </span>
          }
        </div>
      )}
      <div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            size="small"
            onClick={() => hideallEvent()}
          >
            <VisibilityOffIcon className="mr-2" style={{color:"#E67E22"}}/> Hide All
          </MenuItem>
          <MenuItem
            size="small"
            onClick={() => unhideallEvent()}
          >
            <VisibilityIcon className="mr-2"  style={{color:"#E67E22"}}/>  Unhide All
          </MenuItem>
          <MenuItem
            size="small"
            onClick={(e) => copyAllEventFromSelectedSlot(e)}
            id={data.id}
          >
            <ContentCopyIcon className="mr-2"  style={{color:"#E67E22"}}/>  Copy
          </MenuItem>
          <MenuItem
            size="small"
            onClick={() => pasteAllEventFromSelectedSlot()}
          >
            <ContentPasteIcon className="mr-2"  style={{color:"#E67E22"}}/>  Paste
          </MenuItem>
          <MenuItem
            size="small"
            onClick={() => deleteAllEventFromSelectedSlot()}
          >
            <DeleteForeverIcon className="mr-2"  style={{color:"#E67E22"}}/> Delete
          </MenuItem>

        </Menu>
      </div>
      {!data?.isWeekCount &&
        data.children.map((component, index) => {
          const currentPath = `${path}-${index}`;
          
          return (
            <React.Fragment key={component.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                }}
                onDrop={handleDrop}
              />

              {renderComponent(component, currentPath)}
            </React.Fragment>
          );
        })}
      {!data?.isWeekCount && (
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
          }}
          onDrop={handleDrop}
          isLast
          id={id}
        />
      )}


      
    </div>

    {modalVisible &&
          <ViewBlocks
            data={data?.children}
            path={path}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            openBox={openBox}
             setOpenBox={setOpenBox}
              isEditOpen={isEditOpen}
               setIsEditOpen={setIsEditOpen}
                sectedCopyData={sectedCopyData} 
                setSelectCopyData={setSelectCopyData}
                 selectedID={selectedID}
                  setSelectedID={setSelectedID} 
                  handlePastProgram={handlePastProgram}
                   showDeleteConfirm={showDeleteConfirm}
                    handleCopy={handleCopy}
          />
        }
        {/* {summaryModalVisible &&
          <TrainingSummary
            data={data}
            rowData={rowData}
           handleClick={handleClick}
            summaryModalVisible={summaryModalVisible}
            setSummaryModalVisible={setSummaryModalVisible}
            handleCopyWeeklyWorkout={handleCopyWeeklyWorkout}
            PasteWeeklyWorkouts={PasteWeeklyWorkouts}
            openCopyModal={openCopyModal}
            setCopyOpen = {setCopyOpen}
            open ={open}
            
            
          />
        } */}
    </>
  );
};
export default Column;
