import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, FormLabel, Grid, TextField } from "@mui/material";
import "../components/AssignCoach/Assign.css";
import Header from "../components/Header";
import {
  getSubscriptionPaymentFile,
  getSubscriptionPaymentData,
} from "../API/api-endpoint";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";
import { Modal } from "antd";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e40af",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const SubscriptionWithPayments = () => {
  const [formValue, setFormValue] = useState({});
  const [reportData, setReportData] = useState();
  const [showLogsDetails, setShowLogsDetails] = useState({
    isOpen: false,
    logs: [],
  });

  const fetchReport = async () => {
    if (formValue?.start && formValue?.end) {
    const response = await getSubscriptionPaymentData(formValue);
    if (response?.status) {
      setReportData(response?.data);
    }
  }
  else {
    Swal.fire({
      title: "Error",
      text: "Please Fill All Fields!",
      icon: "error",
    });
  }
  };

  const downloadReport = async () => {
    const response = await getSubscriptionPaymentFile(formValue);
    if (response?.status) {
      window.open(response.url);
      setTimeout(() => {
        Swal.fire({
          title: "Success",
          text: "Report Downloaded Successfully!",
          icon: "success",
        });
      }, 2000);
    } else {
      Swal.fire({
        title: "Error",
        text: "Some Error Occured, Please Try Again!",
        icon: "error",
      });
    }
  };

  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div style={{ marginTop: "100px", padding: "20px" }}>
        <div
          style={{
            fontSize: "18px",
            background: "#FFEADC",
            width: "100%",
            padding: "10px",
          }}
        >
          <h1 className="mb-2" style={{ fontWeight: "700", fontSize: "17px" }}>
            Subscriptions With Payments Report
          </h1>
          <Grid container spacing={2}>
            <Grid className="dateWrap" item xs={12} sm={2}>
              <FormLabel>
                <b>Start Date</b>
              </FormLabel>

              <DatePicker
                selected={formValue?.start ? new Date(formValue?.start) : null}
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    start: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                onKeyDown={(e) => e.preventDefault()}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                maxDate={formValue?.end ? new Date(formValue?.end) : new Date()}
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid className="dateWrap" item xs={12} sm={2}>
              <FormLabel>
                <b>End Date</b>
              </FormLabel>
              <DatePicker
                selected={formValue?.end ? new Date(formValue?.end) : null}
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    end: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                onKeyDown={(e) => e.preventDefault()}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                maxDate={new Date()}
                minDate={formValue?.start ? new Date(formValue?.start) : null}
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid  className="mt10" item xs={3} sm={1} sx={{ marginTop: "26px" }}>
              <Button
                className="report-btn"
                variant="contained"
                onClick={fetchReport}
              >
                Submit
              </Button>
            </Grid>
            <Grid
              item
              xs={7}
              sm={4}
              sx={{ marginTop: "26px", marginLeft: "10px" }}
               className="mt10"
            >
              <Button
                className="report-btn"
                variant="contained"
                onClick={downloadReport}
              >
                Download Report
              </Button>
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, padding: "10px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sr ID</StyledTableCell>
                <StyledTableCell align="left">Price</StyledTableCell>
                <StyledTableCell align="left">Subscription Id</StyledTableCell>
                <StyledTableCell align="left">User Id</StyledTableCell>
                <StyledTableCell align="left">Plan Id</StyledTableCell>
                <StyledTableCell align="left">Program Id</StyledTableCell>
                <StyledTableCell align="left">Start Date</StyledTableCell>
                <StyledTableCell align="left">End Date</StyledTableCell>
                <StyledTableCell align="left">Payment Id</StyledTableCell>

                <StyledTableCell align="left">
                  Razorpay Payment Id
                </StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.length > 0 ? (
                <>
                  {reportData?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.price ?? 0}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.subscription_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.user_id}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.plan_id}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.program_id}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.start_date}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.end_date}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.payment_id}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.razorpay_payment_id}
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        {row?.onhold ? "On hold" : "Success"}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button
                          className="ant-btn-primary"
                          variant="contained"
                          onClick={() => {
                            setShowLogsDetails({
                              isOpen: true,
                              logs: row?.subscriptionslogs,
                            });
                            // ?userId=${row?.subscription?.user_id}
                          }}
                        >
                          View Subscription logs
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <div className="p-4">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        title="Show logs details"
        centered
        width={1000}
        open={showLogsDetails?.isOpen}
        onCancel={() => setShowLogsDetails({ isOpen: false, logs: [] })}
        footer={null}
      >
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, padding: "10px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sr ID</StyledTableCell>
                <StyledTableCell align="left">Price</StyledTableCell>
                <StyledTableCell align="left">Subscription Id</StyledTableCell>
                <StyledTableCell align="left">Razorpay Id</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showLogsDetails?.logs?.length > 0 ? (
                <>
                  {showLogsDetails?.logs?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.price ?? 0}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.subscription_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.razorpay_payment_id}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <div className="p-4">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
    </div>
  );
};

export default SubscriptionWithPayments;
