import { Box, Toolbar } from '@mui/material'
import React, { useState,useEffect } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import '../../styles/navbar.css'
import { useNavigate ,useLocation} from 'react-router-dom';


export default function NavGroupInfo({  groupName,setOpenPageOne,openPageOne }) {
    const [checked] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
  const currentPath = location.pathname;
console.log("currentpath",currentPath);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 500);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleGoBack = () => setOpenPageOne("home")

    return <>
        <Toolbar className="appBar" style={{backgroundColor:"#F5F5F5"}}>
        {openPageOne === "create-group" ?
    <Box sx={{ display: 'flex', cursor: "pointer" }}>
        <KeyboardBackspaceIcon onClick={handleGoBack} />
    </Box>
    : null
}
{isMobileView && 
  <KeyboardBackspaceIcon onClick={() => {
    if(isMobileView && currentPath == "/start-new-chat"){
        navigate('/chat')
    }
   else if (isMobileView) {
      navigate('/group-chat/creator');
    }
  }} />
}

            <Box className="readUnreadButton" sx={{margin: "0 auto"}}>
                <span style={!checked ? { color: "black", fontSize: 15 } : {}}>
                    {groupName}
                </span>
            </Box>
            <Box >
            </Box>
        </Toolbar >
    </>
}