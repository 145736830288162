import { Avatar, Box, Paper,CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../styles/chatlanding.css";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import {
  onValue,
  query,
  limitToLast,
  orderByChild,
  equalTo,
} from "firebase/database";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  getDatabase,
  ref,
  get,
  set,
  update,
  serverTimestamp,
  onDisconnect
} from "firebase/database";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsersAction,
  setSearchUserData,
  storeOpenedUserInfoAction,
} from "../redux/action/usersAction";
import { getCurrentUserChatsAction } from "../redux/action/userChats";
import UserChats from "../components/UserChats";
import Header from "../../../components/Header";
import { useMessageContext } from "../../../context/MessageContext";
import { URL } from "../../../API/api-endpoint";
import { useNavigate } from "react-router-dom";

export default function ChatLanding({setOpenPage,setOpenPageOne,openPage,isUnRead,setIsUnRead}) {
  const paper = {
    padding: "0rem",
    minHeight: "87vh",
    maxHeight: "87vh",
    overflowY: "scroll",
    maxWidth: "32rem",
    // marginLeft: "3rem"
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleID = localStorage.getItem("roleID");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { allUsers } = useSelector((state) => state.users);
  const { serchedUserData } = useSelector((state) => state.users);
  const { currentUser } = useSelector((state) => state.auth);
  const { currentUserChats } = useSelector((state) => state.userChats);
  
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { totalUnseenMessageCount } = useMessageContext();
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingUsers, setIsLoadingusers] = useState(true)
  const [programIdChange, setprogramIdChange] = useState(false)
  const { openedUser } = useSelector(state => state.users)
  // const [serchedUserData, setSerchedUserData] = useState([]);
  const[totalMessageCount,setTotalMessageCount]= useState(0)
  const [isActive, setIsActive] = useState(false);
  const [isLoadingAthlete, setIsLoadingAthlete] = useState(true)


  const handleSelect = async (user) => {
    dispatch(storeOpenedUserInfoAction(user));

    const db = getDatabase();

    const combinedId =
      currentUser?.uid > user?.uid
        ? currentUser?.uid + user?.uid
        : user?.uid + currentUser?.uid;

    try {
      console.log(combinedId);
      const chatRef = ref(db, "chats/" + combinedId);
      const snapshot = await get(chatRef);

      if (!snapshot.exists()) {
        // Create user chats
        const currentUserChatsRef = ref(db, `userChats/${currentUser?.uid}`);
        const userChatsRef = ref(db, `userChats/${user?.uid}`);

        await update(currentUserChatsRef, {
          [`${combinedId}/userInfo`]: {
            uid: user?.uid,
          },
          [`${combinedId}/date`]: serverTimestamp(),
          [`${combinedId}/isMsgReqAccepted`]: true,
          [`${combinedId}/isMsgReqDeclined`]: false,
          [`${combinedId}/chatType`]: "personal",
        });

        await update(userChatsRef, {
          [`${combinedId}/userInfo`]: {
            uid: currentUser?.uid,
          },
          [`${combinedId}/date`]: serverTimestamp(),
          [`${combinedId}/isMsgReqAccepted`]: false,
          [`${combinedId}/isMsgReqDeclined`]: false,
          [`${combinedId}/chatType`]: "personal",
        });

        // navigate("/individual-chat");
        if(isMobileView){
          navigate("/individual-chat");
         }
         else{
          setOpenPage("individual")
        if( !isActive){
          setIsUnRead(false)
        }
         }
        
        
      }
      if(isMobileView){
        navigate("/individual-chat");
       }
       else{
        setOpenPage("individual")
      if( !isActive){
        setIsUnRead(false)
      }
       }
      // navigate("/individual-chat");
      
    } catch (err) {
      console.log(err);
    }
  };


  

  
  let filteredAllUsers;
  
if (roleID == 5) {
  filteredAllUsers = allUsers
  // .filter((user) => {
  //   const isUserInPersonalChats = currentUserChats.some(
  //     (chat) => chat.chatType === 'personal' && chat.userInfo?.uid === user.uid
  //   );
  //   return !isUserInPersonalChats;
  // });
}

 else {
  filteredAllUsers = allUsers;
  
}



// useEffect(() => {
//   setSearchUserData(filteredAllUsers)
// }, [allUsers,filteredAllUsers]);

// useEffect(() => {
//   console.log("nigixsearch",serchedUserData);
// }, [serchedUserData]);
const firstLogin = localStorage.getItem('FirstLogin');

useEffect(() => {
  const firstLogin = localStorage.getItem('FirstLogin');
    if (firstLogin === 'true') {
      // Set FirstLogin to false and reload the page
      localStorage.setItem('FirstLogin', 'false');
      window.location.reload();
    }
}, [firstLogin]);


useEffect(() => {
  if (allUsers.length > 0 && roleID !== 5) {
    setIsLoadingusers(false);
  }
  else if(roleID == 5){
    setTimeout(() => {
      setIsLoadingusers(false);
    }, 8000);
  }
}, [allUsers]);

useEffect(() => {
  if (roleID == 3) {
    setTimeout(() => {
    setIsLoadingusers(false);
  }, 8000);
  }
  
}, [allUsers]);

useEffect(() => {
  if (roleID == 5) {
    setTimeout(() => {
    setIsLoadingAthlete(false)
  }, 8000);
  }
  
}, []);




useEffect(() => {
  console.log("program changed");
  const fetchData = async () => {
    await dispatch(getAllUsersAction(roleID));
    
  };

  fetchData();
  if(programIdChange==true){
    window.location.reload();
  }
  
  setprogramIdChange(false)
}, [programIdChange==true]);


  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllUsersAction(roleID));
      await dispatch(getCurrentUserChatsAction(currentUser?.uid));
    };

    fetchData();
  }, [currentUser?.uid, roleID, dispatch]);

  const handleSearchUser = () => {
    console.log("searchNigix",filteredAllUsers,searchKeyword);
    dispatch(setSearchUserData(filteredAllUsers, searchKeyword));
  };

  useEffect(() => {
    handleSearchUser();
  }, [searchKeyword]);
 


const db = getDatabase();
const isOnlineRef = ref(db, ".info/connected");
const userStatusRef = ref(db, `/users/${currentUser?.uid}/onlineStatus/isOnline`);

onValue(isOnlineRef, (snapshot) => {
  if (snapshot.val() === false) {
    return;
  }

  set(userStatusRef, true);
  onDisconnect(userStatusRef).set(false);
});

async function getGroupDataById(groupId) {
  try {
    const userRef = ref(db, `groups/${groupId}`);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
      const createdTime = snapshot.val()
      return createdTime;
    } else {
      console.warn("User data not found.");
      return null;
    }
  } catch (error) {
    console.error("Error getting user data:", error);
    return null;
  }
}

function convertToUnixEpoch(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date format.");
  }
  return date.getTime();
}


  const getLastMessageForGroupChat = async(groupId) => {
    const groupData = await getGroupDataById(groupId);

    let lastMessageTime = 0;
    const messagesRef = ref(db, `chats/${groupId}/messages`);
    const lastMessageQuery = query(
      messagesRef,
      orderByChild("timeStamp"),
      limitToLast(1)
    );

    const callback = async (snapshot) => {
      if (snapshot.exists()) {
      } else {
        console.log("No messages found.");
        if (groupData && groupData.createdAt) {
          const formatTime = convertToUnixEpoch(groupData.createdAt);
          lastMessageTime = formatTime
          
        }
      }
    };

    onValue(lastMessageQuery, callback);

    return lastMessageTime;
  };

  const getLastMessage = (combinedId) => {
    return new Promise((resolve, reject) => {
      const messagesRef = ref(db, `chats/${combinedId}/messages`);
      const lastMessageQuery = query(
        messagesRef,
        orderByChild("timeStamp"),
        limitToLast(1)
      );

      onValue(lastMessageQuery, (snapshot) => {
        if (snapshot.exists()) {
          const lastMessageKey = Object.keys(snapshot.val())[0];
          const lastMessage = snapshot.val()[lastMessageKey];
          resolve({
            message: lastMessage.message,
            timestamp: lastMessage.timeStamp,
          });
        } else {
          resolve(null); // No messages found
        }
      }, (error) => {
        reject(error);
      });
    });
  };


// Function to sort currentUserChats based on the last message timestamp of each chat
// Function to sort currentUserChats based on the last message timestamp of each chat
const sortCurrentUserChatsByLastMessage = async (uniquePersonalChats) => {
  const sortedChats = [];

  // Iterate over currentUserChats and retrieve last message for each chat
  for (const chat of uniquePersonalChats) {
    let lastMessageTimestamp = 0;

    if (chat.chatType === 'personal') {
      const combinedId =
        currentUser?.uid > chat.userInfo?.uid
          ? currentUser?.uid + chat.userInfo?.uid
          : chat.userInfo?.uid + currentUser?.uid;

      const lastMessage = await getLastMessage(combinedId);
      lastMessageTimestamp = lastMessage ? lastMessage.timestamp : 0;
    } else if (chat.chatType === 'group') {
      const lastMessage = await getLastMessageForGroupChat(chat.groupId);
      lastMessageTimestamp = lastMessage ? lastMessage : 0;
    }

    sortedChats.push({
      ...chat,
      lastMessageTimestamp,
    });
  }

  // Sort chats based on last message timestamp in descending order
  sortedChats.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);


  return sortedChats;
};
const uniquePersonalChats = currentUserChats.filter((chat, index, chats) => {
  if (chat.chatType === 'personal') {
    return chats.findIndex((prevChat) => prevChat.chatType === 'personal' && prevChat.userInfo?.uid === chat.userInfo?.uid) === index;
  }
  return true; // Keep non-personal chats
});

const [sortedCurrentUserChats, setSortedCurrentUserChats] = useState([]);



// Get sorted currentUserChats based on last message

useEffect(() => {
  sortCurrentUserChatsByLastMessage(uniquePersonalChats).then((sortedChats) => {
    setSortedCurrentUserChats(sortedChats);
    setIsLoading(false)
  }).catch((error) => {
    console.error('Error sorting chats:', error);
  });
}, [currentUserChats, currentUser?.uid,totalUnseenMessageCount,isUnRead]);

useEffect(() => {
  const timeoutId = setTimeout(() => {

    sortCurrentUserChatsByLastMessage(uniquePersonalChats)
      .then((sortedChats) => {
        setSortedCurrentUserChats(sortedChats);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error sorting chats:', error);
      });
  }, 1000);

  return () => clearTimeout(timeoutId);
}, [currentUserChats]);


const getTotalMessageCount = (userUid) => {
  const combinedId =
    currentUser?.uid > userUid
      ? currentUser?.uid + userUid
      : userUid + currentUser?.uid;

  const messagesRef = ref(db, `chats/${combinedId}/messages`);

  let totalMsgesCount = 0;

  const callback = (snapshot) => {
    totalMsgesCount = 0; // Reset count before updating
    snapshot.forEach((childSnapshot) => {
      totalMsgesCount++; // Increment count
    });
    console.log("isactive",totalMsgesCount);
    setTotalMessageCount(totalMsgesCount); // Set count after processing
  };

  onValue(messagesRef, callback);
  return;
};

useEffect(() => {
  // Call getTotalMessageCount when openedUser changes
  if (openedUser) {
    getTotalMessageCount(openedUser?.uid);
  }
}, [openedUser]);

useEffect(() => {
  // Call getTotalMessageCount when openedUser changes
 console.log("isactive",isActive);
}, [isActive]);

// Set setActive to true if totalMsgesCount is zero
useEffect(() => {
  if (totalMessageCount === 0) {
    setIsActive(true);
  }
  else{
    setIsActive(false)
  }
}, [totalMessageCount]);

const [groupExistence, setGroupExistence] = useState({});

useEffect(() => {
  const fetchGroupData = async () => {
    const existenceMap = {};
    for (const chat of sortedCurrentUserChats) {
      if (chat.chatType === 'group') {
        const groupData = await getGroupDataById(chat.groupId);
        existenceMap[chat.groupId] = groupData !== null;
      }
    }
    setGroupExistence(existenceMap);
  };

  fetchGroupData();
}, [sortedCurrentUserChats]);




  return (
    <div>
      <Header setprogramIdChange={setprogramIdChange}/>
      <Box
        sx={{
          display: "flex",
          marginTop: "90px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          className="chatPaper groupStepOne height100"
          sx={{ flexGrow: 1 }}
          elevation={10}
          style={paper}
        >
          <Navbar
            isUnRead={isUnRead}
            setIsUnRead={setIsUnRead}
            isSearchActive={isSearchActive}
            setIsSearchActive={setIsSearchActive}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
          {roleID != 5 && (
            <Box
            onClick={() => {
              if(isMobileView){
                navigate("/start-new-chat")
              }
              else{
                setOpenPageOne("create-group");
              setOpenPage("");
              }
              
          }}
          
              sx={{
                backgroundColor: "#F5F5F5",
                padding: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "5px",
                cursor: "pointer",
                // borderTop : "1px solid lightGray",
                marginTop: "5px"
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", padding: "6px" }}
              >
                <Avatar sx={{ backgroundColor: "#E67E22" }}>
                  {/* <img src="/chats-icon.png" alt="" /> */}
                  <QuestionAnswerIcon />
                </Avatar>
                <Typography
                  className="start-new-chat"
                  sx={{ paddingLeft: "1.2rem" }}
                >
                  Start a New Chat
                </Typography>
              </Box>
              <img src="/cross-arrow.png" alt="cross-arrow" />
            </Box>
          )}

          {(isLoading  && !isLoadingUsers) ? (
            <CircularProgress className="m-6" />) : (
          <>
      {searchKeyword === "" &&
      sortedCurrentUserChats.map((item, index) => {
        // console.log("called",item);
        if (item?.userInfo?.uid === currentUser?.uid ) return null; // Skip rendering current user
        if (item.chatType === 'group' && !groupExistence[item.groupId]) {
          return null; // Skip rendering non-existent groups
        }
        return (
          <UserChats
            key={`cUser${index}`}
            item={item}
            currentUser={currentUser}
            isUnRead={isUnRead}
            setIsUnRead={setIsUnRead}
            setOpenPage={setOpenPage}
            openPage={openPage}
          />
        );
      })}
       </>
    )}

      

          {!isLoadingAthlete && allUsers.length == 0 && (roleID == 5) && searchKeyword == ""  && totalUnseenMessageCount==0  && (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                fontWeight: "700",
              }}
            >
              Your Messaging will be activated once Coach is assigned to you
            </Typography>
          )}


{!isLoadingUsers && allUsers.length == 0 && roleID == 3 && searchKeyword == "" && isUnRead   && (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                fontWeight: "700",
              }}
            >
              No Athletes Assigned.
            </Typography>
          )}

          {/* {allUsers.length == 0 && roleID == 5 && searchKeyword == "" && !isUnRead && currentUserChats?.length == 0  && (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                fontWeight: "700",
              }}
            >
              No Chats Yet.
            </Typography>
          )} */}

{isLoadingUsers ? (
                            <CircularProgress className="m-6" />) : (
                              <>

          {serchedUserData.length == 0 &&
            searchKeyword === "" &&
            filteredAllUsers.map((item, index) => {
              if (item?.uid === currentUser?.uid ) return;
              console.log("5555userOne", item);
              return (
                <Box
                  onClick={() => handleSelect(item)}
                  key={index}
                  sx={
                    (openedUser?.uid === item?.uid && openPage === "individual" && isActive) 
                    ?
                    {
                      marginLeft:"10px !important",
                    cursor: "pointer",
                    padding: "8px",
                    paddingTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: "1",
                    backgroundColor: "#E67E22",
                    borderRadius: "10px"
                    }
                    :
                    {
                      marginLeft:"10px !important",
                    cursor: "pointer",
                    padding: "8px",
                    paddingTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: "0.5",
                    
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6px",
                    }}
                  >
                     <Avatar alt={item.displayName}
                     src={`${URL}/static/public/userimages/${item?.photoURL}`} />
                    <Box>
                      <Typography
                        className="userName"
                        fontWeight="fontWeightBold"
                        sx={{ paddingLeft: "1.2rem" }}
                      >{item.displayName}</Typography>
                      <Typography
                        sx={{ paddingLeft: "1.2rem", fontSize: "15px" }}
                      >
                        {item.desc}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Box className="timeStamp">{item.time}</Box>
                    <Box
                      sx={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {item.showMediaIcon && (
                        <InsertLinkOutlinedIcon className="chat-icon" />
                      )}
                      {item.showBellIcon && (
                        <NotificationsActiveOutlinedIcon className="chat-icon" />
                      )}
                      {item.msgCount > 0 && (
                        <Box className="badgeIcon chat-icon">
                          <Typography fontSize={15}>{item.msgCount}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
            </>
    )}

          {searchKeyword && serchedUserData && serchedUserData.length === 0 ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                paddingTop: "20px",
                fontWeight: "700",
              }}
            >
              No Data Found.
            </Typography>
          ) : (
            serchedUserData &&
            serchedUserData.length > 0 &&
            serchedUserData.map((item, index) => {
              if (item?.uid === currentUser?.uid ) return;
              console.log("5555user", item);
              return (
                <Box
                  onClick={() => handleSelect(item)}
                  key={index}
                  sx={
                    (openedUser?.uid === item?.uid && openPage === "individual" && isActive)
                    ?
                  
                    {
                      marginLeft:"10px !important",
                    cursor: "pointer",
                    padding: "8px",
                    paddingTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: "1",
                    backgroundColor: "#E67E22",
                    borderRadius: "10px"
                    }
                    :
                    {
                      marginLeft:"10px !important",
                    cursor: "pointer",
                    padding: "8px",
                    paddingTop: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    opacity: "0.5",
                    
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "6px",
                    }}
                  >
                    <Avatar alt={item.displayName}
                     src={`${URL}/static/public/userimages/${item?.photoURL}`} />
                    <Box>
                      <Typography
                        className="userName"
                        fontWeight="fontWeightBold"
                        sx={
                          (openedUser?.uid === item?.uid && openPage === "individual" && isActive)
                    ?
                    {color: "white !important",paddingLeft: "1.2rem"}
                    :
                          { paddingLeft: "1.2rem" }}
                      >{item.displayName}</Typography>
                      <Typography
                        sx={{ paddingLeft: "1.2rem", fontSize: "15px" }}
                      >
                        {item.desc}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "right" }}>
                    <Box className="timeStamp">{item.time}</Box>
                    <Box
                      sx={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {item.showMediaIcon && (
                        <InsertLinkOutlinedIcon className="chat-icon" />
                      )}
                      {item.showBellIcon && (
                        <NotificationsActiveOutlinedIcon className="chat-icon" />
                      )}
                      {item.msgCount > 0 && (
                        <Box className="badgeIcon chat-icon">
                          <Typography fontSize={15}>{item.msgCount}</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })
          )}
        </Paper>
      </Box>
    </div>
  );
}
