import { Avatar, Box, Fade, FormControlLabel, IconButton, Toolbar, Typography } from '@mui/material'
import React, { useState,useEffect } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import '../styles/navbar.css'
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router-dom';
import { URL } from '../../../API/api-endpoint';

export default function NavIndividualChat({ backButtonPath, photoURL, displayName, isOnline, isSearchActive, setIsSearchActive, searchKeyword, setSearchKeyword }) {
    const navigate = useNavigate()
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 500);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleNavigate = () => navigate(backButtonPath)
console.log("kkk",isOnline);
const handleSetIsSearch = async () => {
    await setIsSearchActive(!isSearchActive ? true : false);
    const inputElement = document.getElementById('search-msg-input');
    if (inputElement && !isSearchActive) {
        inputElement.focus();
    } else {
        // Clear search keyword when closing search
        setSearchKeyword('');
    }
};
const [isUserOnline, setIsUserOnline] = useState(isOnline);

useEffect(() => {
  setIsUserOnline(isOnline);
  console.log("kkk",isOnline);
}, [isOnline]);

    

    return <>
        <Toolbar className="appBar">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isMobileView &&
                <KeyboardBackspaceIcon onClick={handleNavigate} />}
                <Avatar
                 onClick={() => {
                    if (isMobileView) {
                      navigate('/individual-information');
                    }
                  }}
                 sx={{ height: 33, width: 33, marginLeft: '8px' }}
                alt={displayName}
                src={`${URL}/static/public/userimages/${photoURL}`}  />
                {
                    !isSearchActive
                        ? <Box
                        onClick={() => {
                            if (isMobileView) {
                              navigate('/individual-information');
                            }
                          }}
                        >
                            <Typography className='userName' sx={{ fontSize: '14px', paddingLeft: '1.2rem', lineHeight: 1.3 }}>{displayName}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', flexDirection: 'row', marginLeft: '1rem' }} >
                                <FiberManualRecordIcon sx={
                                    isOnline
                                        ? { color: '#056B6B', display: 'inline-block', fontSize: '10px' }
                                        : { color: '#F49696', display: 'inline-block', fontSize: '10px' }
                                } />
                                <Typography className='active-inactive'>
                                    {isOnline ? "Active now" : "Not active"}
                                </Typography>
                            </Box>
                        </Box>
                        : <Box style={{ position: 'relative', textAlign: "center" }}>
                            <input
                                id='search-msg-input'
                                value={searchKeyword}
                                onChange={e => setSearchKeyword(e.target.value)}
                                placeholder='Search Buddies' type="text" className='search-message-input'
                            />
                        </Box>
                }
            </Box>
            <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center", width: "3.5rem" }}>
                <img onClick={handleSetIsSearch}
                    src={!isSearchActive ? "/images/searcher-icon.svg" : "/images/close-icon.png"}
                    alt="search" style={{}} />
                {/* <MoreVertIcon /> */}
            </Box>
        </Toolbar >
    </>
}