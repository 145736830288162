import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Select,
  FormLabel,
  Drawer,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  MenuItem,
  CardHeader,
  Grid,
  Divider,
  CardContent,
  FormHelperText,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import {
  createWorkouts,
  fetchactivitylevel,
  getAllActivities,
  getAllActivitiesField,
  getAllUserZones,
  getParticularWorkoutDetails,
  getSuActivity,
  getuomsData,
  importStravaActivity,
  updateWorkouts,
} from "../../../../API/api-endpoint";
import {
  initialValues,
  addworkoutValidation,
  addworkoutMealValidation,
} from "../../../../components/formik/FormikValidationSceema";
import { useFormik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import CommentBox from "../CommentBox";
import Swal from "sweetalert2";
import { handleTimeChange } from "../../../../utils/Resubale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import TextEditor from "../../../../TextEditor/TextEditor";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const emojiFeelings = {
  "😊": "felt great",
  "😃": "struggled",
  "👍": "felt okay",
  "👏": "managed",
  "🥳": "felt awesome",
};

const WorkoutForm = (props) => {
  const {
    workoutformdrawerState,
    setworkoutformdrawerState,
    formData,
    setWorkoutID,
    getAllWorkouts,
    workoutID,
    assignedCocahId,
    dateForworkout,
  } = props;

  const matches = useMediaQuery('(max-width:500px)');


  console.log("dateForworkout", dateForworkout);
  const [formValue, setFormValue] = useState({});
  const [fetchAllActivities, setFetchActivies] = useState();
  const [allZones, setAllZones] = useState();
  const [IsNutrision, setIsNutrision] = useState(false);
  const [initial, setInitial] = useState(false);
  const [selectedTime, setSelectedTime] = useState(dayjs());
console.log("selectedTime",selectedTime);
const [allUoms, setAllUoms] = useState();

  const [fetchSubActivity, setFechSubActivity] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [fieldName, setFiledName] = useState();
  useEffect(() => {
    setFormValue(formData === undefined ? {} : formData);
    getAllActivitiesDetails();
  }, [formData]);

  useEffect(() => {
    if (formValue?.copy_from_actuals === true) {
      const {
        planned_distance,
        planned_distance_unit,
        planned_duration,
        planned_heartrate,
        planned_heartrate_unit,
        planned_pace,
        planned_rpe,
        planned_power,
        planned_power_unit,
        planned_cadence,
        planned_cadence_unit,
      } = formValue;

      const ActualsValues = {
        actual_distance: planned_distance,
        actual_distance_unit: planned_distance_unit,
        actual_duration: planned_duration,
        actual_heartrate: planned_heartrate,
        actual_heartrate_unit: planned_heartrate_unit,
        actual_pace: planned_pace,
        actual_rpe: planned_rpe,
        actual_power: planned_power,
        actual_power_unit: planned_power_unit,
        actual_cadence: planned_cadence,
        actual_cadence_unit: planned_cadence_unit,
      };

      setFormValue({ ...formValue, ...ActualsValues });
    }
  }, [formValue?.copy_from_actuals]);

  const drawerStateChange = (state) => {
    setworkoutformdrawerState(state);
    setWorkoutID()
  };

  const DrawerHeader = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        color: "#E67E22",
        backgroundColor: "#FFEADC",
      }}
    >
      <div>
        <CardHeader
          title={
            <Typography variant="h4" style={{ fontSize: '23px', fontWeight: "500" }}>
              {workoutID ? 'Edit Workout' : 'Add Workout'}
            </Typography>
          }

          titleTypographyProps={{ variant: "h4" }}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "right", marginTop: "12px" }}
      >
        <span>
          {formValue?.isEditForm ? (
            <Button style={{ color: "#E67E22" }}>
              {" "}
              {/*onClick={() => onDelete()} */}
              <DeleteForeverIcon />
            </Button>
          ) : (
            ""
          )}
        </span>
        <span>
          <Button
            onClick={() => drawerStateChange(false)}
            className="white-bg-btn"
            style={{ color: "#E67E22" }}
          >
            <CloseIcon />
          </Button>
        </span>
      </div>
    </div>
  );

  const handleOnSubmitForm = async (value, resetForm) => {
    console.log("value", value);
    try {
      setIsLoading(true);
      if (workoutID) {
        const Result = await updateWorkouts(value, workoutID, assignedCocahId);
        console.log("Result", Result);
        if (Result.status) {
          setIsLoading(false);
          Swal.fire({
            title: "Success",
            text: Result.message,
            icon: "success",
          });
          setworkoutformdrawerState(false);
          setWorkoutID()
          getAllWorkouts();
          if (resetForm) {
            resetForm();
          }
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Error",
            text: Result.message,
            icon: "error",
          });
          setworkoutformdrawerState(false);
          setWorkoutID()
        }
      } else {
        const Result = await createWorkouts(value, assignedCocahId);
        if (Result.status) {
          setIsLoading(false);
          Swal.fire({
            title: "Success",
            text: Result.message,
            icon: "success",
          });
          setworkoutformdrawerState(false);
          setWorkoutID()
          getAllWorkouts();
          if (resetForm) {
            resetForm();
          }
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Error",
            text: Result.message,
            icon: "error",
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // getParticularWorkoutDetails
  const formik = useFormik({
    initialValues,
    validationSchema: IsNutrision ? addworkoutMealValidation : addworkoutValidation,
    onSubmit: (values, { resetForm }) => {
      handleOnSubmitForm(values, resetForm);
    },
  });
  useEffect(() => {
    if (formik.values.activity_id) {
      getactiviesField();
    }
    if (formik?.values?.activity_id == 7) {
      setIsNutrision(true)
    }
    if (formik?.values?.activity_id != 7) {
      setIsNutrision(false)
    }

    if (dateForworkout) {
      formik?.setFieldValue(
        "workout_planned_for_date_time",
        moment(dateForworkout).format("YYYY-MM-DD")
      );
    }
  }, [formik.values.activity_id, dateForworkout]);
  const getactiviesField = async () => {
    const subActivityResult = await getSuActivity(formik.values.activity_id);
    setFechSubActivity(subActivityResult);
    let result = await getAllActivitiesField(
      formik.values.activity_id,
      setFiledName,
      assignedCocahId
    );
    setFiledName(result);

    console.log("assignedCocahId", assignedCocahId);
    const response1 = await getAllUserZones(
      formik.values.activity_id,
      assignedCocahId
    );
    if (
      formik.values.activity_id == 1 ||
      formik.values.activity_id == 2 ||
      formik.values.activity_id == 3 ||
      formik.values.activity_id == 4
    ) {
      if (response1?.zones?.length > 0) {
        setAllZones(response1);
        formik.setFieldValue("zone", response1?.zones[0]?.id);
        formik.setFieldValue(
          "workout_planned_heart_rate",
          response1?.zones[0]?.heartratestart
        );
        formik.setFieldValue(
          "workout_planned_heart_rate_end",
          response1?.zones[0]?.heartrateend
        );
        formik.setFieldValue("heartZone", response1?.zones[0]?.zone);
      }
      // else {
      //   Swal.fire({
      //     title: "Info",
      //     text: "Please Create Zones First",
      //     icon: "info",
      //   });
      //   if (assignedCocahId) {
      //     setIsZoneOpen({isOpen:true, assignCoachId:assignedCocahId})
      //     setCalendarModal(false)
      //     setworkoutformdrawerState(false)
      //   }else{
      //   navigate("/zone")

      //   }
      // }
    }

    console.log("response1", response1);
  };
  console.log(
    "formik.values?.activity_id == 3",
    formik.values?.activity_id == 3
  );

  const hnadleFormikDistance = async (e, keyname) => {
    console.log("vdsbasvdb", e.target.value, keyname);
    formik.setFieldValue("workout_planned_distance", e.target.value);

    let value = {
      activity_id: formik.values.activity_id,
      time: formik.values.workout_planned_duration,
      distance: e.target.value,
      unit: formik.values?.unit,
    };
    if (formik.values.workout_planned_duration) {
      const response = await fetchactivitylevel(value);
      formik.setFieldValue("workout_planned_pace", response?.pace);
      formik.setFieldValue("workout_planned_speed", response?.speed);

      formik.setFieldValue("workout_planned_Unit", response?.paceunit);
      formik.setFieldValue("workout_planned_SpeedUnit", response?.speedunit);
    }

    // fetchactivitylevel
  };
  const hnadleFormikDuration = async (e, keyname) => {
    const formattedTime = handleTimeChange(e);
    formik.setFieldValue([keyname], formattedTime);

    let value = {
      activity_id: formik.values.activity_id,
      distance: formik?.values?.workout_planned_distance,
      time: formattedTime,
      unit: formik.values?.unit,
    };
    if (formik?.values?.workout_planned_distance) {
      const response = await fetchactivitylevel(value);
      formik.setFieldValue("workout_planned_pace", response?.pace);
      formik.setFieldValue("workout_planned_speed", response?.speed);
      formik.setFieldValue("workout_planned_Unit", response?.paceunit);
      formik.setFieldValue("workout_planned_SpeedUnit", response?.speedunit);
    }

    // fetchactivitylevel
  };

  useEffect(() => {
    fetchUoms()
  }, []);

  const fetchUoms = async () => {
    const response = await getuomsData();
    if(response){
      setAllUoms(response)
    }
  };

  // fetch particular workout
  useEffect(() => {
    if (workoutID) {
      getperticualarWorkout();
    } else {
      // formik.setValues({})
    }
  }, [workoutID]);
  const getperticualarWorkout = async () => {
    const res = await getParticularWorkoutDetails(workoutID);
    getAllActivitiesDetails(res.activity_id);
    const responseData = {
      ...res,
      workout_planned_for_date_time: moment(res.date).format("YYYY-MM-DD"),
    };
    console.log("responseData", responseData);
    setInitial(true)
    formik.setValues(responseData);
    formik.setFieldValue("rpe_actual", responseData?.workout_actual?.rpe);
  };

  const getAllActivitiesDetails = async (id) => {
    let result = await getAllActivities();
    setFetchActivies(result);


  };
  console.log("formik.values.activity_id", formik.values);
  const today = moment().format('YYYY-MM-DD')
  const importStravaAcrity = async (assignedCocahId) => {
    const response = await importStravaActivity(assignedCocahId);
    console.log("response", response);
  };


  return (
    <div style={{ padding: "5% 0" }}>
      <div>
        <Button
          startIcon={<AddIcon />}
          onClick={() => drawerStateChange(true)}
          style={{
            color: "white",
            backgroundColor: "#E67E22",
            fontSize: "10px",
          }}
        >
          {" "}
          {formValue.isEditForm ? "Edit Workout" : "Add Workout"}
        </Button>
      </div>

      <Drawer
        anchor={"right"}
        open={workoutformdrawerState}
        onClose={() => {
          drawerStateChange(false);

          formik.setValues({});
        }}
        PaperProps={{
          sx: { width: matches ? "100%" : "50%" },
        }}
      >
        <DrawerHeader importStravaAcrity={importStravaAcrity} />
        <Divider sx={{ margin: 0 }} />

        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Grid container>
              <Grid container spacing={1} className="pl-2" style={{ placeItems: "center" }}>

                <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
                  <InputLabel
                    shrink
                    id="form-layouts-separator-select-label"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Title <span className="text-[red]">*</span>
                  </InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Workout Title"
                    InputLabelProps={{
                      shrink: Boolean(formValue?.workout_planned_title), // Set to true if value exists, otherwise false
                    }}
                    type="text"
                    name="workout_planned_title"
                    value={formik.values.workout_planned_title}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      formik.setFieldValue(
                        "workout_planned_title",
                        trimmedValue
                      );
                    }}
                    error={
                      formik.touched.workout_planned_title &&
                      formik.errors.workout_planned_title
                    }
                    helperText={
                      formik.touched.workout_planned_title &&
                      formik.errors.workout_planned_title
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    height: "372px",
                    paddingBottom: "20px",
                    marginTop: "20px"
                  }}
                >

                  <Grid item xs={12} sm={2}>
                    <FormLabel variant="h3">
                      Description{" "}
                    </FormLabel>
                  </Grid>

                  <TextEditor setInitial={setInitial}
                    initial={initial}
                    initialContent={formik?.values?.workout_description}
                    onContentChange={(newContent) => formik?.setFieldValue("workout_description", newContent)}
                    placeholder="Type your content here..." />




                </Grid>
                <Grid item xs={6} sm={4} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel
                    shrink
                    id="form-layouts-separator-select-label"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Select Date
                  </InputLabel>

                  <DatePicker
                    selected={formik.values.workout_planned_for_date_time ? new Date(formik.values.workout_planned_for_date_time) : null}
                    onChange={(date) => {
                      formik.setFieldValue(
                        "workout_planned_for_date_time",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    // disabled={workoutID}

                    placeholderText="DD-MM-YYYY"
                    dateFormat="dd-MM-yyyy"
                    customInput={
                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        name="workout_planned_for_date_time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={!workoutID && { min: today }}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={6} sm={4} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel
                    shrink
                    id="form-layouts-separator-select-label"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Select Activity<span className="text-[red]">*</span>
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      placeholder="Distance"
                      name="activity_id"
                      error={
                        formik.touched.activity_id && formik.errors.activity_id
                      }
                      value={
                        formik.values.activity_id ||
                        (fetchAllActivities?.length > 0
                          ? "Select Activity"
                          : "")
                      }
                      onChange={(event) => {
                        formik.setFieldValue("activity_id", event.target.value);
                        let data = fetchAllActivities?.filter(
                          (ele) => ele.id === event.target.value
                        );
                        if (data.length > 0) {
                          formik.setFieldValue("badge", data[0].badge);
                        }
                      }}
                      id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={<OutlinedInput id="select-multiple-language" />}
                    >
                      <MenuItem value={""} disabled>
                        Select Activity
                      </MenuItem>
                      {fetchAllActivities?.map((value, index) => {
                        return (
                          <MenuItem value={value?.id}>
                            {value?.activity_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {formik.touched.activity_id && formik.errors.activity_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={4} sx={{ mt: 2, mb: 2 }}>
                  <InputLabel
                    shrink
                    id="form-layouts-separator-select-label"
                    style={{ fontSize: "20px", fontWeight: "500" }}
                  >
                    Select Sub Activity
                  </InputLabel>
                  <FormControl fullWidth>
                    <Select
                      placeholder="Distance"
                      name="sub_activity_id"
                      error={
                        formik.touched.sub_activity_id && formik.errors.sub_activity_id
                      }
                      value={
                        formik.values.sub_activity_id ||
                        (fetchSubActivity?.length > 0
                          ? "Select Sub Activity"
                          : "")
                      }
                      onChange={(event) =>
                        formik.setFieldValue(
                          "sub_activity_id",
                          event.target.value
                        )
                      }
                      id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={<OutlinedInput id="select-multiple-language" />}
                    >
                      <MenuItem value={""} disabled>
                        Select Sub Activity
                      </MenuItem>
                      {fetchSubActivity?.map((value, index) => {
                        return (
                          <MenuItem value={value?.id}>
                            {value?.subworkout}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ color: "#d32f2f" }}>
                      {formik.touched.sub_activity_id && formik.errors.sub_activity_id}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sm={12}
                spacing={1}
                style={{
                  margin: "2% 0% 0% 0%",
                  borderTop: "1px solid #FFEADC",
                  // padding: "2%",
                  alignItems: "flex-end",
                  // margin: "2%",
                }}
              >
                {/* <Grid item xs={4} sm={2}></Grid> */}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  style={{
                    borderBottom: "3px solid #E67E22",
                    textAlign: "center",
                    color: "#E67E22",
                  }}
                >
                  <Typography variant="h6">Planned</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  style={{
                    borderBottom: "3px solid #E67E22",
                    textAlign: "center",
                    color: "#E67E22",
                  }}
                >
                  <Typography variant="h6">Actuals</Typography>
                  {/*
                 <div style={{ display: "flex" }}>
                    <Checkbox
                      name="copy_from_actuals"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "copy_from_actuals",
                          e.target.checked
                        );
                      }}
                    />
                    <p className="mt-2">Copy From Planned</p>
                  </div>
                */}
                </Grid>
                {fieldName?.activitymetrices?.Distance && (
                  <>
                    {/* <Grid item xs={4} sm={2}>
                      <FormLabel variant="h6">Distance</FormLabel>
                    </Grid> */}
                    <Grid item xs={3} sm={3} style={{ marginTop: "20px" }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Distance
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="0"
                        shrink
                        type="number"
                        name="workout_planned_distance"
                        onChange={(e) =>
                          hnadleFormikDistance(e, "workout_planned_distance")
                        }
                        error={
                          formik.touched.workout_planned_distance &&
                          formik.errors.workout_planned_distance
                        }

                        value={formik.values.workout_planned_distance}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.planned_distance), // Set to true if value exists, otherwise false
                        }}
                      />
                      <div style={formik.errors.workout_planned_distance ? { color: "red" } : { color: "transparent" }}>{formik.errors.workout_planned_distance ?
                        formik.errors.workout_planned_distance : "distance"}</div>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <FormControl fullWidth>
                        <Select
                          placeholder="Distance"
                          name="unit"
                          value={formik.values.unit}
                          onChange={async (e) => {
                            formik.setFieldValue("unit", e.target.value);
                            let value = {
                              unit: e.target.value,
                              activity_id: formik.values.activity_id,
                              time: formik.values.workout_planned_duration,
                              distance:
                                formik?.values?.workout_planned_distance,
                            };
                            const response = await fetchactivitylevel(value);
                            console.log("response", response);
                            formik.setFieldValue(
                              "workout_planned_pace",
                              response?.pace
                            );
                            formik.setFieldValue(
                              "workout_planned_speed",
                              response?.speed
                            );
                            formik.setFieldValue(
                              "workout_planned_Unit",
                              response?.paceunit
                            );
                            formik.setFieldValue(
                              "workout_planned_SpeedUnit",
                              response?.speedunit
                            );
                          }}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={""}>Select </MenuItem>
                          {formik.values?.activity_id == 3 ? (
                            <MenuItem value={"yards"}>Yards</MenuItem>
                          ) : (
                            <MenuItem value={"km"}>Kilometer</MenuItem>
                          )}
                          {formik.values?.activity_id == 3 ? (
                            <MenuItem value={"mtr"}>Meter</MenuItem>
                          ) : (
                            <MenuItem value={"miles"}>Miles</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      <div style={{ color: "transparent" }}>distance</div>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Distance
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="0"
                        name="workout_actual_distance"
                        type="number"
                        value={formik.values.workout_actual_distance}
                        error={
                          formik.touched.workout_actual_distance &&
                          formik.errors.workout_actual_distance
                        }
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_distance), // Set to true if value exists, otherwise false
                        }}
                      />
                      <div style={formik.errors.workout_actual_distance ? { color: "red" } : { color: "transparent" }}>{formik.errors.workout_actual_distance ?
                        formik.errors.workout_actual_distance : "distance"}</div>

                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <TextField
                        fullWidth
                        placeholder="Kilometer"
                        name="workout_actual_distance"
                        type="text"
                        disabled
                        value={formik.values.unit}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_distance), // Set to true if value exists, otherwise false
                        }}
                      />
                      <div style={{ color: "transparent" }}>distance</div>

                    </Grid>
                  </>
                )}

                {fieldName?.activitymetrices?.Duration && (
                  <>
                    <Grid item xs={6} sm={6} style={{ marginTop: "20px" }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Duration
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="00:00:00"
                        type="text"
                        id="timeInput"
                        InputLabelProps={{
                          shrink: Boolean(formValue?.planned_duration), // Set to true if value exists, otherwise false
                        }}
                        name="workout_planned_duration"
                        onChange={(e) =>
                          hnadleFormikDuration(e, "workout_planned_duration")
                        }
                        value={formik.values.workout_planned_duration}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.workout_planned_duration &&
                          formik.errors.workout_planned_duration
                        }
                        helperText={
                          formik.touched.workout_planned_duration &&
                          formik.errors.workout_planned_duration
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Duration
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="00:00:00"
                        type="string"
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_duration), // Set to true if value exists, otherwise false
                        }}
                        name="workout_actual_duration"
                        value={formik.values.workout_actual_duration}
                        onChange={(e) => {
                          const formattedTime = handleTimeChange(e);
                          formik.setFieldValue(
                            "workout_actual_duration",
                            formattedTime
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={4} sm={1}></Grid>
                <Grid item xs={12} sm={12}>
                  <Divider sx={{ margin: "10px 0px", bgcolor: "#FFEADC" }} />
                </Grid>
                {fieldName?.activitymetrices?.Zone && (
                  <>
                    <Grid item xs={6} sm={6}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Zone
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="0"
                        name="heartZone"
                        type="string"
                        disabled
                        value={formik.values.heartZone}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.planned_heartrate), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={1} sm={1}></Grid>
                  </>
                )}
                <br />
                {fieldName?.activitymetrices?.Heartrate && (
                  <>
                    <Grid item xs={4} sm={6}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Heart Rate
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="0"
                        disabled
                        name="workout_planned_heart_rate"
                        type="string"
                        value={`${formik.values.workout_planned_heart_rate
                          ? formik.values.workout_planned_heart_rate
                          : 0
                          }-${formik.values.workout_planned_heart_rate_end
                            ? formik.values.workout_planned_heart_rate_end
                            : 0
                          }`}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.planned_heartrate), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={3} sx={{ mt: 3 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Heart Rate
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="0"
                        name="workout_actual_heart_rate"
                        type="number"
                        value={
                          formik.values.workout_actual_heart_rate
                            ? formik.values.workout_actual_heart_rate
                            : ""
                        }
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_heartrate), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={3} sx={{ mt: 3 }}>

                      <FormControl fullWidth>

                        <Select
                          placeholder="123"
                          name="workout_actual_heart_unit"
                          value={
                            formik.values.workout_actual_heart_unit
                              ? formik.values.workout_actual_heart_unit
                              : "bpm"
                          }
                          onChange={formik.handleChange}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={"bpm"}>BPM</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* &nbsp; */}

                {fieldName?.activitymetrices?.Pace &&
                  formik.values?.activity_id !== 2 && (
                    <>
                      {/* &nbsp; */}
                      <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                        <InputLabel
                          shrink
                          id="form-layouts-separator-select-label"
                          style={{ fontSize: "20px", fontWeight: "500" }}
                        >
                          Pace
                        </InputLabel>
                        <TextField
                          fullWidth
                          placeholder="pace"
                          type="text"
                          disabled
                          name="workout_planned_pace"
                          value={`${formik.values.workout_planned_pace
                            ? formik.values.workout_planned_pace
                            : ""
                            }  ${formik.values.workout_planned_Unit
                              ? formik.values.workout_planned_Unit
                              : ""
                            }`}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: Boolean(formValue?.workout_planned_pace), // Set to true if value exists, otherwise false
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                        <InputLabel
                          shrink
                          id="form-layouts-separator-select-label"
                          style={{ fontSize: "20px", fontWeight: "500" }}
                        >
                          Pace
                        </InputLabel>
                        <TextField
                          fullWidth
                          placeholder="pace"
                          type="number"
                          name="workout_actual_pace"
                          value={formik.values.workout_actual_pace}
                          onChange={formik.handleChange}
                          InputLabelProps={{
                            shrink: Boolean(formValue?.pace), // Set to true if value exists, otherwise false
                          }}
                        />
                      </Grid>
                    </>
                  )}
                {formik.values?.activity_id === 2 && (
                  <>
                    {/* &nbsp; */}
                    <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Speed
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="speed"
                        type="string"
                        name="workout_planned_speed"
                        value={`${formik.values.workout_planned_speed} ${formik.values.unit !=
                          "undefined" &&
                          formik.values.unit
                          }/hr`}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.workout_planned_speed), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Speed
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="speed"
                        type="text"
                        name="speed"
                        value={formik.values.speed}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.speed), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                  </>
                )}

                {fieldName?.activitymetrices?.Power && (
                  <>
                    <Grid item xs={3} sm={3} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Power
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="123"
                        name="workout_planned_power"
                        value={formik.values.workout_planned_power}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.planned_power), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <Select
                          placeholder="123"
                          name="workout_planned_power_unit"
                          value={formik.values.workout_planned_power_unit ? formik.values.workout_planned_power_unit : "watts" }
                          onChange={formik.handleChange}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={"watts"}>Watt</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={3} sm={3} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Power
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="123"
                        name="workout_actual_power"
                        value={formik.values.workout_actual_power}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_power), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} sm={3} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <Select
                          placeholder="123"
                          name="workout_actual_power_unit"
                          value={formik.values.workout_actual_power_unit? formik.values.workout_actual_power_unit : "watts"}
                          onChange={formik.handleChange}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={"watts"}>Watt</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={4} sm={1}></Grid> */}
                  </>
                )}
                {fieldName?.activitymetrices?.RPE && (
                  <>
                    <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Rate of Perceived Exertion(RPE)
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="RPE"
                        type="number"
                        name="rpe_actual"
                        value={formik.values.rpe_actual}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.rpe_actual), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>{" "}
                  </>
                )}
                {fieldName?.activitymetrices?.Cadence && (
                  <>
                    <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                      <InputLabel
                        shrink
                        id="form-layouts-separator-select-label"
                        style={{ fontSize: "20px", fontWeight: "500" }}
                      >
                        Cadence
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder="123"
                        type="number"
                        name="workout_actual_cadence"
                        value={formik.values.workout_actual_cadence}
                        onChange={formik.handleChange}
                        InputLabelProps={{
                          shrink: Boolean(formValue?.actual_cadence), // Set to true if value exists, otherwise false
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <Select
                          placeholder="123"
                          defaultValue={3}
                          name="workout_actual_cadence_uom"
                          value={formik.values.workout_actual_cadence_uom}
                          onChange={formik.handleChange}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={3}>SPM</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
                {fieldName?.activitymetrices?.Cadence &&

                  <Grid item xs={12} sm={12}>
                    <Divider sx={{ margin: "10px 0px", bgcolor: "#FFEADC" }} />
                  </Grid>
                }
                {fieldName?.activitymetrices?.Cadence &&
                  <>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="h6"
                        style={{
                          color: "#E67E22",
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        How did you Post workout feel after the activity
                      </Typography>
                    </Grid>
                    {Object.keys(emojiFeelings).map((emoji, index) => (
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        key={index}
                        className={`cursor-pointer`}
                      >
                        <span
                          style={{
                            fontSize: "2em", // Add this line to increase the size of the emojis
                            background:
                              formik.values.postworkoutfeel_actual ===
                                emojiFeelings[emoji]
                                ? "lightblue"
                                : "none",
                          }}
                          role="img"
                          aria-label={emojiFeelings[emoji]}
                          onClick={() => {
                            formik.setFieldValue(
                              "postworkoutfeel_actual",
                              emojiFeelings[emoji]
                            );
                          }}
                        >
                          {emoji}
                        </span>
                      </Grid>
                    ))}
                  </>
                }
                {/*
              <Grid item xs={6} sm={6}>
                  <Checkbox
                    name="group_workout"
                    value={formik.values.group_workout}
                    onChange={(e) => {
                      formik.setFieldValue("group_workout", e.target.checked);
                    }}
                  />
                  Group Workout
                </Grid>
                <Grid item xs={6} sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{ backgroundColor: "#2563EB" }}
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            */}
              </Grid>

              {!fieldName?.activitymetrices?.Distance && formik?.values?.activity_id == 7 &&
                <>
                  <Grid item xs={4} sm={6} style={{ marginTop: "20px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} style={{width:"100%"}}>
                      <DemoContainer components={['TimePicker']}style={{width:"100%"}}>
                        <DemoItem label=" Meal time" style={{width:"100%"}}>
                          <TimePicker
                          style={{width:"100%"}}
                          value={formik.values.meal_time ? dayjs(moment('2022-04-17 ' + formik.values.meal_time, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm')) : dayjs('2022-04-17T12:00')}
                          
                          onChange={(newValue) => {
                            formik.setFieldValue('meal_time',newValue.format("hh:mm A")); // Updating the state on change
                          }}
                           
                            fullWidth
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>

                  </Grid>
                  <Grid item xs={4} sm={6} style={{ marginTop: "20px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} className='mealTime'>
                      <DemoContainer components={['TimePicker']}>
                        <DemoItem label=" Meal time">
                          <TimePicker
                            value={formik.values.actual_meal_time ? dayjs(moment('2022-04-17 ' + formik.values.actual_meal_time, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm')) : dayjs('2022-04-17T12:00')}
                            onChange={(newValue) => {
                              formik.setFieldValue('actual_meal_time',newValue.format("hh:mm A")); // Updating the state on change
                            }}
                            
                            fullWidth
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>

                  </Grid>
                  <Grid item xs={4} sm={5.5} style={{ marginTop: "20px" }}>
                    <InputLabel
                      shrink
                      id="form-layouts-separator-select-label"
                      style={{ fontSize: "20px", fontWeight: "500" }}
                    >
                      Adherence
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Adherence %"
                      type="number"
                      id="timeInput"
                      InputLabelProps={{
                        shrink: Boolean(formValue?.adherence), // Set to true if value exists, otherwise false
                      }}
                      name="adherence"
                      onChange={(e) =>
                        formik.setFieldValue("adherence", e.target.value)
                      }
                      value={formik.values.adherence}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.adherence &&
                        formik.errors.adherence
                      }
                      helperText={
                        formik.touched.adherence &&
                        formik.errors.adherence
                      }
                    />
                  </Grid>
                  <Grid item xs={4} sm={6} style={{ marginTop: "20px", marginLeft: "10px" }}>
                    <InputLabel
                      shrink
                      id="form-layouts-separator-select-label"
                      style={{ fontSize: "20px", fontWeight: "500" }}
                    >
                      Adherence
                    </InputLabel>
                    <TextField
                      fullWidth
                      placeholder="Adherence %"
                      type="number"
                      InputLabelProps={{
                        shrink: Boolean(formValue?.actual_adherence), // Set to true if value exists, otherwise false
                      }}
                      name="actual_aadherence"
                      value={formik.values.actual_adherence}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "actual_adherence",
                          e.target.value
                        );
                      }}
                    />
                  </Grid>
                </>
              }
              <Grid
                container
                xs={12}
                sm={12}
                spacing={1}
                style={{
                  margin: "2% 0% 0% 0%",

                  // padding: "2%",
                }}
              >
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={5}
                    placeholder="Write Here"
                    className="CommentTextarea"
                    name="comments"
                    value={formik.values.comments}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: Boolean(formValue?.comment), // Set to true if value exists, otherwise false
                    }}
                  />
                </Grid>
                {workoutID && (
                  <Grid item xs={12} sm={12}>
                    <CommentBox selectedValue={workoutID} />
                  </Grid>
                )}

                {/*
                <Grid item xs={12} sm={12}>
                  How important is this to you?
                </Grid>
                <Grid container xs={12} sm={12}>
                  <Grid item xs={4} sm={4}>
                    27
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    27
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    27
                  </Grid>
                </Grid>
*/}
                <Grid item xs={12} sm={12}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{
                      marginBottom: 2,
                      // color: "white",
                      // backgroundColor: "rgb(145, 85, 253)",
                      color: "white",
                      backgroundColor: "#E67E22",
                      fontSize: "20px",
                      fontWeight: "500",
                      marginTop: 2,
                    }}
                    type="submit"
                    loading={isLoading}
                    style={{ backgroundColor: "#E67E22" }}
                    disabled={allZones?.length === 0}
                    loadingPosition="start"
                  >
                    Submit
                  </LoadingButton>
                  {allZones?.length === 0 && (
                    <a
                      href={`/zone?userId=${assignedCocahId}`}
                      className="text-[#1976d2]"
                    >
                      Click here to create Zone first
                    </a>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Drawer>
    </div>
  );
};

export default WorkoutForm;
