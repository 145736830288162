import { FormLabel, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import { Forgotpassword } from "../../API/api-endpoint";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SlickCarousel from "../SlickCarousel";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSend = async (event) => {
    event.preventDefault();
    if(email){
      setIsLoading(true);
    let result = await Forgotpassword(email);
    console.log("result", result);
    if (result?.status) {
      Swal.fire({
        title: "Success",
        text: result?.message,
        icon: "success",
      });
      setIsLoading(false);
      navigate("/");
    } else {
      Swal.fire({
        title: "Error!!",
        text: result,
        icon: "error",
      });
      setIsLoading(false);
    }
    }
    else {
      Swal.fire({
        title: "Error!!",
        text: "Please enter the email address",
        icon: "error",
      });
    }
    
  };

  return (
    <div
      className="app__container heightAuto"
      style={{
        width: "100vw",
        padding: "40px",
        height: "100vh", // 100% of the viewport height
      }}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems:"center",
            padding: "20px"
          }}>
            <div sx={{ width: "100%" }}>
              <div className="mt0" style={{ marginTop: "150px" }}>
              <div className="headingCont mb-8">
              <Typography variant="h4" className="heading">Forgot<span style={{ color: "orange" }}> Password</span></Typography>{" "}
            </div>
                <form className="form1"
                  onSubmit={handleSend}
                >
                  <Grid sx={{ display: 'flex', flexDirection: 'column' }} container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >Email</FormLabel>
                      <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        value={email}
                        name="email"
                        type="email"
                        placeholder="Email"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{
                          color: "white",
                          backgroundColor: "#FFA654",
                          '&:hover': {
                            backgroundColor: '#FFA654', // Change to your desired hover color
                          },
                        }}
                        loading={isLoading}
                        loadingPosition="start"
                      >
                        Send Reset Link
                      </LoadingButton>
                    </Grid>
                    <div id="recaptcha"></div>
                  </Grid>
                </form>
                <br />
                <Typography variant="h6" component="div" style={{ fontSize: "16px", fontWeight: "500" }}>Already have an account?<span>
                  <a onClick={() => navigate("/")} className="cursor-pointer text-[#E67E22]" style={{fontWeight: "600" }}> Sign in</a>
                </span></Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}  >
            <SlickCarousel />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
