import {
  Chip,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  CreateReviewSection,
  getAllActivityData,
  updateRevieSection,
} from "../../API/api-endpoint";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import SlickCarousel from "../../pages/SlickCarousel";

const scoreData = [1, 2, 3, 4, 5];
const CreateRevieSection = ({
  fetchReport,
  setShowAssesmentModal,
  showAssesmentModal,
  editData,
  setEditData,
}) => {
  const [programList, setProgramList] = useState([]);
  console.log("editData", editData);
  const formik = useFormik({
    initialValues: {
      section: "",
      activity_id:""
    },
    validate: (values) => {
      const errors = {};
      if (!values.section) {
        errors.section = "Section Name is required";
      }
      if (!values.activity_id) {
        errors.activity_id = "Activity Name is required";
      }
      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleSubmitAssesmentForm(values, resetForm);
    },
  });
  console.log("formik", formik?.values);
  const getAllProgramsData = async () => {
    const response = await getAllActivityData();
    console.log("response", response);
    setProgramList(response?.rows);
  };
  useEffect(() => {
    getAllProgramsData();
  }, []);

  const handleSubmitAssesmentForm = async (data, resetForm) => {
    let response = "";
    if (editData?.id) {
      response = await updateRevieSection(data);
    } else {
      response = await CreateReviewSection(data);
    }
    if (response?.status) {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
      setEditData({});
      setShowAssesmentModal(false);
      fetchReport();
      resetForm();
      formik?.setValues({ section: "",activity_id:"" });
    } else {
      Swal.fire({
        title: "Error",
        text: response.message,
        icon: "error",
      });
    }
    console.log("response", response);
  };
  useEffect(() => {
    if (editData?.id) {
      const { srID, ...data } = editData;
      console.log("data", data);
      formik?.setValues(data);
    } else {
      setEditData({});
    }
  }, [editData?.id]);
  return (
    <Modal
      width={1200}
      open={showAssesmentModal}
      onCancel={() => {
        setShowAssesmentModal(false);
        setEditData({});
        formik.resetForm();
        formik?.setValues({ section: "",activity_id:"" });
      }}
      footer={<div></div>}
    >
      <div className="headingCont ">
        <span className="heading">{editData?.id ? "Edit " : "Create"}</span>{" "}
        <span className="orange heading">Section</span>
       
      </div>
      <div className="parentCont">
        <form className="form1" onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
          <Grid  className="mbtm"  item xs={12} sm={11}>
              <FormLabel>Activity Name<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                size="small"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                         scrollbarColor:"#E67E22 white",
                         scrollbarWidth:"thin"
                      },
                    },
                  },
                }}
                name="activity_id"
                value={formik?.values?.activity_id || "placeholder"}
                onChange={formik.handleChange}
                error={formik.touched.activity_id && formik.errors.activity_id}
                helperText={formik.touched.activity_id && formik.errors.activity_id}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={"placeholder"} disabled>
                  Select Activity
                </MenuItem>
                {programList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.id}>
                      {value?.activity_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid className="marbot" item xs={12} sm={11}>
              <FormLabel>Section Name<span className="text-[red]">*</span></FormLabel>
              <TextField
                fullWidth
                placeholder="Section name"
                size="small"
                type="text"
                name="section"
                value={formik?.values?.section}
                onChange={formik.handleChange}
                error={formik.touched.section && formik.errors.section}
                helperText={formik.touched.section && formik.errors.section}
              />
            </Grid>

            <Grid item xs={12} sm={10}>
                <Button 
                className="btn"
                key="submit"
                type="primary"
                
                onClick={() => formik.handleSubmit()}
              >
                Submit
              </Button>
              </Grid>
          </Grid>
        </form>

        <div className="slick-container">
          <SlickCarousel />
        </div>
      </div>
    </Modal>
  );
};
export default CreateRevieSection
