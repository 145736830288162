import {
  Box,
  Fade,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../styles/navbar.css";
import Switch from "@mui/material/Switch";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUsersToCreateGroup } from "../../redux/action/usersAction";
import InfoIcon from "@mui/icons-material/Info";

export default function NewGroupNav({ setOpenPageOne,isMobileView}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  console.log("setOpenPageOne",setOpenPageOne);

  const handleGoTo = async () => {
    await dispatch(clearUsersToCreateGroup());
    if (isMobileView) {
      navigate("/start-new-chat")
    }
    else{
      setOpenPageOne("create-group")
    }
    
  };

  return (
    <>
      <Toolbar className="appBar">
        <Box sx={{ display: "flex" }}>
          <KeyboardBackspaceIcon onClick={handleGoTo} sx={{ margin: "auto",cursor:"pointer" }} />
          <Box>
            <Typography
              className="chatTitle"
              style={{ paddingLeft: "1rem", fontSize: "16px" }}
            >
              Create New Group :{" "}
              <span style={{ color: "#707070" }}>Step 1/2</span>
            </Typography>
            {/* <Typography
              className="select-buddies-text"
              style={{ marginTop: "-1rem" }}
            >
              6/100 Selected
            </Typography> */}
          </Box>
        </Box>
        <Box>
          <InfoIcon />
          {/* <img style={{ paddingRight: '0.5rem' }} height={20} src="/images/info-icon.png" alt="info" /> */}
        </Box>
      </Toolbar>
    </>
  );
}
