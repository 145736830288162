import { RecaptchaVerifier } from "firebase/auth";
import { Box, Card, CardContent, FormLabel, Grid, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import {
  verifyOTPAndMerge,
  sendOTPToUser,
  auth,
} from "../../API/firebase.config";
import { useNavigate } from "react-router-dom";
import Background from "../../Images/Background.png";
import Swal from "sweetalert2";
import { updatePhoneVerificationStatus } from "../../API/api-endpoint";
import PhoneInput from 'react-phone-input-2'
import "./style.css";
import LayoutImage from "./LayoutImage";
import SlickCarousel from "../SlickCarousel";

const PhoneOtpVerification = () => {
  const [phone, setPhone] = useState("");
  const [hasFilled, setHasFilled] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  console.log("phone", phone, otp, otp?.length);
  const handleSend = async (event) => {
    event.preventDefault();
    // Check if the phone number is valid (10-digit)
    if (phone.length !== 12) {
      Swal.fire({
        title: "Error!!",
        text: "Please enter a 10-digit phone number",
        icon: "error",
      });
      return;
    }

    setIsLoading(true);

    try {
      // Create a reCAPTCHA verifier
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha",
        {
          size: "invisible", // or "normal" if you want to show the reCAPTCHA box
        },
        auth
      );

      // Render and verify the reCAPTCHA
      const widgetId = await recaptchaVerifier.render();
      const event = await recaptchaVerifier.verify();

      // Send OTP to the phone number
      const result = await sendOTPToUser(`+${phone}`, event);

      if (result.success) {
        Swal.fire({
          title: "Success",
          icon: "success",
        });
        setVerificationId(result.data);
        setIsLoading(false);
        setHasFilled(true);
        // setPhone("")
      } else {
        Swal.fire({
          title: "Error!!",
          text: result.message,
          icon: "error",
        });
        setIsLoading(false);
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      Swal.fire({
        title: "Error!!",
        text: "Something went wrong. Please try again later.",
        icon: "error",
      });
      setIsLoading(false);
    }
  };

  const verifyOtp = async (event) => {
    if (otp.length === 6) {
      try {
        setIsLoading(true);
        const result = await verifyOTPAndMerge(verificationId, otp);

        if (result.success) {
          Swal.fire({
            title: "Success!!",
            text: "OTP has been Verified Successfully",
            icon: "success",
          });
          const phoneWithoutCountryCode = phone.slice(2);
          localStorage.setItem("phonenumber", phoneWithoutCountryCode);
          navigate("/onboarding-flow");
          await updatePhoneVerificationStatus();
        } else {
          if (result.message == "SESSION_EXPIRED") {
            setHasFilled(false)
            setOtp("")
            Swal.fire({
              title: "Error!!",
              text: result.message,
              icon: "error",
            });
          } else {

            // setHasFilled(false);
            Swal.fire({
              title: "Error!!",
              text: result.message,
              icon: "error",
            });
          }

          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        // setHasFilled(false);
        Swal.fire({
          title: "Error!!",
          text: "An error occurred while verifying OTP",
          icon: "error",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      Swal.fire({
        title: "Error!!",
        text: "Please enter a 6-digit OTP",
        icon: "error",
      });
    }
  };

  if (!hasFilled) {
    return (
      <div
        className="app__container  heightAuto"
        style={{
          width: "100vw",
          padding: "40px",
          height: "100vh", // 100% of the viewport height
        }}
      >
        <Grid container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className="p10" style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "50px"
            }}>

              <div>
                <div>
                <Box sx={{ marginBottom: 2, marginTop: 2 }}>
                <Typography variant="h6" component="div">
                    Enter your phone number
                  </Typography>
                  </Box>
                  <form
                    onSubmit={handleSend}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                  <div  style={{width:"100%"}}>
                    <PhoneInput
                    style={{width:"100%"}}
                      country={'in'}
                      // disableCountryCode={true}
                      countryCodeEditable={false}
                      // inputStyle={{
                      //   borderColor: (props.touched && props.error) && "red"
                      // }}
                      value={phone}
                      onChange={setPhone} />
                      </div>
                      <div style={{width:"100%"}}>
                    <LoadingButton
                      type="submit"
                      // fullWidth
                      variant="contained"
                      sx={{
                        marginTop: "20px",
                        // color: "white",
                        // backgroundColor: "rgb(145, 85, 253)",
                        color: "white",
                        backgroundColor: "#FFA654",
                            '&:hover': {
                              backgroundColor: '#FFA654', // Change to your desired hover color
                            },
                      }}
                      loading={isLoading}
                      loadingPosition="start"
                      id="sendCode"
                    // onClick={handleSend}
                    >
                      Send Code
                    </LoadingButton>
                    </div>

                    <div id="recaptcha"></div>
                  </form>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={6} style={{ background: "rgba(252, 250, 255, 1)", height: "600px", textAlign: "center" }} >
              <LayoutImage />
            </Grid> */}
             <Grid item xs={12} sm={6} md={5} >
              <SlickCarousel />
            </Grid>
          </Grid>
        </Grid>
      </div>

    );
  } else {
    return (
      <div
        className="app__container  heightAuto"
        style={{
          width: "100vw",
          padding: "40px",
          height: "100vh", // 100% of the viewport height
        }}
      >
      <Grid container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}  style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: "50px"
              }}>
        <div >
          <div>
          <Box sx={{ marginBottom: 2, marginTop: 2 }}>
          <Typography variant="h6" className="font16" component="div">
          An OTP has been sent to your mobile, please enter it below to verify your mobile number.
          </Typography>
        </Box>
        <FormLabel sx={{ fontSize: "14px", fontWeight: "600", color: "background: rgba(13, 20, 28, 1)", marginBottom: "10px" }} >OTP</FormLabel><br />
            <TextField
            fullWidth
              variant="outlined"
              value={otp}
              type="number"
              size="small"
              onChange={(event) => setOtp(event.target.value)}
            />
            <br/>
            <LoadingButton
              variant="contained"
              // fullWidth
              sx={{
                
                marginTop: "20px",
                // color: "white",
                // backgroundColor: "rgb(145, 85, 253)",
                color: "white",
                backgroundColor: "#FFA654",
                '&:hover': {
                  backgroundColor: '#FFA654', // Change to your desired hover color
                },
              }}
              onClick={verifyOtp}
              loading={isLoading}
              loadingPosition="start"
            >
              Verify Otp
            </LoadingButton>
          </div>
        </div>
        </Grid>
              {/* <Grid item xs={12} sm={6} style={{ background: "rgba(252, 250, 255, 1)", height: "600px", textAlign: "center" }} >
                <LayoutImage />
              </Grid> */}
               <Grid item xs={12} sm={12} md={5} >
              <SlickCarousel />
            </Grid>
            </Grid>
          </Grid>
      </div>
    );
  }
};

export default PhoneOtpVerification;
