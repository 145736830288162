import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress,  Grid, Pagination, TextField } from "@mui/material";
import "../../components/AssignCoach/Assign.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from "../../components/Header";
import { deleteLevel,  getAlllevels,  } from "../../API/api-endpoint";
import { IconEdit, IconTrash } from "@tabler/icons";
import { Modal } from "antd";
import CreateLevel from "../../components/FeedData/CreateLevel";
import MenuItem from "@mui/material/MenuItem";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const Levels = () => {
    const { confirm } = Modal;
    const [reportData, setReportData] = useState()
    const [editData, setEditData] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useState('');
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);


    const fetchReport = async () => {
        const response = await getAlllevels()
        setIsLoading(false)

        setReportData(response?.rows)
    }
    useEffect(() => {
        fetchReport()
    }, [])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState('All');

    const handleActivityFilterChange = (event) => {
        setSelectedActivityFilter(event.target.value);
        setCurrentPage(1); // Reset the current page when the filter changes
    };

    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            if (selectedActivityFilter === 'All') {
                return row?.level?.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return (
                    row?.level?.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    row?.level?.toLowerCase().trim() === selectedActivityFilter.toLowerCase()
                );
            }
        });
    }, [reportData, selectedActivityFilter, searchTerm]);
    let PageSize = searchTerm || selectedActivityFilter !== 'All' ? filteredList?.length : 15;
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData,filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const DeleteZones = async (id) => {
        const response = await deleteLevel(id)
        setCurrentPage(1)
        fetchReport()
        console.log("res",response);
    }
    const showDeleteConfirm = (id) => {
        confirm({
          title: 'Are you sure delete this level?',
          icon: <ExclamationCircleFilled />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            DeleteZones(id)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid  className="mt15" item xs={12} sm={4} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => {setShowAssesmentModal(true)
                                    setEditData({})
                                }}>Create Level</Button>
                            </Grid>
                            <Grid item className="mt15 textStart" xs={12} sm={6} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField
                                    select
                                    size="small"
                                    className="width220"
                                    label="Filter by Activity"
                                    style={{ width: '150px' }} 
                                    value={selectedActivityFilter}
                                    onChange={handleActivityFilterChange}
                                    
                                >
                                    <MenuItem value="All">All</MenuItem>
                                    <MenuItem value="Beginner">Beginner</MenuItem>
                                    <MenuItem value="Novice">Novice</MenuItem>
                                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                                    <MenuItem value="Advanced">Advanced</MenuItem>
                                    <MenuItem value="Elite">Elite</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item  className="mt0 textStart" xs={9} sm={2} sx={{ textAlign: "end",marginTop: "30px" }}>
                            <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Level.." />
                        </Grid>
                        </Grid>
                    </div>
                    <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                    <Table stickyHeader sx={{ minWidth: 700,}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sr ID</StyledTableCell>
                                <StyledTableCell align="left">Level Name</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {isLoading?(
                            <CircularProgress className="m-6" />):(
                                <>
                            {checkLastPage?.length > 0 ? (
                                <>
                                    {checkLastPage?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">
                                                {row?.srID}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row?.level}

                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                            <div className="flex ">
                                            <span className="px-2 cursor-pointer">
                                              <IconEdit
                                                color="blue"
                                                onClick={() => {
                                                    setShowAssesmentModal(true);
                                                  setEditData(row);
                                                }}
                                              />
                                            </span>
                                            <span className="px-2 cursor-pointer">
                                              <IconTrash
                                                color="red"
                                                onClick={() => showDeleteConfirm(row?.id)}
                                              />
                                            </span>
                                          </div>

                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </>
                            ) : (
                                <div className="p-4">No data found</div>
                            )}
                            </>
                            )}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            {
                <CreateLevel setEditData={setEditData} editData={editData} fetchReport={fetchReport} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />}
        </div>
    )
}
export default Levels
