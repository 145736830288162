import React, { useEffect, useState } from "react";
import ChatLanding from "./ChatLanding";

const ChatMobile = () => {

  const [isUnRead, setIsUnRead] = useState(true);


  return (
    <div>
        <ChatLanding isUnRead={isUnRead} setIsUnRead={setIsUnRead} />
    </div>
  );
};

export default ChatMobile;
