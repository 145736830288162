import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import GlobalContext from "../context/GlobalContext";
import {
  createTrainingPlanMasterMaping,
  createTraningBlocksWorkout,
  getAllWorkoutLibrary1,
  getAllWorkouts,
  getAllWorkoutsByLiabrary,
  getTrainingPlanMasterMapingByTrainingPlanID,
} from "../API/api-endpoint";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getTrainingPlanMasterMappingByTrainingId, updateColumnsinTrainingData, updateLastColumnDayInRow } from "../store/slices/MultiTrainingBlocksSlice";
import   "../index.css"
const LibraryModal = ({ userId }) => {
  let dispatch = useDispatch();
  const { setSelectedEvent, setShowLibraryModal, daySelected } =
    useContext(GlobalContext);

  let selectedDayAndWeekNumber = useSelector(
    (state) => state.MultiTrainingBlocksSlice.selecteddayandweek
  );

  let CurrentTrainingBlock = useSelector(
    (state) => state.MultiTrainingBlocksSlice.CurrentTrainingBlock
  );

  const [items, setItem] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    getAllWorkoutFromDB();
  }, []);

  const getAllWorkoutFromDB = async () => {
    let result = await getAllWorkoutLibrary1();
    const allWorkouts = [];
// Iterate through the array of objects and extract workout_library_workouts
result?.library?.forEach(library => {
    if (library.workout_library_workouts && library.workout_library_workouts.length > 0) {
        allWorkouts.push(...library.workout_library_workouts);
    }
});

    const resultdata = allWorkouts?.map((workout) => {
      return {
        ...workout, name: workout?.workout_master?.workout_planned_title
      };
    });
    setItem(resultdata)
  };
  useEffect(() => {
  }, [items]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results);
  };

  // const handleOnHover = (result) => {
  //   // the item hovered
  //   console.log(result);
  // };

  const handleOnSelect = async (item) => {
    if (CurrentTrainingBlock.name === null) {
      Swal.fire({
        title: "Error",
        text: "Please First Select any Training Block",
        icon: "error",
      });
    } else {
      // the item selected selectedDayAndWeekNumber
      let newObject = {
        weekId: parseInt(selectedDayAndWeekNumber.week),
        workoutId: [item.workout_id],
        dayId: parseInt(selectedDayAndWeekNumber.day),
        trainingPlanId: CurrentTrainingBlock.id,
        status: 1,
      };

      let response = await createTrainingPlanMasterMaping(newObject);
      if (
        response.message === "Training plan master mapping created successfully"
      ) {
        setShowLibraryModal(false);
        dispatch(
          getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
        );
        
        setTimeout(() => {
          dispatch(updateColumnsinTrainingData());
          dispatch(updateLastColumnDayInRow());
        }, 300);
      }
    }
  };

  const handleOnFocus = () => {
  };

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
        <span style={{ display: "block", textAlign: "left" }}>
          {item["workout-planned-duration"]} |{" "}
          {item["workout-planned-distance"]}
        </span>
      </>
    );
  };

  return (
    <div className="h-screen w-full fixed left-0 -top-32 flex justify-center items-center">
      <form className="bg-white  w-80p rounded-lg shadow-2xl w-1/4">
        <div style={{backgroundColor:"#FFEADC"}} className="bg-gray-100 px-4 py-3 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400" style={{color:"#E67E22"}} >
            drag_handle
          </span>
          <span style={{fontSize:"medium"}}>Add workout</span>
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                setShowLibraryModal(false);
                setSelectedEvent();
              }}
            >
              <span style={{color:"#E67E22"}} className="material-icons-outlined text-black-600">
                close
              </span>
            </button>
          </div>
        </div>
        <div  style={{backgroundColor:"#FFEADC"}} className="bg-gray-100 px-4 py-3 flex justify-between items-center">
          <div style={{ width: "100%" }} className="fix-search">
            <ReactSearchAutocomplete
              items={items}
              onSearch={handleOnSearch}
              //onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              autoFocus
              formatResult={formatResult}
              placeholder="Search workouts"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LibraryModal;
