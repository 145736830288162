import React, {useState,useEffect} from "react";
import NavGroupInfo from "../../../components/navbar/NavGroupInfo";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../../../../components/Header";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

export default function StartNewChat({setOpenPageOne,openPageOne}) {
  const paper = {
    padding: "0rem",
    minHeight: "90vh",
    maxWidth: "33vw",
    marginTop: "90px",
    paddingBottom: "5rem",
    backgroundColor: "white",
    // backgroundImage: 'url("https://cdn.wallpapersafari.com/80/83/K7l2qB.jpg")',
  };

  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
    <Header />
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        sx={{ flexGrow: 1, position: "relative" }}
        elevation={10}
        style={paper}
        className="unsetMaxWidth"
      >
        <NavGroupInfo groupName={"Start a New Chat"} setOpenPageOne={setOpenPageOne} openPageOne={openPageOne}/>

        <Box
         onClick={() => {
          if(isMobileView){
            navigate("/create-new-group/step-first")
          }
          else{
            setOpenPageOne("stepOne")
          }
          
      }}
          
          sx={{
            backgroundColor: "#F5F5F5",
            padding: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            cursor:"pointer",
            marginTop: "5px"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", padding: "6px" }}>
            <Avatar sx={{ backgroundColor: "#E67E22" }}>
              {/* <img src="/chats-icon.png" alt="" /> */}
              <QuestionAnswerIcon />
            </Avatar>
            <Typography
              className="start-new-chat"
              sx={{ paddingLeft: "1.2rem" }}
            >
              Create New Group
            </Typography>
          </Box>
          <img src="/cross-arrow.png" alt="cross-arrow" />
        </Box>
      </Paper>
    </Box>
    </>
  );
}
