import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Menu,
  Typography,
  TextField,
  Stack,
  Chip,
  FormControl,
  InputLabel,
  Input,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../pages/Workouts/componants/ManageWorkouts/AddWorkout.css";
import { MdOutlineAutoDelete } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import SellIcon from "@mui/icons-material/Sell";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoEyeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ViewTrainingBlock from "./ViewTrainingBlock";
import EditWorkout from "./EditWorkout";
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import { HiDotsVertical } from "react-icons/hi";

const TrainingSummary = ({
  
  data,
  handleClick,
  summaryModalVisible,
  setSummaryModalVisible,
  handleCopyWeeklyWorkout,
  PasteWeeklyWorkouts,
  openCopyModal,
  setCopyOpen,
  open,
  rowData
}) => {
  const [isLoading, setisLoading] = useState(false);
  let counter = data.counter;
  let CurrentTrainingBlock = useSelector(
    (state) => state.MultiTrainingBlocksSlice.CurrentTrainingBlock
  );
  let lastColumnDayInRow = useSelector(
    (state) => state.MultiTrainingBlocksSlice.lastColumnDayInRow
  );
  
  
//   console.log("rowdata1", rowData);
const weekData = data.flatMap(item => 
    item.children.filter(child => child.isWeekCount)
  );
 
console.log("weekdata", weekData);
  return isLoading ? (
    <div>Loading Data...</div>
  ) : (
    <Modal
      open={summaryModalVisible}
      onCancel={() => setSummaryModalVisible(false)}
      footer={null}
      title="Summary"
    >
           {weekData.map((data) => (
            <div  style={{
                color: "#6B7A99",
                backgroundColor:"white",
                borderRadius:"5px",
                padding: "10px",
                border: ` 3px solid gray`,
                width: "100%",
                marginBottom:"20px"
              }}
               key={`empty-${counter}`} className="" id={data?.id}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ fontWeight: "bold", marginTop: "5px" }} className="text-gray-500">
                  Week {data.id.slice(5, data?.id?.indexOf("-row"))}
                </span>
                {/* <span>
                  <HiDotsVertical
                    style={{ color: "#E67E22" }}
                    // onClick={(e) => {
                    //   if (CurrentTrainingBlock?.id) {
                    //     handleClick(e, data?.id?.slice(5, data?.id.indexOf("-row")), CurrentTrainingBlock?.id);
                    //   }
                    // }}
                    className="cursor-pointer"
                  />
                </span> */}
              </div>
              {/* <Menu
            id="basic-menu"
            anchorEl={openCopyModal}
            open={open}
            onClose={() => setCopyOpen(false)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleCopyWeeklyWorkout} >Copy Weekly Workout</MenuItem>
            <MenuItem onClick={PasteWeeklyWorkouts} >Paste Weekly Workout</MenuItem>
          </Menu> */}

          {lastColumnDayInRow?.length !== 0 ? (
            lastColumnDayInRow?.map((item) => {
              if (
                item?.rowName === data?.id?.slice(0, data?.id?.indexOf("-count")) &&
                item?.splitArray?.length !== 0
              ) {
                return (
                  <div key={item?.rowName}>
                    {" "}
                    {/* Add a unique key to the parent div */}
                    {item?.splitArray?.map((activity, index) => (
                      <table key={index} className="customCalenderTable">
                        <tbody>
                          <tr>
                            {/* <td className="customcalenderTdTh">
                              <PoolIcon />
                            </td> */}
                            <td className="customcalenderTdTh  font14">
                              {Object?.keys(activity)[0]}
                            </td>
                            <td className="customcalenderTdTh alignRight  font14">
                               <b>{Number(parseFloat(Object?.values(activity)[0]).toFixed(1))}</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ))}
                  </div>
                );
              }
            })
          ) : (
            <div></div>
          )}
            </div>
          ) )}
     
    </Modal>
  );
};

export default TrainingSummary;