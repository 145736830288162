import React, { useState, useCallback, useEffect, useContext } from "react";
import DropZone from "./DropZone";
import SideBar from "./SideBarItem";
import Row from "./Row";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
} from "./helpers";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { SIDEBAR_ITEM, COMPONENT, COLUMN } from "./constants";
import shortid from "shortid";
import { useDispatch, useSelector } from "react-redux";
import {
  genrateGrid,
  onNext,
  onPrev,
  UpdateMasterDataWithUpdatedEvent,
  updateLayout,
  updateComponant,
  updateColumnsinTrainingData,
  getTrainingPlanMasterMappingByTrainingId,
  updateLastColumnDayInRow,
  handlecurrentTrainingBlockData,
} from "../../store/slices/MultiTrainingBlocksSlice";
import { IoCloseSharp } from "react-icons/io5";

import Header from "../../components/Header";
// import WorkoutDrawer from "../../components/WorkoutDrawer";

import GlobalContext from "../../context/GlobalContext";
import LibraryModal from "../../components/LibraryModal";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  DeleteWorkoutFromTrainingPlanMasterMaping,
  createTrainingPlanMasterMaping,
  getTrainingPlanMasterMapingByTrainingPlanID,
  updateTrainingPlanMasterMapingShileDND,
} from "../../API/api-endpoint";
import WorkoutSidebar from "./Searchbar/searchbar";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ManageUserSidebar from "./ManageUserSidebar";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaChevronRight } from "react-icons/fa";
import TrainingSummary from "./TrainingSummary";

const Container = () => {
  const { setShowLibraryModal, setDaySelected, showLibraryModal } =
    useContext(GlobalContext);
  const dispatch = useDispatch();
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  let masterData = useSelector(
    (state) => state.MultiTrainingBlocksSlice.masterData
  );
  let layout = useSelector((state) => state.MultiTrainingBlocksSlice.layout);
  console.log("layout",layout);
  let components = useSelector(
    (state) => state.MultiTrainingBlocksSlice.componant
    );
    let startingWeek = useSelector(
      (state) => state.MultiTrainingBlocksSlice.startingWeek
      );
      let endingWeek = useSelector(
        (state) => state.MultiTrainingBlocksSlice.endingWeek
        );
        
        let CurrentTrainingBlock = useSelector(
          (state) => state.MultiTrainingBlocksSlice.CurrentTrainingBlock
          );
          
          let traningblockdata = useSelector(
    (state) => state.MultiTrainingBlocksSlice.traniningBlockData
  );
  useEffect(() => {
    dispatch(genrateGrid());
    
  }, []);

  useEffect(() => {
    setPreTrainingBlockData();
  }, [traningblockdata]);

  const setPreTrainingBlockData = () => {
    if (traningblockdata.length === 0) {
      return false;
    } else {
      dispatch(updateColumnsinTrainingData());
      dispatch(updateLastColumnDayInRow());
    }
  };
  //when any workouts drag from sidebar add into the calender
  const createNewWorkoutFromSidebar = async (newObject) => {
    let response = await createTrainingPlanMasterMaping(newObject);
      if (
        response.message === "Training plan master mapping created successfully"
      ) {
        setShowLibraryModal(false);
        dispatch(
          getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
        );
        
        setTimeout(() => {
          dispatch(updateColumnsinTrainingData());
          dispatch(updateLastColumnDayInRow());
        }, 300);
      }
  };

  //when any workouts drag and drop into calander
  const updateTrainingPlanMasterMapingwhileDND = async (
    body,
    oldweekID,
    olddayID,
    mappingID
  ) => {
    let trainingBlockId, trainingPlanMasterMappingId;
    const targetWeekId = oldweekID; // Replace with the week_id you want to find
    const targetDayId = olddayID; // Replace with the day_id you want to find
    const matchingItems = traningblockdata.filter((item) => {
      return item.week_id === targetWeekId && item.day_id === targetDayId;
    });
    if (matchingItems.length > 0) {
      const firstMatchingItem = matchingItems[0];
      trainingPlanMasterMappingId =
        firstMatchingItem.training_plan_master_mapping_id;

      trainingBlockId = firstMatchingItem.training_block_id.training_block_id;
    }
     
    let newObject = {
      weekId: body?.weekId,
      dayId: body?.dayId,
      workout_id: body?.workout_id,
      status:1
    };
console.log("CurrentTrainingBlock",CurrentTrainingBlock,body);
  let response =  await DeleteWorkoutFromTrainingPlanMasterMaping(
    newObject,
    mappingID
  );
  if (response.message ==="Training plan master mapping updated successfully") {
    handleCurrentTrainingBlock(CurrentTrainingBlock)
 
  }
  };
  const handleCurrentTrainingBlock = (trainingBlockData) => {
    dispatch(
      handlecurrentTrainingBlockData({
        id: trainingBlockData?.id,
        name: trainingBlockData?.name,
      })
    );
    dispatch(
      getTrainingPlanMasterMappingByTrainingId(trainingBlockData?.id)
    );

    //its update the layout when select any other training plan
    setTimeout(() => {
      dispatch(updateColumnsinTrainingData());
      dispatch(updateLastColumnDayInRow());
    }, 300);
  };
  const handleDrop = useCallback(
    (dropZone, item) => {
      console.log("item>>>>>>",item,dropZone);
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = {
        id: item.id,
        type: item.type,
        isHidden: item.isHidden,
        content: item?.component?.content,
      };
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: shortid.generate(),
          isHidden: item.isHidden,
          ...item.component,
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
          isHidden: item.isHidden,
          content: item.component.content,
        };
    //     dispatch(
    //       updateComponant({
    //         ...components,
    //         [newComponent.id]: newComponent,
    //       })
    //     );
    //     const updatedLayout = handleMoveSidebarComponentIntoParent(
    //       layout,
    //       splitDropZonePath,
    //       newItem
    //     );
        // dispatch(UpdateMasterDataWithUpdatedEvent(updatedLayout));
        let newID = dropZone.path;
        let newweekID = item?.workout_id? parseInt(newID.slice(0, newID.indexOf("-"))) :parseInt(newID.slice(0, newID.indexOf("-"))) ;
        let newdayID = item?.workout_id?parseInt(
          newID.slice(newID.indexOf("-") + 1, newID.lastIndexOf("-"))
        ) :parseInt(
          newID.slice(newID.indexOf("-") + 1, newID.lastIndexOf("-"))
        ) + 1;
          
        let newObject = {
          weekId: parseInt(newweekID),
          workoutId: [item?.workout_id?item?.workout_id:item["workout-id"]],
          dayId: parseInt(newdayID),
          trainingPlanId: CurrentTrainingBlock.id,
          status: 1,
        };
        createNewWorkoutFromSidebar(newObject);
        // dispatch(updateLayout(updatedLayout));


        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");
      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          const updatedLayout = handleMoveWithinParent(
            layout,
            splitDropZonePath,
            splitItemPath
          );
          dispatch(updateLayout(updatedLayout));
          dispatch(UpdateMasterDataWithUpdatedEvent(updatedLayout));

          return;
        }
        console.log("item>>>>>",item);
        let oldid = item.path;
        let newID = dropZone.path;
        let workout_id = item.id;
        let mappingID= item?.mappingID
        let oldweekID = parseInt(oldid.slice(0, oldid.indexOf("-")));
        let olddayID =
          parseInt(
            oldid.slice(oldid.indexOf("-") + 1, oldid.lastIndexOf("-"))
          ) ;
        let newweekID = parseInt(newID.slice(0, newID.indexOf("-")));
        let newdayID =
          parseInt(
            newID.slice(newID.indexOf("-") + 1, newID.lastIndexOf("-"))
          ) ;

        let newObject = {
          weekId: newweekID,
          dayId: newdayID,
          workout_id: workout_id,
        };
        updateTrainingPlanMasterMapingwhileDND(newObject, oldweekID, olddayID,mappingID);
        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        const updatedLayout = handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        );
        dispatch(updateLayout(updatedLayout));
        dispatch(UpdateMasterDataWithUpdatedEvent(updatedLayout));
        
        return;
      }

      // 3. Move + Create
      const updatedLayout = handleMoveToDifferentParent(
        layout,
        splitDropZonePath,
        splitItemPath,
        newItem
      );
      dispatch(updateLayout(updatedLayout));
      dispatch(UpdateMasterDataWithUpdatedEvent(updatedLayout));
    },
    [layout, components]
  );
  
  const renderRow = (row, currentPath,setCopyWorkoutData,CopyWorkoutData) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        setCopyWorkoutData={setCopyWorkoutData}
        CopyWorkoutData={CopyWorkoutData}
        summaryModalVisible={summaryModalVisible}
        setSummaryModalVisible={setSummaryModalVisible}
      />
    );
  };

  const onNextClick = () => {
    dispatch(onNext());
    console.log("wwww",masterData.length);
    // if (masterData.length < endingWeek + 12) {

      dispatch(genrateGrid());
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    // }
  };

  const onPrevClick = () => {
    dispatch(onPrev());
    console.log("dsfg",masterData.length,startingWeek);
    // if (masterData.length > startingWeek ) {

      dispatch(genrateGrid());
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(CurrentTrainingBlock.id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    // }
  };

  const [pathname, setPathName] = useState("");
  const [togglesidebar, setTogglesidebar] = useState(false);
  const [manageUserSidebar, setManageUserSidebar] = useState(false);
  const [CopyWorkoutData, setCopyWorkoutData] = React.useState({});
  useEffect(() => {
    const path = window.location.pathname;
    setPathName(path);
  }, []);
  useEffect(() => {
    console.log("isOpenSidebar",isOpenSideBar);
  }, [isOpenSideBar]);
  // dont use index for key when mapping over items
  // causes this issue - https://github.com/react-dnd/react-dnd/issues/342
  return (
    <div>
      <Header />
      {/*
    <WorkoutDrawer />
    */}
      {showLibraryModal ? (
        <div className="fixed z-50">
          <LibraryModal /> {/**userId={userId} */}
        </div>
      ) : (
        ""
      )}
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4">
      {!isOpenSideBar && (
        <div style={isMobileView ? {marginTop:"-10px",backgroundColor:"#FFEADC",zIndex:"10",width:"100vw",display:"block"} : {marginTop:"-10px",backgroundColor:"#FFEADC",zIndex:"10"}} className="hidden xl:block pt-4 pl-2 pr10 fixed top-24 xl:w-2/12 left-0 overflow-scroll h-[90vh]">
        { isMobileView &&
          <div style={{display:"flex",justifyContent:"end"}}>
      <IoCloseSharp 
        onClick={() => setIsOpenSideBar(true)} 
        color="#E67E22" 
        className="cursor-pointer relative top-2 right-[10px]" 
        style={{ fontSize: "30px" }} 
      />
      </div>}
          {togglesidebar ? (
            <WorkoutSidebar setTogglesidebar={setTogglesidebar} />
          ) : manageUserSidebar ? (
            <ManageUserSidebar
              setTogglesidebar={setTogglesidebar}
              setManageUserSidebar={setManageUserSidebar}
            />
          ) : (
            <SideBar isOpenSideBar={isOpenSideBar} isMobileView={isMobileView} setIsOpenSideBar={setIsOpenSideBar} setTogglesidebar={setTogglesidebar} />
          )}
        </div>
      )}
      {isOpenSideBar && isMobileView &&
          <GiHamburgerMenu
          onClick={() => setIsOpenSideBar(false)} 
           
          className="cursor-pointer mt30p ml-1" 
          style={{ fontSize: "30px",color:"#E67E22",zIndex:"10"  }} 
        />
          }
        <div className="lg:col-span-4 mt30 flex flex-col md:flex-col  p-4 w-full xl:w-10/12 right-0 top-16 absolute overflow-y-scroll">
          <div className="calendar-header mt-3" style={{display:"flex",alignItems: "center",fontSize:"small"}}>
            <div>{CurrentTrainingBlock.name}</div>
            <button
            disabled={startingWeek==1}
              onClick={onPrevClick}
              style={{ border: 0, color:"#E67E22" }}
              className="text-gray-500"
            >
              <ArrowBackIosIcon />
            </button>
            <div>
              <h2 className="ml-4 text-xl text-gray-500 font-bold font14">
                {/* week {startingWeek} - week {endingWeek} */}
                {isMobileView ? `week ${startingWeek} - ${endingWeek}` : `week ${startingWeek} - week ${endingWeek}` }
              </h2>
              <h4 className="ml-4 text-sm text-gray-500 font14">
                (12 weeks per page)
              </h4>
            </div>
            <button
              onClick={onNextClick}
              style={{ border: 0 , color:"#E67E22"}}
              className="text-gray-500 ml-4 "
            >
              <ArrowForwardIosIcon />
            </button>
            {CurrentTrainingBlock?.name &&
            
            <button
              style={{ border: 0 }}
              className="text-gray-500 ml-4 "
              onClick={() => {
                setManageUserSidebar(true);
                if(isMobileView){
                setIsOpenSideBar(false)
                }
              }}
            >
              <PeopleAltIcon 
              style={{ border: 0 , color:"#E67E22"}}
              />
            </button>
          }

          </div>
          <div
            style={{ color: "white", backgroundColor: "#E67E22" }}
            className="p-2"
          >
            <LocalOfferIcon />
          </div>
          {isMobileView && 
          <div className="cursor-pointer fixed top-2 right-[5px]"  style={{zIndex:"2",top:"50%", border:"1px solid #E67E22",padding:"5px",borderRadius: "10px" }}>
          <FaChevronRight
          onClick={() => {
            if(isMobileView){
              setSummaryModalVisible(!summaryModalVisible);
            }
            
          }}
          color="#E67E22"
          // className="cursor-pointer absolute top-2 right-[0px]"
          style={{ fontSize: "22px"}}
        /></div>
          }
          <div className="p-2">
            {layout?.map((row, index) => {
              const currentPath = `${row?.id?.split("-")[1]}`;
              console.log("currentPath",currentPath);
              console.log("parentdata",row);
              return (
                <React.Fragment key={row.id}>
                  <DropZone
                    data={{
                      path: currentPath,
                      childrenCount: layout.length,
                    }}
                    onDrop={handleDrop}
                    path={currentPath}
                  />
                  {renderRow(row, currentPath,setCopyWorkoutData,CopyWorkoutData)}
                </React.Fragment>
              );
            })}
            <DropZone
              data={{
                path: `${layout.length}`,
                childrenCount: layout.length,
              }}
              onDrop={handleDrop}
              isLast
              id="example"
            />
          </div>
        </div>
      </div>
      {summaryModalVisible &&
          <TrainingSummary
            data={layout}
            // rowData={rowData}
          //  handleClick={handleClick}
            summaryModalVisible={summaryModalVisible}
            setSummaryModalVisible={setSummaryModalVisible}
            // handleCopyWeeklyWorkout={handleCopyWeeklyWorkout}
            // PasteWeeklyWorkouts={PasteWeeklyWorkouts}
            // openCopyModal={openCopyModal}
            // setCopyOpen = {setCopyOpen}
            // open ={open}
            
            
          />
        }
    </div>
  );
};
export default Container;
