import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { FormLabel, MenuItem, OutlinedInput, TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { craetSlotBooking, getAllCoachesList, getAllCoachesSheduelListCoach, updatSlotBooking, updatecoachbooking } from "../../API/api-endpoint";
import Header from "../Header";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Button, Modal } from "antd";
import { showError, showSuccess } from "../Messages";
import { handleInputTimeChange } from "../../utils/Resubale";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";



const CoachAdminBooking = () => {
    const navigate = useNavigate();

    const [getList, setGetList] = useState()
    const [selectChallenge, setSelectChallenge] = useState()
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [formValue, setFormValue] = useState({slotDate:"",startTime:"",endTime:""})
    console.log("formValue", formValue);
    const [getAllShedueldList, setGetAllShedueldList] = useState()
    const [modalVisible, setModalVisible] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);

    const [selectedDayEvents, setSelectedDayEvents] = useState([]);
    const [selectedDay, setSelectedDay] = useState([]);


    const sheduelData = async () => {
        setGetAllShedueldList([])
        const response = await getAllCoachesSheduelListCoach()
        setGetAllShedueldList(response)
        console.log("GetAllShedueldList",response);
    }
    useEffect(() => {
        sheduelData()
    }, [])

    // useEffect(() => {
    //     console.log("eventInfo",eventInfo);
    // }, [eventInfo])
    const handleFetchProfile=(id)=>{
        setIsOpenModal(true)
        let data = getAllShedueldList?.filter((ele)=>ele.id==id)
        console.log("data",data);
        setFormValue({ "slotDate": data[0]?.date,
        "startTime": data[0]?.start_time,
        "endTime": data[0]?.end_time,
        "slotid":data[0]?.id,
        })
    }
    const eventContent = (eventInfo) => {
        console.log("eventInfo",eventInfo);
        if (isMobileView) {
            const eventsOnSameDay = getAllShedueldList.filter((event) =>
                moment(event.date).isSame(eventInfo.event.start, 'day')
              );
          
            // Get the count of events on the same day
            const eventCount = eventsOnSameDay.length;
          
            // Render orange dots
            return (
              
        <div style={{width: '10px', height: '10px', backgroundColor: 'orange', borderRadius: '50%'}}></div>
      
      
              
            );
          }
        return (

            
            <div style={{ color: "grey",padding:"10px",border:"1px solid lightGrey",borderRadius:"6px"}} onClick={() => handleFetchProfile(eventInfo?.event?.id)}>
                <div>
                   <span style={{fontWeight:"bold",borderLeft:"4px solid #E67E22",paddingLeft:"10px", margin:"5px"}}>Start Time: </span> {eventInfo.event.extendedProps.start_time}
                </div>
                <hr />
                <div>
                    <span style={{fontWeight:"bold",borderLeft:"4px solid #E67E22",paddingLeft:"10px", margin:"5px"}}>End Time: </span> {eventInfo.event.extendedProps.end_time}
                </div>
            </div>)
    }
    const SaveSlot = async (id) => {
       if (formValue?.slotDate &&formValue?.startTime&&formValue?.endTime) {
        let response =""
        if (formValue?.slotid) {
        response  = await updatSlotBooking(formValue)
        }else{
            response  = await craetSlotBooking(formValue)
        }
        if (response?.status) {
            showSuccess(response?.message)
            setIsOpenModal(false)
            sheduelData()
            setFormValue({slotDate:"",startTime:"",endTime:""})
        } else {
            showError(response?.message)
        }
       }else{
        showError("Please fill all details")
       }
    }

    const handleDayClick = (date) => {
        
      
        if (isMobileView) {
            console.log("isMobileView is true");
      
            if (!Array.isArray(getAllShedueldList)) {
                console.error("getAllShedueldList is not an array:", getAllShedueldList);
                return;
            }
            const formattedDate = moment(date).format('YYYY-MM-DD');
            console.log("Formatted date:", formattedDate, getAllShedueldList);
      
            const eventsOnDay = getAllShedueldList.filter((event) => {
                console.log("dates", event.date, formattedDate);
                return moment(event.date).isSame(formattedDate, 'day');
            });
      
            console.log("Events on selected day:", eventsOnDay);
            setSelectedDay(formattedDate)
            setSelectedDayEvents(eventsOnDay);
            setModalVisible(true);
            console.log("Modal visibility set to true");
        }
      };
    return (
        <div className="zones-form-container absolute top-20 w-[100vw]">
            <Header />
            <div
                className="zones-form-main"
                style={{ fontSize: "2rem", textAlign: "center" }}
            >
                Coach Booking
            </div>

            <Grid className="coachBook" xs={12} md={12}>
                <div className="table-section">
                    <div className="zone-table-title">

                    </div>
                    <div className="zone-table">
                        <TableContainer component={Paper}>
                            <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8} md={10} sm={10}>
                                        <div style={{ padding: "20px 0px 0px 10px" }}>
                                            <Button type="primary" onClick={() => setIsOpenModal(true)}><b>At Time Slot </b></Button>
                                        </div>
                                    </Grid>
                                    <Grid className="pt20" item xs={4} md={2} sm={2} style={isMobileView ? {} : { padding: "10px 0px 0px 10px"}}>
                                        <div style={{ padding: "20px 10px 0px 10px",float:"right" }}>
                                            <Button type="primary" onClick={() => navigate(`/all-coach-booking`)}><b>Get all Booking </b></Button>
                                        </div>
                                    </Grid>

                                </Grid>
                                &nbsp;
                            </div>
                        </TableContainer>
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                interactionPlugin,
                                listPlugin,
                            ]}
                            className="custom-calendar"
                            eventContent={eventContent}
                            headerToolbar={{
                                left: 'prev,title,next',
                                center: '',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}
                            initialView="dayGridMonth"
                            // weekNumbers="true"
                            editable={true}
                            // drop={handleDrop}
                            selectable={true}
                            firstDay={1}
                            selectMirror={true}
                            dayMaxEvents={false}
                            weekends={true}
                            dateClick={(info) => handleDayClick(info.date)}
                            // initialEvents={events}
                            // droppable={true}
                            events={getAllShedueldList}
                        />
                    </div>
                </div>
            </Grid>

            <Modal
                title="Booking Coach"
                centered
                open={isOpenModal}
                onCancel={() => {setIsOpenModal(false)
                    setFormValue({slotDate:"",startTime:"",endTime:""})
                }}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setIsOpenModal(false)
                        setFormValue({slotDate:"",startTime:"",endTime:""})

                    }}>
                        Close
                    </Button>,
                    <Button key="book" type="primary" onClick={() => SaveSlot(isOpenModal)}>
                        Save
                    </Button>,
                ]}
            >
                <div  className="dateWrap" style={{ borderBottom: "1px solid gray", paddingBottom: "24px" }}>
                    <FormLabel style={{ textAlign: "center", fontWeight: 600 }} >Date:</FormLabel>
                    <br />
                    <DatePicker
  selected={formValue?.slotDate ? new Date(formValue?.slotDate) : null}
  onChange={(date) => {
    setFormValue({ ...formValue, slotDate: moment(date).format("YYYY-MM-DD") })
  }}
  dateFormat="dd-MM-yyyy"
  placeholderText="DD-MM-YYYY"
  minDate={new Date()}
  customInput={
    <TextField
      fullWidth
      placeholder="Added Date and Time"
      size="small"
      type="text"
      name="added_datetime"
    />
  }
/>
&nbsp;
                    <div>
                        <FormLabel style={{ textAlign: "center", fontWeight: 600 }} >Start Time:</FormLabel>
                        <br />
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            value={formValue?.startTime}
                            onChange={(e) => handleInputTimeChange(e, "startTime", setFormValue)}
                            placeholder="hh:mm:ss"
                        />
                    </div> &nbsp;
                    <div>
                        <FormLabel style={{ textAlign: "center", fontWeight: 600 }} >End Time:</FormLabel>
                        <br />
                        <TextField
                            fullWidth
                            size="small"
                            type="text"
                            id="timeInput"
                            value={formValue?.endTime}
                            onChange={(e) => handleInputTimeChange(e, "endTime", setFormValue)}
                            placeholder="hh:mm:ss"
                        />
                    </div>
                </div>
            </Modal>

            <Modal
  open={modalVisible}
  onCancel={() => setModalVisible(false)}
  footer={null}
  title={`Events on ${moment(selectedDay).format('DD MMM YYYY')}`}
>
  {selectedDayEvents.length > 0 ? (
    selectedDayEvents.map((event) => (
      <div
        key={event.id} // Don't forget to add a unique key for each mapped element
        style={{ color: 'grey', padding: '10px', border: '1px solid lightGrey', borderRadius: '6px' }}
        onClick={() => handleFetchProfile(event?.id)}
      >
        <div>
          <span style={{ fontWeight: 'bold', borderLeft: '4px solid #E67E22', paddingLeft: '10px', margin: '5px' }}>
            Start Time:
          </span>{' '}
          {event?.start_time}
        </div>
        <hr />
        <div>
          <span style={{ fontWeight: 'bold', borderLeft: '4px solid #E67E22', paddingLeft: '10px', margin: '5px' }}>
            End Time:
          </span>{' '}
          {event?.end_time}
        </div>
      </div>
    ))
  ) : (
    <div style={{ textAlign: 'center' }}>No Events on this day Yet!</div>
  )}
</Modal>

        </div>
    );
};

export default CoachAdminBooking
