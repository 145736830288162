import React, { useEffect, useState } from "react";
import ZoneTable from "../CustomForms/zone-forms/ZoneTable";

import "../CustomForms/zone-forms/zonesforms.css";
import { Box, Button, FormControl, MenuItem, OutlinedInput, Select, TableBody, TableRow } from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PoolIcon from "@mui/icons-material/Pool";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import Grid from "@mui/material/Grid";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useSearchParams } from 'react-router-dom';
import { handleTimeChangeZones } from "../../utils/Resubale";
import Header from "../../components/Header";
import {
  fetchactivitylevel,
  getAllUserZones,
  getDistanceforactivity,
  getHeartZoneData,
  getSaveCoreZones,
  getSaveZones,
} from "../../API/api-endpoint";
import { showError } from "../../components/Messages";
import moment from "moment";
import CoreZones from "../CustomForms/zone-forms/CoreZones";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e40af",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AtheleZone = ({ isZoneOpen }) => {
  const [searchParams] = useSearchParams();
  console.log("isZoneOpen", isZoneOpen);
  // Get the userId from the search parameters

  const [getAllRunning, setGetAllRunning] = useState();
  const [getAllCycling, setGetAllCycling] = useState();
  const [getAllSpeedData, setGetAllSpeedData] = useState();
  const [cyclingForm, setCyclingForm] = useState({ power: 0, heartrate: 0 });
  const [runningForm, setRunningForm] = useState({ power: 0, heartrate: 0 });
  const [swimmingForm, setSwimmingForm] = useState({ power: 0, heartrate: 0 });
  console.log("cyclingForm", cyclingForm);
  const [heartZoneData, setheartZoneData] = useState();
  const [formValue, setFormValue] = useState({
    activity_id: 4, arrayofvalues: [{ subworkout_id: "", uomName: "", metric: "", value: "" }, { subworkout_id: "", uomName: "", metric: "", value: "" },
    { subworkout_id: "", uomName: "", metric: "", value: "" },
    { subworkout_id: "", uomName: "", metric: "", value: "" }]
  });
  const [cyclingDistnce, setCyclingDistance] = useState();
  const [runningDistnce, setRunningDistance] = useState();
  const [swimmingDistnce, setSwimmingDistance] = useState();
  const [coreData, setCoreData] = useState();


  console.log("cyclingDistnce", cyclingDistnce);
  useEffect(() => {
    getAllZones();
  }, []);

  // to fetch first time all zones 
  const getAllZones = async () => {
    const runningData = await getAllUserZones(1, isZoneOpen?.assignCoachId);
    const ruuningDistance = await getDistanceforactivity(1);
    setRunningDistance(ruuningDistance?.distancesforactivity)

    setGetAllRunning(runningData);
    const cyclingData = await getAllUserZones(2, isZoneOpen?.assignCoachId);
    const cyclingDistance = await getDistanceforactivity(2);
    setCyclingDistance(cyclingDistance?.distancesforactivity)

    setGetAllCycling(cyclingData);
    const speedData = await getAllUserZones(3, isZoneOpen?.assignCoachId);
    setGetAllSpeedData(speedData);
    const swimmingDistance = await getDistanceforactivity(3);
    setSwimmingDistance(swimmingDistance.distancesforactivity)

  };

  // onachnge to fetch pace and fitnes 

  const HandleChangeTiralTime = async (e, setData, data, id) => {
    const newTimeValue = e;

    // Update the local state
    setData((prevState) => ({ ...prevState, timeValue: newTimeValue }));
    if (data?.timeTrail) {
      let value = {
        activity_id: id,
        time: newTimeValue,
        distance: data?.timeTrail,
        unit: 'km'
      }
      const response = await fetchactivitylevel(value)
      console.log("response", response);
      if (response?.activitylevel?.level) {
        setData((prevState) => ({
          ...prevState,
          level: response?.activitylevel?.level,
          pace: response?.pace,
          speed: response?.speed,
          speedUnit: response?.speedunit,
        }));
      } else {
        setData((prevState) => ({
          ...prevState,
          level: { level: "" },
          pace: "",
        }));
      }
    } else {
      showError("Please select distance")
    }


    // Rest of your code...
  };

  // calculate zones 
  const handleSubmit = async (id, setData, data, setAllData, setDistance, isSwim) => {
    if (data.timeValue  && data.timeValue!='00:00:00' ) {
      console.log("assignCoachId", data);
      const response = await getSaveZones(id, data, isZoneOpen?.assignCoachId);
      if (response.status) {
        setData({
          ...data,
          timeValue: "",
          level: { level: "" },
          pace: "",
          heartrate: 0,
          power: 0,
          value: "",
        });
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
        getRunningZones(id, setData, setAllData, setDistance);
      } else {
        Swal.fire({
          title: "Error!!",
          text: response,
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Info",
        text: "Please fill correct all details",
        icon: "info",
      });
    }


  };

  // convert time with two digits 
  function timeStringToNumber(inputTime) {
    const timeComponents = inputTime.split(":");

    // Extract hours, minutes, and seconds
    const hours = parseInt(timeComponents[0]);
    const minutes = parseInt(timeComponents[1]);
    const seconds = Math.round(parseFloat(timeComponents[2])); // Rounding seconds

    // Format each component to have two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Construct the final formatted time
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
  }
  // fetch zone after submit calculation 
  const getRunningZones = async (id, setData, setAllData, setDistance) => {
    setDistance([])

    const response = await getAllUserZones(id, isZoneOpen?.assignCoachId);
    const runningValue = await getDistanceforactivity(id);
    setTimeout(() => {

      setDistance(runningValue?.distancesforactivity)
    }, 30);
    console.log("runningValue", runningValue);
    // setData({
    //   timeTrail: runningValue?.distanceforactivity,
    // });
    setAllData(response);
  };


  const fetchHeartZone = async () => {
    const response = await getHeartZoneData()
    setheartZoneData(response)
  }
  useEffect(() => {

    fetchHeartZone()
  }, [])
  const handleInputChange = (e, field, index) => {
    const newArrayofvalues = [...formValue.arrayofvalues];
    newArrayofvalues[index][field] = e;

    setFormValue({
      ...formValue,
      arrayofvalues: newArrayofvalues
    });
  };
  const handleInputPlanksChange = (time, field, index) => {
    if (time) {
      const newArrayofvalues = [...formValue.arrayofvalues];
      newArrayofvalues[index][field] = time;

      setFormValue({
        ...formValue,
        arrayofvalues: newArrayofvalues
      });
    }

  };

  const updateFormValue = (index, subworkout_id, metric, uomName, value) => {
    const newArrayofvalues = [...formValue.arrayofvalues];
    newArrayofvalues[index] = {
      subworkout_id,
      metric,
      uomName,
      value
    };

    setFormValue({
      ...formValue,
      arrayofvalues: newArrayofvalues
    });
  };
  const CalculateCoreZones = async () => {

    const isValid = formValue.arrayofvalues.every(item => {
      return item.value !== undefined && item.value !== null && item.value !== '';
    });
    console.log("formcorevalid", isValid);

    if (!isValid) {
      Swal.fire({
        title: "Info",
        text: "Please Fill All Fields",
        icon: "info",
      });
      return; // Skip the API call
    }
    else {

    const response = await getSaveCoreZones(formValue, isZoneOpen?.assignCoachId)
    
    console.log("response", response);
    if (response.status) {
      Swal.fire({
        title: "Success",
        text: response?.message,
        icon: "success",
      });
      setCoreData(response)
    } else {
      Swal.fire({
        title: "Error",
        text: response?.message,
        icon: "error",
      });
    }
  }
  }
  return (
    <div className="zones-form-container" style={{ height: "125vh", overflow: "scroll" }}>
      <div
        className="zones-form-main"
        style={{ fontSize: "2rem", textAlign: "center" }}
      >
        Zones
      </div>

      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid xs={12} md={5} sm={5.5} >
          <div className="table-section">
            <div className="zone-table-title">
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  color: "#E67E22",
                  padding: "20px",
                  fontSize: "20px",
                }}
              >
                <DirectionsBikeIcon />
                <p style={{ margin: "0", paddingLeft: "20px" }}>Cycling</p>
              </Box>
            </div>
            <div className="zone-table">
              <ZoneTable
                title={"Heart Rate"}
                rows={
                  <TableBody>
                    {getAllCycling?.zones?.length > 0 ? (
                      <>
                        {getAllCycling?.zones?.map((row) => (
                          <StyledTableRow key={row?.number}>
                            {/*
                            
                            <StyledTableCell>
                                <input type="radio" />
                              </StyledTableCell>
                            */}
                            <StyledTableCell align="left">
                              {row?.zone}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.zone_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.heartratestart} - {row?.heartrateend}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <div className="p-4">No data found</div>
                    )}
                  </TableBody>
                }
              />
            </div>

          </div>
        </Grid>
        <Grid  className="marginZero" xs={12} md={5} sm={5.5} style={{ marginLeft: "20px" }}>
          <div className="table-section  mt0 mb0" style={{ marginTop: "70px" }}>
            <div className="zone-table">
              <ZoneTable
                title={"Power"}
                rows={
                  <TableBody>
                    {getAllCycling?.zones?.length > 0 ? (
                      <>
                        {getAllCycling?.zones?.map((row) => (
                          <StyledTableRow key={row?.number}>
                            {/*
                        
                        <StyledTableCell>
                            <input type="radio" />
                          </StyledTableCell>
                        */}
                            <StyledTableCell align="left">
                              {row?.zone}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.zone_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.powerstart}-{" "}
                              {row?.powerend}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <div className="p-4">No data found</div>
                    )}
                  </TableBody>
                }
              />
            </div>
          </div>
        </Grid>


        <Grid  className="marginZero" xs={12} md={5} sm={5.5}>
          <div className="table-section  mt30">
            <div className="zone-table">
              <ZoneTable
                title={"Speed"}
                rows={
                  <TableBody>
                    {getAllCycling?.zones?.length > 0 ? (
                      <>
                        {getAllCycling?.zones?.map((row) => (
                          <StyledTableRow key={row?.number}>
                            {/*
                      
                      <StyledTableCell>
                          <input type="radio" />
                        </StyledTableCell>
                      */}
                            <StyledTableCell align="left">
                              {row?.zone}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.zone_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.speed_start} - {row?.speed_end}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <div className="p-4">No data found</div>
                    )}
                  </TableBody>
                }
              />
            </div>
          </div>
        </Grid>
        {getAllCycling?.changelogs1?.length > 0 &&
          <Grid  className="marginZero" xs={12} md={5} sm={5.5} style={{ marginLeft: "20px" }}>
            <div className="table-section m0">
              <div className="zone-table">
                <ZoneTable
                  title={"Zones Logs"}
                  isShowLogHedaers={getAllCycling?.changelogs1?.length > 0}
                  isSpeed={getAllCycling?.changelogs1?.length > 0}

                  rows={
                    <TableBody>
                      {getAllCycling?.changelogs1?.length > 0 ? (
                        <>
                          {getAllCycling?.changelogs1?.slice(0, 5)?.map((row) => (
                            <StyledTableRow key={row?.number}>
                              <StyledTableCell align="left">
                                {row?.activity?.activity_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.distance}Km
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.time}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {moment(row?.createdAt).format("MMMM Do YYYY, h:mm a")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.heartrate}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.power}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.fitnesslevel?.level}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.speed}km/hr
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <div className="p-4">No data found</div>
                      )}
                    </TableBody>
                  }
                />
              </div>
            </div>
          </Grid>
        }

      </Grid>
      <div className="zone-form-section-group">
        <div className="zone-discription-group">
          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Time trial of <br />
              <FormControl style={{ minWidth: "92px" }} >
                <Select
                  size="small"
                  placeholder="Distance"
                  name="sub_activity"
                  value={cyclingForm.timeTrail ? cyclingForm.timeTrail : cyclingDistnce && cyclingDistnce[0]}
                  onChange={(e) => setCyclingForm({
                    ...cyclingForm,
                    timeTrail: e.target.value,
                  })}

                  id="form-layouts-separator-select"
                  labelId="form-layouts-separator-select-label"
                  input={<OutlinedInput id="select-multiple-language" />}
                >
                  {cyclingDistnce?.map((value, index) => {
                    return (
                      <MenuItem value={value}>
                        {value} KM
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="text"
                id="timeInput"
                value={cyclingForm?.timeValue}
                onChange={(e) => {
                  let checkValue = handleTimeChangeZones(e)
                  console.log("checkValue", checkValue);
                  setCyclingForm({ ...cyclingForm, timeValue: checkValue })
                  HandleChangeTiralTime(checkValue, setCyclingForm, cyclingForm, 2)
                }}
                placeholder="hh:mm:ss"
              />

            </div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Heart Rate (in BPM):{" "}
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="number"
                value={cyclingForm?.heartrate}
                onChange={(e) =>
                  setCyclingForm({
                    ...cyclingForm,
                    heartrate: e.target.value,
                  })
                }
                placeholder="Enter Heart rate"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Power (in watts):{" "}
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="number"
                value={cyclingForm?.power}
                onChange={(e) =>
                  setCyclingForm({
                    ...cyclingForm,
                    power: e.target.value,
                  })
                }
                placeholder="Enter only number"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Speed:
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="text"
                value={`${cyclingForm?.speed} ${cyclingForm?.speedUnit}`}
                disabled
                placeholder="Enter here"
              />
            </div>
          </div>

          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Pace:
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="text"
                value={cyclingForm?.pace}
                disabled
                placeholder="Enter here"
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div style={{ color: "gray", marginRight: "20px" }}>
              Fitness Level:
            </div>{" "}
            <div className="zone-form-group">
              <input
                type="text"
                disabled
                value={cyclingForm?.level?.level}
                placeholder="Enter here"
              />
            </div>
          </div>
        </div>
        <div className="zone-form-group-btton">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleSubmit(
                2,
                setCyclingForm,
                cyclingForm,
                setGetAllCycling,
                setCyclingDistance
              )
            }
          >
            Calculate Zones
          </Button>
        </div>
      </div>
      <Grid container>
        {/* <div style={{ display: "flex", gap: "20px" }}> */}
          <Grid xs={12} md={12} sm={12}>
            <div className="table-section m0">
              <div className="zone-table-title">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    color: "#E67E22",
                    padding: "20px",
                    fontSize: "20px",
                  }}
                >
                  <DirectionsRunIcon />
                  <p style={{ margin: "0", paddingLeft: "20px" }}>Running</p>
                </Box>
              </div>
              <div className="zone-table">
                <ZoneTable
                  title={"Heart Rate"}
                  rows={
                    <TableBody>
                      {getAllRunning?.zones?.length > 0 ? (
                        <>
                          {getAllRunning?.zones?.map((row) => (
                            <StyledTableRow key={row?.number}>
                              {/*
                        
                        <StyledTableCell>
                            <input type="radio" />
                          </StyledTableCell>
                        */}
                              <StyledTableCell align="left">
                                {row?.zone}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.zone_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.heartratestart} - {row?.heartrateend}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <div className="p-4">No data found</div>
                      )}
                    </TableBody>
                  }
                />
              </div>
              </div>
          </Grid>
          <Grid className="marginZero" xs={12} md={3.8} sm={5.5} style={{ marginLeft: "20px" }}>
            <div className="table-section mt30 m0" style={{ marginTop: "0px" }}>
              <div className="zone-table">
                <ZoneTable
                  title={"Pace"}
                  rows={
                    <TableBody>
                      {getAllRunning?.zones?.length > 0 ? (
                        <>
                          {getAllRunning?.zones?.map((row) => (
                            <StyledTableRow key={row?.number}>
                              {/*
                            
                            <StyledTableCell>
                                <input type="radio" />
                              </StyledTableCell>
                            */}
                              <StyledTableCell align="left">
                                {row?.zone}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.zone_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {timeStringToNumber(row?.pace_start_range)}-{" "}
                                {timeStringToNumber(row?.pace_end_range)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <div className="p-4">No data found</div>
                      )}
                    </TableBody>
                  }
                />
              </div>
            </div>
          </Grid>
            <Grid className="marginZero" xs={12} md={3.8} sm={5.5} style={{ marginLeft: "20px" }} >
          <div className="table-section mt30 m0" style={{ marginTop: "0px" }}>
            <div className="zone-table">
              <ZoneTable
                title={"Zones Logs"}
                isShowLogHedaers={getAllRunning?.changelogs1?.length>0}

                rows={
                  <TableBody>
                    {getAllRunning?.changelogs1?.length > 0 ? (
                      <>
                        {getAllRunning?.changelogs1?.slice(0,5)?.map((row) => (
                          <StyledTableRow key={row?.number}>
                            <StyledTableCell align="left">
                              {row?.activity?.activity_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.distance}Km
                            </StyledTableCell>
                            <StyledTableCell align="left">
                            {row?.time}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                          {moment(row?.createdAt).format("MMMM Do YYYY, h:mm a")}
                        </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.heartrate}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.power}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.fitnesslevel?.level}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                          {row?.pace}
                        </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <div className="p-4">No data found</div>
                    )}
                  </TableBody>
                }
              />
            </div>
          </div>
        </Grid>

        <Grid xs={12} md={5} sm={5.5} >
          <div className="zone-form-section">
                <div className="zone-discription-group">
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Time trial of
                      <br />
                      <FormControl style={{ minWidth: "92px" }}>
                        <Select
                          placeholder="Distance"
                          name="sub_activity"
                          size="small"
                          value={runningForm.timeTrail ? runningForm.timeTrail : runningDistnce && runningDistnce[0]}
                          onChange={(e) => setRunningForm({
                            ...runningForm,
                            timeTrail: e.target.value,
                          })}

                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={<OutlinedInput id="select-multiple-language" />}
                        >
                          {runningDistnce?.map((value, index) => {
                            return (
                              <MenuItem value={value}>
                                {value} KM
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        id="timeInput"
                        value={runningForm?.timeValue}
                        onChange={(e) => {
                          let checkValue = handleTimeChangeZones(e)
                          console.log("checkValue", checkValue);
                          setRunningForm({ ...runningForm, timeValue: checkValue })
                          HandleChangeTiralTime(checkValue, setRunningForm, runningForm, 1)
                        }}
                        placeholder="hh:mm:ss"
                      />

                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Heart Rate (in BPM):{" "}
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="number"
                        value={runningForm?.heartrate}
                        onChange={(e) =>
                          setRunningForm({
                            ...runningForm,
                            heartrate: e.target.value,
                          })
                        }
                        placeholder="Enter Heart rate"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Power (in watts):{" "}
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="number"
                        value={runningForm?.power}
                        onChange={(e) =>
                          setRunningForm({
                            ...runningForm,
                            power: e.target.value,
                          })
                        }
                        placeholder="Enter only number"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Pace:
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        value={runningForm?.pace}
                        disabled
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Fitness Level:
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        disabled

                        value={runningForm?.level?.level}
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                </div>
                <div className="zone-form-group-btton">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSubmit(
                        1,
                        setRunningForm,
                        runningForm,
                        setGetAllRunning,
                        setRunningDistance
                      )
                    }
                  >
                    Calculate Zones
                  </Button>
                </div>
              </div>
        </Grid>
        {/* </div> */}

      </Grid>
      <Grid container>
        {/* <div className="flexCol" style={{ display: "flex", gap: "20px" }}> */}
          <Grid xs={12} md={12} sm={5.5}>

            <div className="table-section m0">
              <div className="zone-table-title">
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                    color: "#E67E22",
                    padding: "20px",
                    fontSize: "20px",
                  }}
                >
                  <PoolIcon />
                  <p style={{ margin: "0", paddingLeft: "20px" }}>Swimming</p>
                </Box>
              </div>
              

              


            </div>
          </Grid>
          <Grid className="marginZero" xs={12} md={3.8} sm={5.5}>
              <div className="zone-table">
                <ZoneTable
                  title={"Heart Rate"}
                  rows={
                    <TableBody>
                      {getAllSpeedData?.zones?.length > 0 ? (
                        <>
                          {getAllSpeedData?.zones?.map((row) => (
                            <StyledTableRow key={row?.number}>
                              {/*
                            
                            <StyledTableCell>
                                <input type="radio" />
                              </StyledTableCell>
                            */}
                              <StyledTableCell align="left">
                                {row?.zone}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.zone_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.heartratestart} - {row?.heartrateend}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <div className="p-4">No data found</div>
                      )}
                    </TableBody>
                  }
                />
              </div>
              </Grid>
          <Grid className="marginZero" xs={12} md={3.8} sm={5.5} style={{ marginLeft: "20px" }}>
            <div className="table-section mt30" style={{ marginTop: "0" }}>
              <div className="zone-table">
                <ZoneTable
                  title={"Pace"}
                  rows={
                    <TableBody>
                      {getAllSpeedData?.zones?.length > 0 ? (
                        <>
                          {getAllSpeedData?.zones?.map((row) => (
                            <StyledTableRow key={row?.number}>
                              {/*
                            
                            <StyledTableCell>
                                <input type="radio" />
                              </StyledTableCell>
                            */}
                              <StyledTableCell align="left">
                                {row?.zone}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row?.zone_name}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {timeStringToNumber(row?.pace_start_range)}-{" "}
                                {timeStringToNumber(row?.pace_end_range)}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </>
                      ) : (
                        <div className="p-4">No data found</div>
                      )}
                    </TableBody>
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid className="marginZero" xs={12} md={3.8} sm={5.5} style={{ marginLeft: "20px" }}>
          <div className="table-section m0" style={{ marginTop: "0px" }}>
            <div className="zone-table">
              <ZoneTable
                title={"Zones Logs"}
                isShowLogHedaers={getAllSpeedData?.changelogs1?.length>0}
                rows={
                  <TableBody>
                    {getAllSpeedData?.changelogs1?.length > 0 ? (
                      <>
                        {getAllSpeedData?.changelogs1?.slice(0,5)?.map((row) => (
                          <StyledTableRow key={row?.number}>
                            <StyledTableCell align="left">
                              {row?.activity?.activity_name}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.distance}Km
                            </StyledTableCell>
                            <StyledTableCell align="left">
                            {row?.time}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                          {moment(row?.createdAt).format("MMMM Do YYYY, h:mm a")}
                        </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.heartrate}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.power}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row?.fitnesslevel?.level}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                          {row?.pace}
                        </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <div className="p-4">No data found</div>
                    )}
                  </TableBody>
                }
              />
            </div>
          </div>
        </Grid>
        {/* </div> */}
        <Grid xs={12} md={5} sm={5.5}>
        <div className="zone-form-section">
                <div className="zone-discription-group">
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Time trial of  <br />
                      <FormControl style={{ minWidth: "92px" }}>
                        <Select
                          size="small"
                          placeholder="Distance"
                          name="sub_activity"
                          value={swimmingForm.timeTrail ? swimmingForm.timeTrail : swimmingDistnce && swimmingDistnce[0]}
                          onChange={(e) => setSwimmingForm({
                            ...swimmingForm,
                            timeTrail: e.target.value,
                          })}

                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={<OutlinedInput id="select-multiple-language" />}
                        >
                          <MenuItem value="">
                            Select Distance
                          </MenuItem>
                          {swimmingDistnce?.map((value, index) => {
                            return (
                              <MenuItem value={value}>
                                {value} KM
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        id="timeInput"
                        value={swimmingForm?.timeValue}
                        onChange={(e) => {
                          let checkValue = handleTimeChangeZones(e)
                          setSwimmingForm({ ...swimmingForm, timeValue: checkValue })
                          HandleChangeTiralTime(checkValue, setSwimmingForm, swimmingForm, 3)
                        }}
                        placeholder="hh:mm:ss"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Heart Rate (in BPM):{" "}
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="number"
                        value={swimmingForm?.heartrate}
                        onChange={(e) =>
                          setSwimmingForm({
                            ...swimmingForm,
                            heartrate: e.target.value,
                          })
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 3);
                        }}
                        placeholder="Enter Heart rate"
                      />
                    </div>
                  </div>
                  

                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>Pace:</div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        value={swimmingForm?.pace}
                        disabled
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div style={{ color: "gray", marginRight: "20px" }}>
                      Fitness Level:
                    </div>{" "}
                    <div className="zone-form-group">
                      <input
                        type="text"
                        disabled

                        value={swimmingForm?.level?.level}
                        placeholder="Enter here"
                      />
                    </div>
                  </div>
                </div>
                <div className="zone-form-group-btton">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSubmit(3, setSwimmingForm, swimmingForm, setGetAllSpeedData)
                    }
                  >
                    Calculate Zones
                  </Button>
                </div>
              </div></Grid>
      </Grid>
      <div className="table-section">
        <div className="zone-table-title">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              color: "#E67E22",
              padding: "20px",
              fontSize: "20px",
            }}
          >
            <SportsGymnasticsIcon />
            <p style={{ margin: "0", paddingLeft: "20px" }}>
              Core & Strengthening
            </p>
          </Box>
        </div>
        {heartZoneData?.length>0 &&
        <div className="zone-form-section-group">
          <div className="zone-discription-group">
          {heartZoneData?.map((zones, index) => {
        return (
          <div className="flex justify-between" key={index}>
            <div style={{ color: "gray", marginRight: "20px" }}>
              {zones?.subworkout?.subworkout}
              <span className="text-[red]">*</span>
            </div>
            {zones?.subworkout?.subworkout == "Planks" ? (
              <div className="zone-form-group">
                <input
                  type="text"
                  id="timeInput"
                  value={formValue?.arrayofvalues[index]?.value || ""}
                  onChange={(e) => {
                    let checkValue = handleTimeChangeZones(e);
                    handleInputPlanksChange(checkValue, "value", index);
                    updateFormValue(index, zones?.subworkout_id, zones?.metric?.metric_name, zones?.uom?.uom_name, checkValue);
                  }}
                  placeholder="hh:mm:ss"
                />
              </div>
            ) : (
              <div className="zone-form-group">
                <input
                  type="number"
                  id="valueInput"
                  placeholder="Enter value"
                  value={formValue?.arrayofvalues[index]?.value || ""}
                  onChange={(e) => {
                    handleInputChange(e.target.value, "value", index);
                    updateFormValue(index, zones?.subworkout_id, zones?.metric?.metric_name, zones?.uom?.uom_name, e.target.value);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
          </div>
          <div className="zone-form-group-btton">
            <Button variant="contained" color="primary" onClick={() => CalculateCoreZones()}>
              Calculate Zones
            </Button>
          </div>
        </div>
        }


      </div>
      <Grid container>
      <Grid xs={12} md={5} sm={5.5}>
        <div className="table-section m0" >
          <div className="zone-table">
            <CoreZones
              title={"Core & Strengthening"}
              total={coreData?.totalPoints}

              rows={
                <TableBody>
                  {coreData?.zones?.length > 0 ? (
                    <>
                      {coreData?.zones?.map((row) => (
                        <StyledTableRow key={row?.number}>
                         
                          <StyledTableCell align="left">
                            {row?.subworkout}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.fitnesslevel}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.points}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <div className="p-4">No data found</div>
                  )}
                </TableBody>
              }
            />
          </div>
        </div>
      </Grid>
    </Grid>
    </div>
  );
};

export default AtheleZone;

