import { Chip, FormControl, FormLabel,FormHelperText, Grid, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { CreateSaveAutomation, getAllPrograms,getAllTrainingBlockData,updatePrograms } from '../../API/api-endpoint';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
const scoreData = [1, 2, 3, 4, 5]
const CreateAutomation = ({ fetchReport, setShowAssesmentModal, showAssesmentModal,editData ,setEditData}) => {
 
 console.log("editData",editData);
    const [programList, setProgramList] = useState([]);
    const [trainingBlockList, setTrainingBlockList] = useState([]);

    const formik = useFormik({
        initialValues: {
             training_block_id: "", 
             program_id:"",
             numdays:""

        }, validate: (values) => {
            const errors = {};
            if (!values.training_block_id) {
                errors.training_block_id = "Training block name is required";
            }
            if (!values.program_id) {
                errors.program_id = "Program name is required";
            }
            if (!values.numdays) {
                errors.numdays = "No of days is required";
            }
            
            return errors;
        },
        // validationSchema: {},
        onSubmit: (values, { resetForm }) => {
            handleSubmitAssesmentForm(values, resetForm)

        },
    });
   
    const getAllProgramsData = async () => {
        const response = await getAllPrograms()
        console.log("response", response);
        setProgramList(response?.rows)
    }
    const getTraininBlokData = async () => {
        const response = await getAllTrainingBlockData()
        let filterData = response?.filter((data)=>data.status==1)
        console.log("filterData", filterData);
        setTrainingBlockList(filterData)
        // setProgramList(response?.rows)
    }
    useEffect(() => {
        getAllProgramsData()
        getTraininBlokData()
    }, [])
  
    const handleSubmitAssesmentForm = async (data,resetForm) => {
        let response =""
        if (editData?.program_id) {
            let allDatadata ={training_block_id:data?.training_block_id,program_id:data?.program_id,id:data?.id,numdays:data?.numdays}

         response = await CreateSaveAutomation(allDatadata)
            
        }else{
         response = await CreateSaveAutomation(data)
        }
        console.log("response",response);
        if (response?.status) {
            Swal.fire({
                title: "Success",
                text: response.message,
                icon: "success",
            });
            setShowAssesmentModal(false)
            setEditData({})
            fetchReport()
            resetForm()
            formik?.setFieldValue("numdays",'')
            formik?.setValues({})
        } else {
            Swal.fire({
                title: "Error",
                text: response.message,
                icon: "error",
            });
        }
        console.log("response", response);
    }
    useEffect(()=>{
        if (editData?.program_id) {
            let data = {training_block_id:editData?.training_block_id,program_id:editData?.program_id,id:editData?.id,numdays:editData?.numdays}
            formik?.setValues(data)
        }else{
            setEditData({})
        }
    },[editData?.program_id])
    const options = trainingBlockList?.map((value) => ({
        label: value['training-block-name'],
        value: value['training-block-id'],
      })) || [];
      console.log(formik?.values,"formik");
      
    return (
        <Modal
            width={600}
            title={editData?.program_id ? "Edit Automation Training Block" : "Add Automation Training Block"}
            open={showAssesmentModal}
            onCancel={() => {setShowAssesmentModal(false)
                setEditData({})
            formik?.setFieldValue("numdays",'')

        formik.resetForm()
               }}
            footer={
                <div >
                    <Button key="submit" type="primary" onClick={() => formik.handleSubmit()}>Submit</Button>
                </div>
                //   loading={isLoading}
            }
        >

            <form >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={3}>
                        <FormLabel >Program Name<span className="text-[red]">*</span></FormLabel>
                    </Grid>
                    <Grid item xs={12} md={8} sm={8} >
                        <TextField
                            fullWidth
                            size="small"
                            select
                            disabled={formik?.values?.id}
                            name="program_id"
                            value={formik?.values?.program_id || "placeholder"}
                            onChange={formik.handleChange}
                            error={formik.touched.program_id && formik.errors.program_id}
                            helperText={
                                formik.touched.program_id && formik.errors.program_id
                            }

                            id="form-layouts-separator-select"
                            labelId="form-layouts-separator-select-label"
                            input={<OutlinedInput id="select-multiple-language" />}
                        >
                            <MenuItem value={"placeholder"} disabled>
                                Select Program
                            </MenuItem>
                            {programList?.map((value, index) => {
                                return (
                                    <MenuItem value={value?.program_id}>
                                    {value?.program_name}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} sm={3}>
                    <FormLabel >Training Block Name<span className="text-[red]">*</span></FormLabel>
                </Grid>
                
                <Grid item xs={12} md={8} sm={8} >
                <FormControl
                   fullWidth
                   error={formik.touched.training_block_id && Boolean(formik.errors.training_block_id)}
                 >
                <ReactSelect
                 styles={{
                   control: (provided, state) => ({
                       ...provided,
                       borderColor: formik.touched.training_block_id && formik.errors.training_block_id ? 'red' : provided.borderColor,
                       '&:hover': {
                         borderColor: formik.touched.training_block_id && formik.errors.training_block_id ? 'red' : provided['&:hover'].borderColor,
                       },
                       // boxShadow: formik.touched.training_block_id && formik.errors.training_block_id ? '0 0 0 1px red' : provided.boxShadow,
                     }),
                   menu: (provided, state) => ({
                     ...provided,
                     scrollbarColor: '#E67E22 white',
                     scrollbarWidth: 'thin',
                     '&::-webkit-scrollbar': {
                       width: '12px',
                     },
                     '&::-webkit-scrollbar-track': {
                       background: 'white',
                     },
                     '&::-webkit-scrollbar-thumb': {
                       backgroundColor: '#E67E22',
                       borderRadius: '20px',
                       border: '3px solid transparent',
                       backgroundClip: 'content-box',
                     },
                   }),
                 }}
                 name="training_block_id"
               //   isMulti
               // value={formik?.values?.training_block_id}
                 options={options}
                 classNamePrefix="custom-select"
                 className='automationSelect'
                 value={options.find(option => option?.value === formik?.values?.training_block_id) || []}
                 onChange={(selectedOption) => {
                   formik.setFieldValue('training_block_id', selectedOption ? selectedOption.value : '');
                 }}
           
                 placeholder="Select Training Block"
                 isClearable
               />
               {formik.touched.training_block_id && formik.errors.training_block_id ? (
                     <FormHelperText>{formik.errors.training_block_id}</FormHelperText>
                   ) : null}
                   </FormControl>
                    {/* <TextField
                        fullWidth
                        size="small"
                        select
                        name="training_block_id"
                        value={formik?.values?.training_block_id}
                        onChange={formik.handleChange}
                        error={formik.touched.training_block_id && formik.errors.training_block_id}
                        helperText={
                            formik.touched.training_block_id && formik.errors.training_block_id
                        }

                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                    >
                        <MenuItem value={""} disabled>
                            Select Training Block 
                        </MenuItem>
                        {trainingBlockList?.map((value, index) => {
                            return (
                                <MenuItem value={value["training-block-id"]}>
                                {value["training-block-name"]}
                                </MenuItem>
                            );
                        })}
                    </TextField> */}
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                <FormLabel >No Of Days<span className="text-[red]">*</span></FormLabel>
            </Grid>
            <Grid item xs={12} md={8} sm={8} >
            <TextField
            fullWidth
            placeholder="No Of Days"
            size="small"
            type="number"
            name="numdays"
            value={formik?.values?.numdays}
            onChange={formik.handleChange}
            error={
              formik.touched.numdays && formik.errors.numdays
            }
            helperText={
              formik.touched.numdays && formik.errors.numdays
            }
          />
            </Grid>
             
                   
                </Grid>
            </form>
        </Modal>
    )
}
export default CreateAutomation
