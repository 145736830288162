import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";
import { logDOM } from "@testing-library/react";

const BorderLinearProgress = styled(LinearProgress)(
  ({ theme, percentage }) => ({
    height: 4,
    borderRadius: 5,
    position: "relative", // Positioning for the percentage text
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: percentage < 50 ? "red" : "#e67e22", // Change color based on percentage
    },
    "& .percentageText": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: theme.palette.mode === "light" ? "#000" : "#fff",
    },
  })
);

const WorkoutProgress = ({ completed, total,type }) => {
  // Calculate the percentage completed
  const convertTimeToSeconds = (timeString) => {
    if (!timeString || !timeString.includes(':')) { // Check if timeString is valid
      return 0; // Return 0 or throw an error depending on your needs
  }
    const [hours, minutes, seconds] = timeString?.split(':')?.map(Number);
    return (hours * 3600) + (minutes * 60) + (seconds || 0); // Convert time to seconds
  };

  // Function to handle value conversion based on type
  const convertToSecondsOrValue = (value, type) => {
    if (type === 'Km' || type === 'M' ) {
      // For non-time metrics (Km, m, etc.), return the value directly (assuming it's numeric)
      return value;
    } else  {
      // For time-related metrics, convert value to seconds
      return convertTimeToSeconds(value);
    }
    return 0; // Default to 0 if none of the conditions match
  };

  // Convert total and completed based on the type
  const totalInSecondsOrValue = convertToSecondsOrValue(total, type);
  const completedInSecondsOrValue = convertToSecondsOrValue(completed, type);

  // Calculate the percentage completed
  const percentage = totalInSecondsOrValue === 0 ? 0 : (completedInSecondsOrValue / totalInSecondsOrValue) * 100;

  // Adjust the value prop for LinearProgress to ensure it shows 100% when completed is greater than total
  const progressValue = completedInSecondsOrValue > totalInSecondsOrValue ? 100 : isNaN(percentage) ? 0 : percentage;

  return (
    <Box sx={{ flexGrow: 1, marginTop:"-16px" }}>
      <br />
      <BorderLinearProgress
        variant="determinate"
        value={isNaN(progressValue)?0:progressValue}
        percentage={isNaN(progressValue)?0:progressValue}
      >
      <span className="percentageText">{Math.round(progressValue)}%</span>

        </BorderLinearProgress>
      
      <Typography variant="body2" color="textSecondary" align="end" sx={{fontSize:"10px"}}>
     <b>P</b> {total} | <b>A</b> {completed}{type}
      </Typography>
    </Box>
  );
};

export default WorkoutProgress;
