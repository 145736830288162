import {
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Input,
  Chip,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { getTagsFromDb } from "../../store/slices/MultiTrainingBlocksSlice";
import {  createTrainingBlockLibrary } from "../../API/api-endpoint";
import Swal from "sweetalert2";
import { showSuccess } from "../../components/Messages";

const initialValues = {
  trainingBlockLibraryName: "",
  tags: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddTrainingBlockLibrary = (props) => {
  const { setisLibraryUpdated, setTogglesidebar } = props;
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [open, setOpen] = React.useState(false);

  let tagList = useSelector((state) => state.MultiTrainingBlocksSlice.tags);
  let CurrentTrainingBlock = useSelector(
    (state) => state.MultiTrainingBlocksSlice.CurrentTrainingBlock
  );

  useEffect(() => {
    dispatch(getTagsFromDb());
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trimStart(); // Trim white space only at the beginning
  
    setValues({
      ...values,
      [name]: trimmedValue,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (values?.trainingBlockLibraryName && values?.tags?.length>0) {
      let result = await createTrainingBlockLibrary(values);
    if (result.message == "Training block library created successfully") {
      showSuccess(result.message)
      handleClose();
      setValues(initialValues);
      setisLibraryUpdated(true);
    }
    setTimeout(() => {
      setisLibraryUpdated(false);
    }, 300);
    }else{
      alert("Please fill all details")
      
    }
  };


  const handleChangeSidebar = () => {
    if (CurrentTrainingBlock.name === null) {
      Swal.fire({
        title: "Error",
        text: "Please First Select any Training Block",
        icon: "error",
      });
    } else {
      setTogglesidebar(true);
    }
  };
  return (
    <div>
      <div className="flex flex-col items-start mb-2">
        <Button
          size="small"
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginBottom: "10px", backgroundColor: "rgb(230, 126, 34)" }}
        >
          <AddIcon />
        </Button>
        <Button
          size="small"
          style={{ backgroundColor: "rgb(230, 126, 34)", color: "white" }}
          variant="contained"
          onClick={() => handleChangeSidebar()}
        >
          Open Workouts
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Create Training Blocks Library"}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginTop: "30px" }}
            className="activity-input width100"
            id="outlined-basic"
            label="Library Name"
            variant="outlined"
            name="trainingBlockLibraryName"
            value={values.trainingBlockLibraryName}
            onChange={handleInputChange}
          />
        
         <FormControl className="width100" style={{ width: 400, marginTop: "20px" }}>
            <InputLabel id="demo-mutiple-checkbox-label">Tags</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name="tags"
              value={values?.tags || []}
              onChange={(e)=>{
                setValues({
                  ...values,
                  tags: e.target.value,
                });
              }}
              input={<Input />}
              renderValue={(selected) => (
                <div>
                  {selected?.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      // className={classes.chip}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
              //style={{ minWidth: "400px" }}
            >
              {tagList?.map((tag) => (
                <MenuItem
                  key={tag["tag-cloud-name"]}
                  value={tag["tag-cloud-name"]}
                  // style={getStyles(name, personName, theme)}
                >
                  {tag["tag-cloud-name"]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button style={{ backgroundColor: "#E67E22", color: "white" }} onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: "#E67E22", color: "white" }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddTrainingBlockLibrary;
