import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Box, Grid } from '@mui/material'
import { URL, getUserOngoingChalenges, subsribeToChallenge } from '../../API/api-endpoint'
import { Button } from 'antd'
import { showError, showSuccess } from '../../components/Messages'
import { capitalizeFirstLetter } from '../../utils/Resubale'
import Description from '../Workouts/Description'

const OngoingUser = () => {
    const [onGoingChallenges, setOngoingChallenges] = useState()
    const [isOpenDescription, setOpenDescription] = useState(false);

    useEffect(() => {
        fetchAssignedChallenge()
    }, [])

    const fetchAssignedChallenge = async () => {
        const response = await getUserOngoingChalenges()
        setOngoingChallenges(response?.challenges)
    }
    const handleSubscribeToChannel = async (id) => {
        let data = {
            challengeId: id
        }
        const response = await subsribeToChallenge(data)
        if (response?.status) {
            showSuccess("You are Subscribed to this Challenge")
            fetchAssignedChallenge()
        } else {
            showError(response?.message)
        }
        console.log("response", response);
    }
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4 "></div>
            <div style={{ marginTop: "80px", padding: "20px", display: "flex", background: "#FFEADC", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "95%", minHeight: "90vh", padding: "20px" }}>
                    <Grid container>
                        <Grid container spacing={0}>
                            <Grid item xs={6} sm={6} sx={{ padding: "24px 0px 20px 20px" }}>
                                <div>
                                    <h1 style={{ fontSize: "24px" }}><strong>Challenges </strong></h1>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{ padding: "24px 20px 20px 20px",textAlign: "end"  }}>
                            <a href='/assignechallenges-user'>
                            <Button type="primary"
                                style={{ margin: "0% 0% 0% 2%" }}
                            >
                                Back
                            </Button> &nbsp;
                        </a>
                            </Grid>
                            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
                            {onGoingChallenges?.map((challnges) => {
                                return (
                                    <Grid item xs={12} sm={2.7}  sx={{ margin: "10px", padding: "10px 10px 10px 10px", minHeight: "350px", minWidth: "200px", border: "1px solid gray", borderRadius: "10px", background: "white" }}>
                                        <Box sx={{ padding: "10px", minHeight: "140px", width: "100%", borderRadius: "10px", background: "#FFEADC" }}>
                                            <Grid container>
                                            <Grid item xs={9} sm={6}>
                                              {`${new Date(challnges?.challengeStartDate).toLocaleDateString('en-GB')} ${new Date(challnges?.challengeEndDate).toLocaleDateString('en-GB')}`}
                                            </Grid>
                                                <Grid item xs={3} sm={6} sx={{ textAlign: "end" }}>
                                                    {challnges?.activitylevel?.level}
                                                </Grid>
                                                <Grid item xs={12} sm={12} style={{ fontSize: "18px" }}>
                                                    {capitalizeFirstLetter(challnges?.challengeName) }
                                                </Grid>
                                                <Grid item xs={12} sm={9} style={{ fontSize: "18px", color: "#E67E22" }}>
                                                    {challnges?.activity?.activity_name}
                                                </Grid>
                                                &nbsp;
                                                <Grid item xs={9} sm={8} sx={{ marginTop: "10px" }}>
                                                    {challnges?.activityTrack?.activity_track}
                                                </Grid>
                                                <Grid item xs={2} sm={4} sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
                                                    <img
                                                        src={`${URL}/static/public/assets/${challnges?.activity?.badge}`}
                                                        alt="..."
                                                        className="w-6 h-6  border-2 rounded-full"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box sx={{ padding: "2px", width: "100%" }}>
                                            <Grid container spacing={2} >

                                                <Grid className='mt20' item xs={6} sm={6}>
                                                    Training block
                                                </Grid>
                                                <Grid className='mt20' item xs={6} sm={6} sx={{ textAlign: "end" }}>
                                                    {challnges?.trainingBlock ? challnges?.trainingBlock : "NA"}
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    Challenge Duration
                                                </Grid>
                                                <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                                                    {challnges?.challengeDuration} {challnges?.durationunit?challnges?.durationunit:"Days"} 
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    Challenge target
                                                </Grid>
                                                <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                                                {challnges?.targetchallanges?.length>0 ?(<>
                                                    
                                                    {challnges?.targetchallanges?.map((target)=>{
                                                        return(
                                                            <div>
                                                            {target?.quota} Km
                                                            </div>
                                                        )
                                                    })}
                                                    </>):("NA")}
                                                
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    Challenge Points
                                                </Grid>
                                                <Grid item xs={6} sm={6} sx={{ textAlign: "end" }}>
                                                    {challnges?.challengePoints} Points
                                                </Grid>
                                                {/*
                                                <Grid item xs={12} sm={6}>
                                                Challenge Prerequisites
                                            </Grid>
                                            <Grid item xs={12} sm={6} sx={{ textAlign: "end" }}>
                                                {challnges?.challengePoints} Points
                                            </Grid>
                                            */}
                                                <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                                                    <Button className='mr10' type="primary" style={{color:"white"}} disabled={challnges?.is_user_enrolled} onClick={() => handleSubscribeToChannel(challnges?.id)}>
                                                   {challnges?.is_user_enrolled?"Enrolled":"Enroll"} 
                                                    </Button>&nbsp;
                                                    <Button variant="outlined" style={{ borderColor: "#E67E22", color: "#E67E22", fontWeight: 700 }} onClick={() => {

setOpenDescription({isOpen:true,description:capitalizeFirstLetter(challnges?.challengeDescription)})
}}>View Description</Button>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                    </Grid>
                                )
                            })}
                            </div>

                        </Grid>
                    </Grid>
                </div>
                {isOpenDescription?.isOpen &&
                <Description isModalOpen={isOpenDescription} handleCancel={setOpenDescription}/>}
        
            </div>
        </div>
    )
}

export default OngoingUser
