import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,

  Typography,
  TextField,
  Stack,
  Chip,
  FormControl,
  InputLabel,
  Input,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../pages/Workouts/componants/ManageWorkouts/AddWorkout.css";
import { MdOutlineAutoDelete } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import SellIcon from "@mui/icons-material/Sell";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IoEyeOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ViewTrainingBlock from "./ViewTrainingBlock";
import EditWorkout from "./EditWorkout";

import moment from "moment";

const ViewBlocks = ({
  selectedValue,
  setModalVisible,
  modalVisible,
  data,
  openBox,
  setOpenBox,
  isEditOpen,
  setIsEditOpen,
  selectedID,
  setSelectedID,
  sectedCopyData,
  setSelectCopyData,
  handleCopy,
  showDeleteConfirm,
  handlePastProgram,
  path
}) => {
  const [isLoading, setisLoading] = useState(false);
  console.log("dataonday", data);
  {
    data?.map((component, index) => {
      console.log("dataonday", component?.content);
    });
  }

  return isLoading ? (
    <div>Loading Data...</div>
  ) : (
    <Modal
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          title="Blocks"

        >
    
      {data.length > 0 ? 
      (data?.map((component, index) => {
        const currentPath = `${path}-${index}`;
        // console.log("currentPath",currentPath);
        return(
        <div
        key={index}
          style={{
            color: "#6B7A99",
              backgroundColor:"white",
              borderRadius:"5px",
              padding: "10px",
              border: ` 3px solid grey`,
              width: "100%",
            //   height:"150px",
              marginBottom:"20px",
            wordBreak: "break-word",
          }}
        >
          <div
            style={{
              cursor: "move",
              opacity: "1",
            }}
            className="component draggable"
          >
            {component?.content}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <IoEyeOutline
              className=""
              style={{ fontSize: "16px", color: "#E67E22" }}
              onClick={() => {
                setOpenBox(!openBox);
                setSelectedID(data[index].workout_id);
              }}
            />
            <FiEdit
              style={{ fontSize: "14px", color: "#E67E22" }}
              onClick={() => {
                setIsEditOpen(true);
                setSelectedID(data[index].workout_id);
                console.log("data.workout_id",data[index].workout_id,data);
              }}
            />
            <MdOutlineAutoDelete
              style={{ fontSize: "14px", color: "#E67E22" }}
                 onClick={() => showDeleteConfirm(data[index])}
            />
            <ContentCopyIcon
              style={{ fontSize: "14px", color: "#E67E22" }}
                 onClick={() => { handleCopy(data[index].id) }}
            />
            <ContentPasteIcon
              style={{ fontSize: "14px", color: "#E67E22" }}
              onClick={() => { handlePastProgram(path,data[index])
                console.log("currentPathpaste",path)}} 
   
            />
          </div>

          {openBox && (
            <ViewTrainingBlock
              selectedValue={selectedID}
              open={openBox}
              onClose={setOpenBox}
            />
          )}
          {isEditOpen && (
            <EditWorkout
              trainingBlock={data}
              selectedValue={selectedID}
              open={isEditOpen}
              onClose={setIsEditOpen}
            />
          )}
        </div>
)}))
:
(<div>No blocks on this day</div>)}
      
   </Modal>
  );
};

export default ViewBlocks;
