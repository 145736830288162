import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, FormLabel, Grid, TextField } from "@mui/material";
import "../components/AssignCoach/Assign.css";
import Header from "../components/Header";
import { getCoachRevenueReport } from "../API/api-endpoint";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { capitalizeFirstLetter } from "../utils/Resubale";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e40af",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const CoachRevenue = () => {
  const [formValue, setFormValue] = useState({});
  const [reportData, setReportData] = useState();

  const fetchReport = async () => {
    if (formValue?.start_date && formValue?.end_date ) {
    const response = await getCoachRevenueReport(formValue);
    console.log("response", response);
    setReportData(response);
    }
    else {
      Swal.fire({
        title: "Error",
        text: "Please Fill All Fields!",
        icon: "error",
      });
    }

  };
  // useEffect(()=>{
  //     fetchReport()
  // },[])
  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div style={{ marginTop: "100px", padding: "20px" }}>
        <div
          style={{
            fontSize: "18px",
            background: "#FFEADC",
            width: "100%",
            padding: "10px",
          }}
        >
          <h1 className="mb-2" style={{ fontWeight: "700", fontSize: "20px" }}>
            Coach Revenue Report
          </h1>
          <Grid className="dateWrap" container spacing={2}>
            <Grid item xs={12} sm={2}>
              <FormLabel>
                <b>Start Date</b>
              </FormLabel>

              <DatePicker
                selected={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    start_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                onKeyDown={(e) => e.preventDefault()}
                placeholderText="DD-MM-YYYY"
                maxDate={
                  formValue?.end_date
                    ? new Date(formValue?.end_date)
                    : new Date()
                }
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid className="dateWrap" item xs={12} sm={2}>
              <FormLabel>
                <b>End Date</b>
              </FormLabel>
              <DatePicker
                selected={
                  formValue?.end_date ? new Date(formValue?.end_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    end_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                onKeyDown={(e) => e.preventDefault()}
                maxDate={new Date()}
                minDate={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid  className="mt10" item xs={12} sm={2} sx={{ marginTop: "26px" }}>
              <Button
                className="report-btn"
                variant="contained"
                onClick={fetchReport}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, padding: "10px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sr ID</StyledTableCell>
                <StyledTableCell align="left">Coach Name</StyledTableCell>
                <StyledTableCell align="left">Coach Commission</StyledTableCell>
                <StyledTableCell align="left">
                  Number Of Active Trainees{" "}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.length > 0 ? (
                <>
                  {reportData?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {capitalizeFirstLetter(row?.coachname)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.coachcommission}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.number_of_athletes}
                      </StyledTableCell>
                      {/*
            
              <StyledTableCell align="left">
                  {row?.coaches?.length > 0 &&

                    <Button variant="contained"
                      onClick={() => {
                        navigate("/coach-page");
                        let data = {
                          athlete_id: row?.subscription?.user_id,
                          program_id: row?.subscription?.program?.activity_id,
                          oldcoach_id: row?.coaches[0]?.id
                        }
                        localStorage.setItem("athleteDetails", JSON.stringify(data))

                        // ?userId=${row?.subscription?.user_id}
                      }}
                    >Change Coach </Button>
                  }
                  &nbsp;
                  <Button variant="contained" onClick={() => {
                    navigate("/coach-page");
                    let data = {
                      atheleteid: row?.subscription?.user_id,
                      activityid: row?.subscription?.program?.activity_id
                    }
                    localStorage.setItem("athleteDetails", JSON.stringify(data))

                    // ?userId=${row?.subscription?.user_id}
                  }} >Assign Coach</Button>

                </StyledTableCell>
            */}
                    </StyledTableRow>
                  ))}
                </>
              ) : (
                <div className="p-4">No data found</div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CoachRevenue;
