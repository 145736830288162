import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
    Button,
    CircularProgress,
    Pagination,
    TextField,
} from "@mui/material";
import Header from "../components/Header";
import {
    getAllWithoutPaymentAthelete,
    notifyCoach,
} from "../API/api-endpoint";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { showSuccess } from "../components/Messages";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const TodayExpiryList = () => {
    const { confirm } = Modal;
    let PageSize = 15;




    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState();

    const [currentPage, setCurrentPage] = useState(1);

    const getCoachList = async () => {
        const response = await getAllWithoutPaymentAthelete();
        setIsLoading(false);
        let dataResponse = response?.filter((ele)=>ele?.start_date == new Date())
        setReportData(dataResponse);
       
    };
    useEffect(() => {

        getCoachList();
    }, []);

    const handleTerminate = async (data) => {
        let userData = {
            "users": [
                {
                    "email": data?.user?.email,
                    "firstname": data?.user?.firstname,
                    "lastname": data?.user?.lastname
                }
            ]
        }
        const response = await notifyCoach(userData);
        if (response?.status) {
            showSuccess(response?.message);
            getCoachList()
        }
        console.log("rseadrsad", response);
    };


    const showConfirmTerminate = (id) => {
        confirm({
            title: "Do you Want to notify this athlete by email?",
            icon: <ExclamationCircleFilled />,
            onOk() {
                handleTerminate(id);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            // Implement your search logic here
            // For example, if you want to search by a specific property like 'name':
            const firstnameMatches = row?.user?.firstname?.toLowerCase().includes(searchTerm?.toLowerCase());
        const emailMatches = row?.user?.email?.toLowerCase().includes(searchTerm?.toLowerCase());
        return firstnameMatches || emailMatches;
        });
    }, [reportData, searchTerm]);
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            // Adjusting index on the first page and adding count from the second page onward
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, filteredList]);
    console.log("checkLastPage", checkLastPage);
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };


 
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                
                    <div
                        style={{
                            fontSize: "18px",
                            background: "#FFEADC",
                            width: "100%",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                        className="flexCol"
                    >
                        <div style={{ padding: "10px", marginLeft: "10px",fontWeight:"700" }}>Today  Payment Failed Athletes</div>

                        <div style={{ padding: "10px", margin: "0" }}>
                                      <TextField
                                type="text"
                                size="small"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="floatLeft"
                                placeholder="Search By First Name.."
                            />
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 700, padding: "10px" }}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Sr ID</StyledTableCell>
                                <StyledTableCell align="left">First Name</StyledTableCell>
                                <StyledTableCell align="left">Last Name</StyledTableCell>
                                <StyledTableCell align="left">Email</StyledTableCell>
                                <StyledTableCell align="left">Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? (
                                <CircularProgress className="m-6" />
                            ) : (
                                <>
                                    {checkLastPage?.length > 0 ? (
                                        <>
                                            {checkLastPage?.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">
                                                    {(currentPage - 1) * PageSize + index + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {row?.user?.firstname ? row?.user?.firstname : "NA"}
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left">
                                                        {row?.user?.lastname
                                                            ? row?.user?.lastname
                                                            : 'NA'}
                                                    </StyledTableCell>

                                                    <StyledTableCell align="left">
                                                        {row?.user?.email ? row?.user?.email : "NA"}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                marginBottom: "10px",
                                                                backgroundColor: "#E67E22",
                                                            }}
                                                            sx={
                                                                row?.terminate
                                                                    ? {
                                                                        background: "red",
                                                                        "&:hover": {
                                                                            backgroundColor: "red", // Change to your desired hover color
                                                                        },
                                                                    }
                                                                    : ""
                                                            }
                                                            disabled={row?.terminate}
                                                            onClick={() => {
                                                                showConfirmTerminate(row);
                                                            }}
                                                        >
                                                            Notify
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </>
                                    ) : (
                                        <div className="p-4">No data found</div>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>

        </div>
    );
};
export default TodayExpiryList
