import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  TextField,
} from "@mui/material";
import Header from "../components/Header";
import {
  coachPutOnHold,
  updateCoach,
  getAllCoachesList,
  terminateCoach,
  getAllPrograms,
} from "../API/api-endpoint";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { showError, showSuccess } from "../components/Messages";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1e40af",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const CoachList = () => {
  const { confirm } = Modal;
  let PageSize = 15;

  const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is Required"),
    lastname: Yup.string().required("Last Name is Required"),
    coachcommission: Yup.string().required("Required"),
  });

  // Inside your component
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      coachcommission: "",
      coachtype: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => { // Make this function async
      console.log("modal value", values);
      let data = { ...values, user_id: isOpenModal?.id }
      const response = await updateCoach(data); // Now you can use await here
      if (response?.status) {
        getCoachList()
        setIsOpenModal({ isOpen: false, id: "" });
        showSuccess(response?.message)
      } else {
        showError("Please add date");
      }
    },
  });

  const [selectedPrograms, setselectedPrograms] = useState();
  const [isOpenModal, setIsOpenModal] = useState({ isOpen: false, id: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [reportData, setReportData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [programList, setProgramList] = useState()

  const getCoachList = async () => {
    const response = await getAllCoachesList();
    setIsLoading(false);
    setReportData(response);
  };
  useEffect(() => {

    getCoachList();
  }, []);


  const handleputonHold = async (id) => {
    let data = { user_id: id, holding_value: true }
    const response = await coachPutOnHold(data);
    if (response?.status) {
      showSuccess();
      getCoachList()
    }
  };

  const handleputunHold = async (id) => {
    let data = { user_id: id, holding_value: false }
    const response = await coachPutOnHold(data);
    if (response?.status) {
      showSuccess();
      getCoachList()
    }
  };

  const handleTerminate = async (id) => {
    let data = { user_id: id }
    const response = await terminateCoach(data);
    if (response?.status) {
      showSuccess();
      getCoachList()
    }
  };

  const showConfirm = (id) => {
    confirm({
      title: "Do you Want to put this Coach on hold?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        handleputonHold(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showConfirmPutOnunhold = (id) => {
    confirm({
      title: "Do you Want to activate this Coach?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        handleputunHold(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const showConfirmTerminate = (id) => {
    confirm({
      title: "Do you Want to Terminate this Coach?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        handleTerminate(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const filteredList = useMemo(() => {
    return reportData?.filter((row) => {
      // Implement your search logic here
      // For example, if you want to search by a specific property like 'name':
      const firstnameMatches = row?.firstname?.toLowerCase().includes(searchTerm?.toLowerCase());
        const emailMatches = row?.email?.toLowerCase().includes(searchTerm?.toLowerCase());
        return firstnameMatches || emailMatches;
    });
  }, [reportData, searchTerm]);
  const checkLastPage = useMemo(() => {
    let frstPgae = (currentPage - 1) * PageSize;
    let lastPage = frstPgae + PageSize;
    return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
      ...row,
      // Adjusting index on the first page and adding count from the second page onward
      srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
    }));
  }, [currentPage, filteredList]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset the current page when the search term changes
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchReport = async () => {
    const response = await getAllPrograms()
    setProgramList(response?.rows)
  }
  useEffect(() => {
    fetchReport()
  }, [])
  const handleUserChange = async (event, newValue, name) => {
    setselectedPrograms([])
    formik.setFieldValue(name, newValue)
    setselectedPrograms(event)

  };

  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div style={{ marginTop: "100px", padding: "20px" }}>
        
          <div
            style={{
              fontSize: "18px",
              background: "#FFEADC",
              width: "100%",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between"
            }}
            className="flexCol"
          >
            <div style={{ padding: "10px", marginLeft: "10px",fontWeight:"700" }}>Manage Coach</div>
            <div style={{ padding: "10px", margin: "0" }}>
              <TextField
                type="text"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search By First Name.."
              />
            </div>
          </div>
          <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700, padding: "10px" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Sr ID</StyledTableCell>
                <StyledTableCell align="left">First Name</StyledTableCell>
                <StyledTableCell align="left">Last Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">Coach Commission(%)</StyledTableCell>
                <StyledTableCell align="left">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <CircularProgress className="m-6" />
              ) : (
                <>
                  {checkLastPage?.length > 0 ? (
                    <>
                      {checkLastPage?.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row?.firstname ? row?.firstname : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row?.lastname
                              ? row.lastname
                              : row?.firstname
                                ? row.firstname
                                : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row?.email ? row?.email : "NA"}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row?.coachcommission ? row?.coachcommission : "0"}%
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Button
                              variant="contained"
                              style={{
                                marginBottom: "10px",
                                backgroundColor: "#E67E22",
                              }}
                              onClick={() => {
                                setIsOpenModal({
                                  isOpen: true,
                                  id: row?.id,
                                });
                                formik.setValues({
                                  firstname: row?.firstname,
                                  lastname: row?.lastname,
                                  coachcommission: row?.coachcommission,

                                });
                                formik.setFieldValue('coachtype', row?.coachtype ? row?.coachtype : "")
                                let proFileter = programList
                                .filter(program => row.program_id.includes(program.program_id))
                                .map(program => ({ label: program.program_name, value: program.program_id }))
                                setselectedPrograms(proFileter);

                                console.log("formik", formik?.values, selectedPrograms);
                              }}
                            >
                              Edit{" "}
                            </Button>
                            &nbsp;
                            <Button
                              variant="contained"
                              style={{
                                marginBottom: "10px",
                                backgroundColor: "#E67E22",
                              }}
                              sx={
                                row?.onHold
                                  ? {
                                    background: "red",
                                    "&:hover": {
                                      backgroundColor: "red", // Change to your desired hover color
                                    },
                                  }
                                  : ""
                              }
                              onClick={() => {
                                if (row?.onHold) {
                                  showConfirmPutOnunhold(row?.id);
                                } else {
                                  showConfirm(row?.id);
                                }
                              }}
                            >
                              {row?.onHold ? "Activate" : "Put Onhold"}{" "}
                            </Button>
                            &nbsp;
                            <Button
                              variant="contained"
                              style={{
                                marginBottom: "10px",
                                backgroundColor: "#E67E22",
                              }}
                              sx={
                                row?.terminate
                                  ? {
                                    background: "red",
                                    "&:hover": {
                                      backgroundColor: "red", // Change to your desired hover color
                                    },
                                  }
                                  : ""
                              }
                              disabled={row?.terminate}
                              onClick={() => {
                                if (!row?.terminate) {
                                  showConfirmTerminate(row?.id);
                                }
                                //  else {
                                //   showConfirmTerminateOne(row?.id);
                                // }
                              }}
                            >
                              {row?.terminate ? "Terminated" : "Terminate"}{" "}
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </>
                  ) : (
                    <div className="p-4">No data found</div>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        &nbsp;
        <div className="flex justify-end">
          <Pagination
            count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
      <Modal
        title="Edit Coach Details"
        centered
        open={isOpenModal?.isOpen}
        onOk={formik?.handleSubmit}
        onCancel={() => setIsOpenModal({ isOpen: false, id: "" })}
      >
        <div className="dateWrap" style={{ paddingBottom: "24px" }}>
          <FormLabel style={{ textAlign: "center", fontWeight: 600 }}>
            First Name:
          </FormLabel>
          <br />
          <TextField
            fullWidth
            placeholder="First Name"
            size="small"
            type="text"
            name="firstname"
            value={formik?.values?.firstname}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.firstname && Boolean(formik?.errors?.firstname)
            }
            helperText={formik?.touched?.firstname && formik?.errors?.firstname}
          />
        </div>
        <div className="dateWrap" style={{ paddingBottom: "24px" }}>
          <FormLabel style={{ textAlign: "center", fontWeight: 600 }}>
            Last Name:
          </FormLabel>
          <br />
          <TextField
            fullWidth
            placeholder="Last Name"
            size="small"
            type="text"
            name="lastname"
            value={formik?.values?.lastname}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.lastname && Boolean(formik?.errors?.lastname)
            }
            helperText={formik?.touched?.lastname && formik?.errors?.lastname}
          />
        </div>
        <div
          className="dateWrap"
          style={{ paddingBottom: "24px" }}
        >
          <FormLabel style={{ textAlign: "center", fontWeight: 600 }}>
            Coach Commission(%)
          </FormLabel>
          <br />
          <TextField
            fullWidth
            placeholder="Coach Commission"
            size="small"
            type="number"
            name="coachcommission"
            value={formik?.values?.coachcommission}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.coachcommission &&
              Boolean(formik?.errors?.coachcommission)
            }
            helperText={
              formik?.touched?.coachcommission &&
              formik?.errors?.coachcommission
            }
          />
        </div>
        <div className="dateWrap"
          style={{ paddingBottom: "24px" }}>
          <FormLabel style={{ textAlign: "center", fontWeight: 600 }}>
            Coach Type:
          </FormLabel>
          <br />
          <TextField
            fullWidth
            size="small"
            select
            name="coachtype"
            value={formik?.values?.coachtype}
            onChange={formik.handleChange}
            error={formik.touched.coachtype && formik.errors.coachtype}
            helperText={
              formik.touched.coachtype && formik.errors.coachtype
            }

            id="form-layouts-separator-select"
            labelId="form-layouts-separator-select-label"
            input={<OutlinedInput id="select-multiple-language" />}
          >
            <MenuItem value={""} disabled>
              Select Coach Type
            </MenuItem>
            <MenuItem value={"parttime"}>
              Part Time
            </MenuItem>
            <MenuItem value={"fulltime"}>
              Full Time
            </MenuItem>

          </TextField>
        </div>
        <div className="dateWrap"
          style={{ borderBottom: "1px solid gray", paddingBottom: "24px" }}>
          <FormLabel style={{ textAlign: "center", fontWeight: 600 }}>
            Program Name:
          </FormLabel>
          <br />
          {programList?.length > 0 &&
            <Select
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  width: '100%',
                  scrollbarColor: '#E67E22 transparent',
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    width: '12px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#E67E22',
                    borderRadius: '20px',
                    border: '3px solid transparent',
                    backgroundClip: 'content-box',
                  },
                }),
                container: (provided, state) => ({
                  ...provided,
                  width: '100% !important', // Add width 100% !important
                }),
              }}
              isMulti
              value={selectedPrograms} 
              options={programList?.map(challenge => ({ label: challenge?.program_name, value: challenge?.program_id }))}
              onChange={(selectedOptions) => handleUserChange(selectedOptions, selectedOptions?.map(option => option?.value), 'program_id')}
            />
          }
        </div>
      </Modal>
    </div>
  );
};
export default CoachList;
