import React, { useState,useEffect } from "react";
import Header from "../../components/Header";
import { revenueReportByTimeRange } from "../../API/api-endpoint";
import {
  Button,
  FormLabel,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import PieChar from "../../components/Chart/PieChar";
import BarChart from "../../components/Chart/BarChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";

const RevenueReporptByTime = () => {
  const [showWidth, setShowWidth] = useState(window.innerWidth < 500 ? '100%' : '60%');
  const [chartData, setChartData] = useState({});
  const [pieChartData, setPieChartDataData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pieChartRevenuewithGstData, setPieChartRevenuewithGstData] = useState(
    {}
  );
  console.log("pieChartRevenuewithGstData",pieChartRevenuewithGstData);
  
  const [pieChartRevenuewithoutGstData, setPieChartRevenuewithoutGstData] =
    useState({});
  const [formValue, setFormValue] = useState({});
  const fetchReport = async () => {
    setIsLoading(true);
    if (formValue?.start_date && formValue?.end_date ) {
    const response = await revenueReportByTimeRange(formValue);
    setIsLoading(false);
    // generate bar and pie for results
    if (response?.results) {
      const barGraphData = generateBarGraphData(response?.results);
      setChartData({
        labels: barGraphData.labels,
        datasets: [
          {
            label: "Total Users per Program",
            data: barGraphData.data,
            backgroundColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderWidth: 1,
          },
        ],
      });
      setPieChartDataData({
        labels: barGraphData.labels,
        datasets: [
          {
            label: "Total Revenue per Program",
            data: barGraphData.data,
            backgroundColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderWidth: 1,
          },
        ],
      });
    }
    if (response?.revenue) {
      const revenueWithGSTData = generatePieGraphDatatotalwithgst(
        response?.revenue
      );
      const barGraphData = generatePieGraphDatatotalwithoutgst(
        response?.revenue
      );

      setPieChartRevenuewithGstData({
        labels: revenueWithGSTData.labels,
        datasets: [
          {
            label: "Revenue with GST",
            data: revenueWithGSTData.data,
            backgroundColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderWidth: 1,
          },
        ],
      });
      setPieChartRevenuewithoutGstData({
        labels: barGraphData.labels,
        datasets: [
          {
            label: "Revenue without GST",
            data: barGraphData.data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              // Add more colors as needed...
            ],
            borderColor: [
              "rgba(36, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(128, 0, 128, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(125, 159, 64, 0.2)",
              // Add more colors as needed...
            ],
            borderWidth: 1,
          },
        ],
      });
    }
}
else {
      setIsLoading(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill All Fields!",
        icon: "error",
      });
    }

  };
  function generateBarGraphData(data) {
    const labels = Object.keys(data);
    const usersCount = labels?.map((program) => data[program].length - 1);
    return {
      labels: labels,
      data: usersCount,
    };
  }
  function generatePieGraphDatatotalwithoutgst(data) {
    const labels = ["GST", "Without Gst"];
    const usersCount = [data.totalwithgst, data.totalwithoutgst];
    return {
      labels: labels,
      data: usersCount,
    };
  }

  // Function to generate pie graph data (total revenue per program)
  function generatePieGraphDatatotalwithgst(data) {
    const labels = Object.keys(data);
    const revenueData = labels.map((program) => {
      const users = data[program][data[program].length - 1];
      const totalRevenue =
        users?.totalrevenuewithgst + users?.totalrevenuewithoutgst;
      return totalRevenue;
    });

    return {
      labels: labels,
      data: revenueData,
    };
  }
  useEffect(() => {
        const handleResize = () => {
          setShowWidth(window.innerWidth < 500 ? '100%' : '50%');
        };
      
        window.addEventListener('resize', handleResize);
      
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div
        style={{
          fontSize: "18px",
          background: "#FFEADC",
          width: "100%",
          padding: "10px",
        }}
      >
        <div style={{ marginTop: "100px" }}>
          <h1 className="mb-2" style={{ fontWeight: "700", fontSize: "20px" }}>
            Revenue Report
          </h1>
          <Grid className="dateWrap" container spacing={2}>
            <Grid item xs={12} sm={2}>
              <FormLabel>
                <b>Start Date</b>
              </FormLabel>

              <DatePicker
                selected={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    start_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                onKeyDown={(e) => e.preventDefault()}
                maxDate={
                  formValue?.end_date
                    ? new Date(formValue?.end_date)
                    : new Date()
                }
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid className="dateWrap" item xs={12} sm={2}>
              <FormLabel>
                <b>End Date</b>
              </FormLabel>
              <DatePicker
                selected={
                  formValue?.end_date ? new Date(formValue?.end_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    end_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                maxDate={new Date()}
                onKeyDown={(e) => e.preventDefault()}
                minDate={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid className="mt10" item xs={12} sm={2} sx={{ marginTop: "26px" }}>
              <Button
                className="report-btn"
                variant="contained"
                onClick={fetchReport}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {chartData?.datasets?.length > 0 && (
            <Grid container spacing={2} className="p-6">
              <Grid item xs={12} sm={6}>
                <BarChart
                  chartData={chartData}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PieChar
                  chartData={pieChartData}
                  showWidth={"50%"}
                  displayLegend={false}
                />
              </Grid>
              
              <Grid item xs={12} sm={6} className="mt-8">
                <PieChar
                  chartData={pieChartRevenuewithoutGstData}
                  showWidth={"60%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <PieChar
                  chartData={pieChartRevenuewithGstData}
                  showWidth={"60%"}
                  displayLegend={false}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default RevenueReporptByTime;
