import React, {  useEffect, useMemo, useState } from "react";
import { Form, Modal, Table,  Button } from "antd";
import Header from "../components/Header";
import { URL, createChallengeActivity, deleteCraetedCahllenges, getAllActivities, getAllChallenges, getAllRenderChallengesData, getAllTrainingBlock, updateChallengeActivity, updateWorkouts } from "../API/api-endpoint";
import { useFormik } from "formik";
import { challengeScema, createchallenge } from "../components/formik/FormikValidationSceema";
import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, OutlinedInput,  TextField } from "@mui/material";
import Swal from "sweetalert2";
import AssignChllenge from "./Challenges/AssignChllenge";
import { useRef } from "react";
import CreateChallengeTrack from "./Challenges/CreateChallengeTrack";
import AssignedChallenge from "./Challenges/AssignedChallenge";
import ChallengeTrack from "./Challenges/ChallengeTrack";
import SlickCarousel from "./SlickCarousel";
import { showInfo } from "../components/Messages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactSelect from 'react-select';
import AssignChallengeGroup from "./Challenges/AssignChallengeGroup";
import RichTextEditor from "../../src/components/EditorFile";
import { ExclamationCircleFilled } from "@ant-design/icons";


const AdminCreateChallenge = () => {
  const { confirm } = Modal;

  const [allChanges, setAllChallege] = useState();
  const [allChalengesData, setAllchalanngesData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
  const [isAssignedGroupModal, setIsAssignedGroupModal] = useState(false);

  const [showCreateTrackModal, setShowCreateTrackModal] = useState(false);
  const [assignedChallengeModal, setShowAssignedChallengeModal] = useState(false);
  const [showTrackChellenges, setShowTrackChellenges] = useState(false);
  const [initial, setInitial] = useState(false);

  const [modalName, setModalName] = useState();

  const [fetchAllActivities, setFetchActivies] = useState();
  const [fetchChallengeTarget, setFechCallnegeTarget] = useState();


  const [form] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [allTraningBlocks, setAllTrainingBlock] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

 



  const showModal = () => {
    setEditData(null);
    setShowTrackChellenges(false)
    setShowAssignedChallengeModal(false);

    if (editData === null) {
      form.resetFields();
    }
    // setValues(initialChallengeValues);
    setIsModalOpen(true);
  };
  const showAssigned = () => {
    setShowTrackChellenges(false)

    setShowAssignedChallengeModal(true);
  };

  const showAssignUserModal = (name) => {
    setIsAssignUserModalOpen(true)
    setShowAssignedChallengeModal(false)
    setShowTrackChellenges(false)

    setModalName(name)
    // setShowAssignedChallengeModal(false);

  }
  const showAssignGroupModal = (name) => {
    setIsAssignUserModalOpen(false)
    setShowAssignedChallengeModal(false)
    setShowTrackChellenges(false)
    setIsAssignedGroupModal(true)

    setModalName(name)
    // setShowAssignedChallengeModal(false);

  }

  const fetChallenges = async () => {
    const response = await getAllChallenges()

    if (response?.length > 0) {

      setAllChallege(response)
    }
  }
  const filteredList = useMemo(() => {
    return allChanges?.filter((row) => {
      return (
        row?.challengeName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [allChanges, searchTerm]);
  const fetchAllchallengesData = async () => {
    const response = await getAllRenderChallengesData()
    setAllchalanngesData(response)
  }
  const columns = [
    {
      title: "Badge",
      dataIndex: "badge",
      key: "badge",
      render: (badge) => (
        <img
          src={badge ? `${URL}/static/public/userimages/${badge}` : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbQIfgvrUcDEY90ZrIVSMPYX4hvjFe963pHSxaVK3fuw&s"}
          alt="..."
          className="w-10 h-10 rounded-full border-2"
        // onClick={handleProfile}
        />

      ),

    },
    {
      title: "Challenge Name",
      dataIndex: "challengeName",
      key: "challengeName",

    },
    
   
    {
      title: "Duration",
      dataIndex: `challengeDuration`,
      key: "challengeDuration",
      render: (challengeDuration, record) => (
        <span>{`${challengeDuration} ${record.durationunit ? record.durationunit : "days"}`}</span>
      ),
    },
    {
      title: "Activity",
      dataIndex: "activity",
      key: "activity",
      render: (activity) => activity.activity_name,
    },
    {
      title: "Challenge Target",
      dataIndex: "challangeTarget",
      key: "challangeTarget",
      render: (challangeTarget) => (
        <span>
          {challangeTarget?.length > 0 ? (
            <span>
              {challangeTarget.map((target, index) => (
                <span key={index}>
                  {target.quota ? `${target.quota} KM` : 'NA'}
                  {index !== challangeTarget.length - 1 && ", "}
                </span>
              ))}
            </span>
          ) : "NA"}

        </span>
      ),

    },
    {
      title: "Challenges Point",
      dataIndex: "challengePoints",
      key: "challengePoints",
      width: 150,
      render: (challengePoints, record) => (
        <span >{`${challengePoints}`}</span>
      ),
     

    },
    
   
   
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, id) => (
        <span>
          <Button style={{ color: "#E67E22" }} type="link" onClick={() => handleEdit(id)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => showDeleteConfirm(id?.id)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditData({});
    formik.setValues(record)
    formik.setFieldValue("challengePrerequisites", []);
    setEditData(record);
    setInitial(true)
    setIsModalOpen(true);
  };
  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure want to delete this challenge?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
        // console.log('OK');
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleDelete = async (id) => {
    const response = await deleteCraetedCahllenges(id)
    if (response?.status === "success") {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
    }
    fetChallenges()
    fetchAllchallengesData()
  };
  const isFormValid = (values) => {
    // Define your required fields here
    const requiredFields = [
      'challengeName',
      'challengeActivity',
      'challengeActivityGroup',
      'challengeActivityTrack',
      'challengeDescription',
      'challengeDuration',
      'challengeStartDate',
      'challengeEndDate',
      'challengeLevel',
      'challengePoints',
      'durationunit',
      'challangeTarget'

      // Add other required fields here
    ];
    // Check if all required fields are present in form values
    // Check if all required fields are present in form values
  const areRequiredFieldsValid = requiredFields.every(
    (field) => values[field] !== undefined && values[field] !== ''
  );

  // Check for challangeTarget.quota and challangeTarget.unit
  const isChallangeTargetValid = Array.isArray(values.challangeTarget) && values.challangeTarget.every(target => {
    return target.quota !== undefined && target.quota !== '' && target.unit !== undefined && target.unit !== '';
  });

  // Return true if all required fields and challangeTarget fields are valid
  return areRequiredFieldsValid && isChallangeTargetValid;
  };
  const handleOnSubmitForm = async (resetForm) => {
    if (!isFormValid(formik.values)) {
      Swal.fire({
        title: "Error",
        text: "Please fill all details",
        icon: "error",
      });
    } else {
      // if (formik.values.challengePrerequisites.length>0) {
      const formData = new FormData();
      if (formik.values.badge) {
        formData.append('badge', formik.values.badge);
        console.log("formik.values.badge", formik.values.badge);

      }
      // Append data to FormData
      formik.values.challangeTarget.forEach(({ activity_id, quota,unit }, index) => {
        formData.append(`challangeTarget[${index}][activity_id]`, activity_id);
        formData.append(`challangeTarget[${index}][quota]`, quota);
        formData.append(`challangeTarget[${index}][unit]`, unit);
      });
      if (formik.values.challengePrerequisites?.length > 0) {
        formik.values.challengePrerequisites.forEach((id, index) => {
          formData.append(`challengePrerequisites[${index}]`, id);

        });
      } else {
        formData.append("challengePrerequisites", "null");

      }

      formData.append("challengeName", formik.values.challengeName);
      formData.append("challengeActivity", formik.values.challengeActivity);
      formData.append("challengeActivityGroup", formik.values.challengeActivityGroup);
      formData.append("challengeActivityTrack", formik.values.challengeActivityTrack);
      formData.append("durationunit", formik.values.durationunit);
      formData.append("challengeDescription", formik.values.challengeDescription);
      formData.append("challengeDuration", formik.values.challengeDuration);
      formData.append("challengeStartDate", formik.values.challengeStartDate);
      formData.append("challengeEndDate", formik.values.challengeEndDate);
      formData.append("challengeLevel", formik.values.challengeLevel);
      formData.append("challengePoints", formik.values.challengePoints);
      formData.append("trainingBlock", formik.values.trainingBlock);
      // formData.append("badge", formik.values.badge);


      if (formik.values.id) {
        formData.append("id", formik.values.id);
      }
      try {
        setIsLoading(true)
        if (formik.values.id) {
          const Result = await updateChallengeActivity(formData);
          if (Result.status) {
            setIsLoading(false)
            Swal.fire({
              title: "Success",
              text: Result.message,
              icon: "success",
            });
            setIsModalOpen(false)
            fetChallenges()
            formik.setValues({
              challangeTarget: [],
              challengeDescription: "",
              challengeName: "",
              challengeDuration: "",
              challengePoints: "",
              trainingBlock: "",
              challengeStartDate: "",
              challengeEndDate: "",
              challengePrerequisites: []
            })

            // if (resetForm) {
            formik.resetForm()
            // }
          }
          else {
            setIsLoading(false)
            Swal.fire({
              title: "Error",
              text: Result.message,
              icon: "error",
            });
            setIsModalOpen(false)

          }
        } else {

          const Result = await createChallengeActivity(formData);
          if (Result.status) {
            setIsLoading(false)
            Swal.fire({
              title: "Success",
              text: Result.message,
              icon: "success",
            });
            setIsModalOpen(false)
            fetChallenges()
            formik.resetForm()
            formik.setValues({
              challangeTarget: [],
              challengeDescription: "",
              challengeName: "",
              challengeDuration: "",
              challengePoints: "",
              trainingBlock: "",
              challengeStartDate: "",
              challengeEndDate: "",
              challengePrerequisites: []
            })
            // }
          }
          else {
            setIsLoading(false)
            Swal.fire({
              title: "Error",
              text: Result.message,
              icon: "error",
            });
          }
        }

      } catch (error) {
        setIsLoading(false)
      }
      // }else{
      //   Swal.fire({
      //     title: "Error",
      //     text: "Please select challenge pre-requisites",
      //     icon: "error",
      //   });
      // }
    }
  };

  const formik = useFormik({
    initialValues: createchallenge,
    validationSchema: challengeScema,
    onSubmit: (values, { resetForm }) => {
      handleOnSubmitForm(values, resetForm)

    },
  });
  const getAllActivitiesDetails = async (id) => {
    let result = await getAllActivities();
    setFetchActivies(result);
  }
  console.log("formik", formik?.values);
  const fetchTrainingBlock = async () => {
    const response = await getAllTrainingBlock()
    setAllTrainingBlock(response)
    console.log("resposne", response);
  }
  useEffect(() => {
    fetChallenges()
    getAllActivitiesDetails()
    fetchTrainingBlock()
    fetchAllchallengesData()
    if (!formik?.values?.id) {
      formik.setValues({
        challangeTarget: [],
        challengeDescription: "",
        challengeName: "",
        challengeDuration: "",
        challengePoints: "",
        trainingBlock: "",
        challengeStartDate: "",
        challengeEndDate: "",
        challengePrerequisites: []
      })

    }
    console.log("d456", fetchChallengeTarget);
  }, [formik?.values?.id])
  const handleCancel = () => {
    setEditData(null);
    form.resetFields();
    setIsModalOpen(false);
    formik.setValues({
      challangeTarget: [],
      challengeDescription: "",
      challengeName: "",
      challengeDuration: "",
      challengePoints: "",
      trainingBlock: "",
      challengeStartDate: "",
      challengeEndDate: "",
      challengePrerequisites: []
    })
  };
  const fileInputRef = useRef(null);


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const acceptedFileTypes = [".jpg", ".JPG", ".jpeg", ".JPEG", ".png", ".PNG", ".gif", ".GIF", ".gfif"];
    const currentFileType = "." + file.type.split("/")[1];
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      showInfo("File size exceeds the limit of 5MB.");
      event.target.value = null;
    } else if (!acceptedFileTypes.includes(currentFileType)) {
      showInfo("Accepted file types are .jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .gif, .GIF, .gfif");
      event.target.value = null;
    } else {
      formik.setFieldValue("badge", file);
    }
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // setCurrentPage(1); // Reset the current page when the search term changes
  };
  useEffect(() => {
    // Define the function that updates fetchChallengeTarget based on formik.values.challengeActivity
    const updateFetchChallengeTarget = (activityId) => {
      let updatedChallengeTarget = formik.values.challangeTarget.map((item) =>
        item.activity_id === activityId ? item : { activity_id: item.activity_id, quota: item.quota }
      );

      if (activityId === 6) {
        const running = fetchAllActivities?.find((activity) => activity?.id === 1);
        const cycling = fetchAllActivities?.find((activity) => activity?.id === 2);
        const swimming = fetchAllActivities?.find((activity) => activity?.id === 3);

        const newArray = [running, cycling, swimming].filter(Boolean);
        setFechCallnegeTarget(newArray);

        const triathlonActivities = [1, 2, 3];
        updatedChallengeTarget = [
          ...triathlonActivities
            .filter((id) => !updatedChallengeTarget.some((item) => item?.activity_id === id))
            .map((id) => ({ activity_id: id, quota: formik.values.challangeTarget.find(item => item.activity_id === id)?.quota || '' })),
        ];
      } else if (activityId === 5) {
        const running = fetchAllActivities?.find((activity) => activity?.id === 1);
        const cycling = fetchAllActivities?.find((activity) => activity?.id === 2);

        const newArray = [running, cycling].filter(Boolean);
        setFechCallnegeTarget(newArray);

        const triathlonActivities = [1, 2];
        updatedChallengeTarget = [
          ...triathlonActivities
            .filter((id) => !updatedChallengeTarget.some((item) => item?.activity_id === id))
            .map((id) => ({ activity_id: id, quota: formik.values.challangeTarget.find(item => item.activity_id === id)?.quota || '' })),
        ];
      } else {
        const running = fetchAllActivities?.find((activity) => activity?.id === activityId);
        const newArray = [running].filter(Boolean);
        setFechCallnegeTarget(newArray);
      }

      formik.setFieldValue('challangeTarget', updatedChallengeTarget);
    };

    // Call the function with the current formik.values.challengeActivity
    updateFetchChallengeTarget(formik.values?.challengeActivity);
  }, [formik.values?.challengeActivity]); // Dependency array

  useEffect(() => {

    // Calculate end date based on start date and duration
    if (formik?.values?.challengeStartDate &&formik?.values?.challengeDuration &&formik?.values?.durationunit) {
      
    const startDate = new Date(formik?.values?.challengeStartDate);
    const duration = parseInt(formik?.values?.challengeDuration);
    const unit = formik?.values?.durationunit;
    
    console.log("unit", unit);
    if (unit === "days") {
      startDate.setDate(startDate.getDate() + duration);
    } else if (unit === "weeks") {
      startDate.setDate(startDate.getDate() + duration * 7);
    } else if (unit === "months") {
      startDate.setMonth(startDate.getMonth() + duration);
    } else if (unit === "years") {
      startDate.setFullYear(startDate.getFullYear() + duration);
    }

    // Format the date as "YYYY-MM-DD" for input field
    const endDate = startDate.toISOString().split("T")[0];

    // Update the "Challenge End Date" field
    formik.setFieldValue("challengeEndDate", endDate);
  }
  }, [formik.values.durationunit, formik?.values?.challengeDuration, formik.values.challengeStartDate])
  
  const options = allTraningBlocks?.map((block) => ({
    label: block['training-block-name'],
    value: block['training-block-id'],
  })) || [];

  const handleUserChange = async (event, newValue, name) => {
    console.log("bdnbafa", newValue);
    formik.setFieldValue(name, newValue)
  };
  const selectedValues = options?.filter(option =>
    formik?.values?.challengePrerequisites?.includes(option?.value)
  );
  
  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div style={{ marginTop: "100px", padding: "20px" }}>

        <div className="add-challenges-modal">
          <Grid container spacing={2}>
            <Grid className="mt0" item xs={12} sm={10} sx={{ marginTop: "30px" }}>
              <Button  className="mr10" style={{marginTop:"10px"}} type="primary" onClick={showModal}>
                Create Challenges
              </Button> &nbsp;
              <Button  style={{marginTop:"10px"}} type="primary" onClick={() => showAssignUserModal("User")}>
                Assign challenge to users
              </Button>
              &nbsp;

               <Button className="mr10" style={{marginTop:"10px"}} type="primary" onClick={() => showAssignGroupModal("User")}>
                Assign challenge to group
              </Button>
              &nbsp;
              <Button type="primary" onClick={showAssigned}>
                Assigned Challenges
              </Button>
              &nbsp;
              <Button className="mr10" style={{marginTop:"10px"}} type="primary" onClick={() => {
                setShowCreateTrackModal(true)
                setShowAssignedChallengeModal(false)
                setShowTrackChellenges(false)
              }}>
                Create a challenge track
              </Button>
              &nbsp;
              <Button  style={{marginTop:"10px"}} type="primary" onClick={() => {
                setShowTrackChellenges(true)
                setShowAssignedChallengeModal(false)
              }
              }>
                Get all challenge tracks
              </Button>
            </Grid>
            {!showTrackChellenges && !assignedChallengeModal && (
            <Grid className="mt0" item xs={12} sm={2} sx={{ textAlign: "start", marginTop: "30px" }}>
              <TextField  style={{marginTop:"10px"}} type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Challenge Name.." />

            </Grid>
            )}
          </Grid>

          {/*
        
         &nbsp;
          <Button type="primary" onClick={() => showAssignUserModal("simulation")}>
          User simulation
        </Button>
       
            <Button type="primary" onClick={() => setShowCreateTrackModal(true)}>
          Create a challenge track
        </Button>
       
          &nbsp;
          <Button type="primary" onClick={showModal}>
            Get all challenge tracks
          </Button>
          &nbsp;
          <Button type="primary" onClick={showModal}>
            Assigned Challenges
          </Button>*/}
          <Modal
            width={1200}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={<div></div>}
          >
            <div className="headingCont paddingBot">
              <span className="heading">{editData ? "Edit " : "Create"}</span>{"  "}
              <span className="orange heading">Challenges</span>
              <p className="grey">
                Set up a new fitness challenge for Yoska Users
              </p>
            </div>
            {/* <h1>{editData ? editData.challengeId : values.challengeId}</h1> */}
            <div className="parentCont">
              <form className="form1" onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid className="marbot" container spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Name<span className="text-[red]">*</span></FormLabel>
                      <TextField
                        fullWidth
                        placeholder="Challenge Name"
                        style={{
                          width: "100%",
                        }}
                        size="small"
                        type="text"
                        name="challengeName"
                        value={formik?.values?.challengeName}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Badge:</FormLabel>
                      <input
                        fullWidth
                        style={{
                          width: "100%",
                          border: "1px solid #c2bbbb",
                          borderRadius: "5px",
                          minHeight: "40px",
                          padding: "5px",
                        }}
                        type="file"
                        accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.gif,.GIF,.gfif"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                      />
                      <p style={{ fontSize: "0.8rem", color: "gray", marginTop: "5px" }}>File size should be less than 5MB</p>
                    </Grid>
                  </Grid>

                  <Grid className="marbot" container spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Activity<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        style={{
                          width: "100%",
                        }}
                        size="small"
                        select
                        name="challengeActivity"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                 scrollbarColor:"#E67E22 white",
                                 scrollbarWidth:"thin"
                              },
                            },
                          },
                        }}
                        value={
                          formik?.values?.challengeActivity ||
                          (fetchAllActivities?.length > 0
                            ? "TextField Activity"
                            : "")
                        }
                        onChange={(event) => {
                          formik.setFieldValue(
                            "challengeActivity",
                            event.target.value
                          );

                          let updatedChallengeTarget = [
                            { activity_id: event.target.value, quota: "" },
                          ];
                          console.log(
                            "updatedChallengeTarget",
                            updatedChallengeTarget
                          );
                          if (event.target.value === 6) {
                            const running = fetchAllActivities.find(
                              (activity) => activity.id === 1
                            ); // Assuming ID 1 is for Running
                            const cycling = fetchAllActivities.find(
                              (activity) => activity.id === 2
                            );
                            const swimming = fetchAllActivities.find(
                              (activity) => activity.id === 3
                            ); // Assuming ID 2 is for Cycling
                            // Assuming ID 2 is for Cycling

                            const newArray = [running, cycling, swimming];
                            // Do something with newArray (e.g., set it to state or formik values)
                            setFechCallnegeTarget(newArray);
                            const triathlonActivities = [1, 2, 3]; // IDs of running, cycling, and swimming
                            updatedChallengeTarget = [
                              ...triathlonActivities
                                .filter(
                                  (id) =>
                                    !updatedChallengeTarget.some(
                                      (item) => item.activity_id === id
                                    )
                                )
                                .map((id) => ({ activity_id: id, quota: "" })),
                            ];
                            console.log(
                              "updatedChallengeTarget",
                              updatedChallengeTarget
                            );
                            formik.handleChange({
                              target: {
                                name: "challangeTarget",
                                value: updatedChallengeTarget,
                              },
                            });
                          } else if (event.target.value === 5) {
                            const running = fetchAllActivities.find(
                              (activity) => activity.id === 1
                            ); // Assuming ID 1 is for Running
                            const cycling = fetchAllActivities.find(
                              (activity) => activity.id === 2
                            );
                            // Assuming ID 2 is for Cycling

                            const newArray = [running, cycling];
                            // Do something with newArray (e.g., set it to state or formik values)
                            setFechCallnegeTarget(newArray);
                            const triathlonActivities = [1, 2]; // IDs of running, cycling, and swimming
                            updatedChallengeTarget = [
                              ...triathlonActivities
                                .filter(
                                  (id) =>
                                    !updatedChallengeTarget.some(
                                      (item) => item.activity_id === id
                                    )
                                )
                                .map((id) => ({ activity_id: id, quota: "" })),
                            ];

                            formik.handleChange({
                              target: {
                                name: "challangeTarget",
                                value: updatedChallengeTarget,
                              },
                            });
                          } else {
                            const running = fetchAllActivities.find(
                              (activity) => activity.id === event.target.value
                            ); // Assuming ID 1 is for Running
                            // Assuming ID 2 is for Cycling

                            const newArray = [running];
                            // Do something with newArray (e.g., set it to state or formik values)
                            setFechCallnegeTarget(newArray);

                            formik.handleChange({
                              target: {
                                name: "challangeTarget",
                                value: updatedChallengeTarget,
                              },
                            });
                          }
                          // let data1=
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select Activity
                        </MenuItem>
                        {fetchAllActivities?.map((value, index) => {
                          return (
                            <MenuItem value={value?.id}>
                              {value?.activity_name}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Activity Group<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        size="small"
                        style={{
                          width: "100%",
                        }}
                        select
                        name="challengeActivityGroup"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                 scrollbarColor:"#E67E22 white",
                                 scrollbarWidth:"thin"
                              },
                            },
                          },
                        }}
                        value={
                          formik?.values?.challengeActivityGroup ||
                          (fetchAllActivities?.length > 0
                            ? "TextField Activity"
                            : "")
                        }
                        onChange={(event) => {
                          formik.setFieldValue(
                            "challengeActivityGroup",
                            event.target.value
                          );
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select Activity
                        </MenuItem>
                        {allChalengesData?.activityGroups?.map(
                          (value, index) => {
                            return (
                              <MenuItem value={value?.id}>
                                {value?.activity_group}
                              </MenuItem>
                            );
                          }
                        )}
                      </TextField>
                    </Grid>
                  </Grid>

              

                  <Grid className="marbot" container spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Duration<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        placeholder="Challenge Duration"
                        size="small"
                        type="number"
                        name="challengeDuration"
                        value={formik?.values?.challengeDuration}
                       onChange={formik?.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Duration Unit<span className="text-[red]">*</span></FormLabel>
                      <TextField
                        fullWidth
                        size="small"
                        select
                        name="durationunit"
                        value={formik?.values?.durationunit}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "durationunit",
                            event.target.value
                          );
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select
                        </MenuItem>
                        <MenuItem value={"days"}>Day</MenuItem>
                        <MenuItem value={"weeks"}>Week</MenuItem>
                        <MenuItem value={"months"}>Month</MenuItem>
                        <MenuItem value={"years"}>Year</MenuItem>

                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container className="marbot dateWrap" spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Start Date<span className="text-[red]">*</span></FormLabel>

                      <DatePicker
  selected={formik.values.challengeStartDate ? new Date(formik.values.challengeStartDate) : null}
  onChange={(date) => {
    formik.setFieldValue(
      "challengeStartDate",
      moment(date).format("YYYY-MM-DD")
    );
  }}
  dateFormat="dd-MM-yyyy"
  placeholderText="DD-MM-YYYY"
  customInput={
    <TextField
      fullWidth
      placeholder={formik.values.challengeStartDate ? "" : "dd-mm-yyyy"}
      size="small"
      type="text"
      name="challengeStartDate"
      // filterDate={(date) => {
      //   return formik.values.challengeEndDate ? date <= new Date(formik.values.challengeEndDate) : true;
      // }}
      maxDate={formik.values.challengeEndDate ? new Date(formik.values.challengeEndDate) : null}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        max: formik.values.challengeEndDate, // Assuming challengeEndDate is a valid date string
      }}
    />
  }
/>

                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge End Date<span className="text-[red]">*</span></FormLabel>

                      <DatePicker
                      placeholderText="DD-MM-YYYY"
  selected={formik.values.challengeEndDate ? new Date(formik.values.challengeEndDate) : null}
  onChange={(date) => {
    formik.setFieldValue(
      "challengeEndDate",
      moment(date).format("YYYY-MM-DD")
    );

    // Calculate challenge duration based on start date and end date
    const startDate = new Date(formik?.values?.challengeStartDate);
    const endDate = date;
    const unit = formik?.values?.durationunit ? formik?.values?.durationunit : "days";

    let duration;

    if (unit === "days") {
      const diffTime = Math.abs(endDate - startDate);
      duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    } else if (unit === "weeks") {
      const diffTime = Math.abs(endDate - startDate);
      duration = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
    } else if (unit === "months") {
      duration =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());
    } else if (unit === "years") {
      duration = endDate.getFullYear() - startDate.getFullYear();
    }

    // Update the "Challenge Duration" field
    formik.setFieldValue("challengeDuration", duration);
  }}
  // filterDate={(date) => {
  //   return (formik.values.challengeStartDate ? new Date(formik.values.challengeStartDate) : new Date()) <= date;
  // }}
  minDate={formik.values.challengeStartDate ? new Date(formik.values.challengeStartDate) : null}
  dateFormat="dd-MM-yyyy"
  customInput={
    <TextField
      fullWidth
      placeholder={formik.values.challengeEndDate ? "" : "dd-mm-yyyy"}
      size="small"
      type="text"
      name="challengeEndDate"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: formik.values.challengeStartDate, // Assuming challengeEndDate is a valid date string
      }}
    />
  }
/>

                    </Grid>
                  </Grid>

                  <Grid container className="marbot" spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Training Block:</FormLabel>

                      <ReactSelect
      styles={{
        menu: (provided, state) => ({
          ...provided,
          scrollbarColor: '#E67E22 white',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'white',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E67E22',
            borderRadius: '20px',
            border: '3px solid transparent',
            backgroundClip: 'content-box',
          },
        }),
      }}
      classNamePrefix="custom-select"
      name="trainingBlock"
      options={options}
      value={options.find(option => option.value == formik.values.trainingBlock) || null}
      onChange={(selectedOption) => {
        formik.setFieldValue('trainingBlock', selectedOption ? selectedOption.value : '');
      }}
      placeholder="Select Activity"
      isClearable
    />

                      {/* <TextField
                        fullWidth
                        size="small"
                        select
                        name="trainingBlock"
                        value={
                          formik?.values?.trainingBlock ||
                          (allTraningBlocks?.length > 0
                            ? "TextField Activity"
                            : "")
                        }
                        onChange={(event) => {
                          formik.setFieldValue(
                            "trainingBlock",
                            event.target.value
                          );
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                // maxHeight: 200,
                                scrollbarColor:"#E67E22 white",
                                scrollbarWidth:"thin"
                              },
                            },
                          },
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select Activity
                        </MenuItem>
                        {allTraningBlocks?.map((value, index) => {
                          return (
                            <MenuItem value={value["training-block-id"]}>
                              {value["training-block-name"]}
                            </MenuItem>
                          );
                        })}
                      </TextField> */}
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Level<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        size="small"
                        select
                        name="challengeLevel"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                 scrollbarColor:"#E67E22 white",
                                 scrollbarWidth:"thin"
                              },
                            },
                          },
                        }}
                        value={
                          formik?.values?.challengeLevel ||
                          (fetchAllActivities?.length > 0
                            ? "TextField Activity"
                            : "")
                        }
                        onChange={(event) => {
                          formik.setFieldValue(
                            "challengeLevel",
                            event.target.value
                          );
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select Activity
                        </MenuItem>
                        {allChalengesData?.levels?.map((value, index) => {
                          return (
                            <MenuItem value={value?.id}>
                              {value?.level}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Grid container className="marbot" spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Pre-requisites:</FormLabel>

                      <FormControl fullWidth size="small">
                      <ReactSelect
                       styles={{
                         menu: (provided, state) => ({
                           ...provided,
                           scrollbarColor: '#E67E22 white',
                           scrollbarWidth: 'thin',
                           '&::-webkit-scrollbar': {
                             width: '12px',
                           },
                           '&::-webkit-scrollbar-track': {
                             background: 'white',
                           },
                           '&::-webkit-scrollbar-thumb': {
                             backgroundColor: '#E67E22',
                             borderRadius: '20px',
                             border: '3px solid transparent',
                             backgroundClip: 'content-box',
                           },
                         }),
                       }}
                       name="challengePrerequisites"
                       classNamePrefix="custom-select"
                       isMulti
                       options={allChalengesData?.allchallenges?.map(challenge => ({ label: challenge?.challengeName, value: challenge?.id }))}
                       value={allChalengesData?.allchallenges?.filter(option =>
                        formik?.values?.challengePrerequisites?.includes(option.id)
                      ).map(option => ({
                        label: option.challengeName,
                        value: option.id
                      }))}
                      onChange={(selectedOptions) => {
                        handleUserChange(
                          null,
                          selectedOptions ? selectedOptions.map(option => option.value) : [],
                          'challengePrerequisites'
                        );
                      }}
                       placeholder="Select Pre-requisites"
                       isClearable
                     />
                        {/* <Select
                          labelId="challengePrerequisites-label"
                          id="challengePrerequisites"
                          multiple
                          value={formik?.values?.challengePrerequisites || []}
                          onChange={(event) => {
                            formik.setFieldValue(
                              "challengePrerequisites",
                              event.target.value
                            );
                          }}
                          input={
                            <OutlinedInput id="select-multiple-challengePrerequisites" />
                          }
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </div>
                          )}
                        >
                          {allChalengesData?.allchallenges?.map((value) => (
                            <MenuItem key={value.id} value={value.id}>
                              {capitalizeFirstLetter(value.challengeName)}
                            </MenuItem>
                          ))}
                        </Select> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Points<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        placeholder="Challenge Points"
                        size="small"
                        type="number"
                        name="challengePoints"
                        value={formik?.values?.challengePoints}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Activity Track<span className="text-[red]">*</span></FormLabel>

                      <TextField
                        fullWidth
                        size="small"
                        select
                        name="challengeActivityTrack"
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                 scrollbarColor:"#E67E22 white",
                                 scrollbarWidth:"thin"
                              },
                            },
                          },
                        }}
                        value={
                          formik?.values?.challengeActivityTrack ||
                          (fetchAllActivities?.length > 0
                            ? "TextField Activity"
                            : "")
                        }
                        onChange={(event) => {
                          formik.setFieldValue(
                            "challengeActivityTrack",
                            event.target.value
                          );
                        }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                      >
                        <MenuItem value={""} disabled>
                          Select Activity
                        </MenuItem>
                        {allChalengesData?.activityTracks?.map(
                          (value, index) => {
                            return (
                              <MenuItem value={value?.id}>
                                {value?.activity_track}
                              </MenuItem>
                            );
                          }
                        )}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={11}>
                      <FormLabel>Challenge Description<span className="text-[red]">*</span></FormLabel>
                      <RichTextEditor
                  setInitial={setInitial}
                  initial={initial}
        initialContent={formik?.values?.challengeDescription}
        onContentChange={(newContent)=>formik?.setFieldValue("challengeDescription",newContent)}
        placeholder="Type your content here..."
      />
                     
                    </Grid>
                  </Grid>

                  <Grid container className="marbot" spacing={3}>
                    <Grid item xs={12} sm={5.5}>
                      <FormLabel>Challenge Target<span className="text-[red]">*</span></FormLabel>

                      {fetchChallengeTarget?.map((activity) => (
                        <React.Fragment key={activity.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik?.values?.challangeTarget.some(
                                  (item) => item.activity_id === activity.id
                                )}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  const existingItemIndex =
                                    formik?.values?.challangeTarget.findIndex(
                                      (item) => item.activity_id === activity.id
                                    );

                                  if (checked && existingItemIndex === -1) {
                                    let updatedChallengeTarget = [
                                      ...formik?.values?.challangeTarget,
                                      { activity_id: activity.id, quota: "" },
                                    ];

                                    // For Triathlon, add running, cycling, and swimming
                                    if (activity.id === 6) {
                                      const triathlonActivities = [1, 2, 3]; // IDs of running, cycling, and swimming
                                      updatedChallengeTarget = [
                                        ...updatedChallengeTarget,
                                        ...triathlonActivities
                                          .filter(
                                            (id) =>
                                              !updatedChallengeTarget.some(
                                                (item) =>
                                                  item.activity_id === id
                                              )
                                          )
                                          .map((id) => ({
                                            activity_id: id,
                                            quota: "",
                                          })),
                                      ];
                                    } else if (activity.id === 5) {
                                      const triathlonActivities = [1, 2]; // IDs of running, cycling, and swimming
                                      updatedChallengeTarget = [
                                        ...updatedChallengeTarget,
                                        ...triathlonActivities
                                          .filter(
                                            (id) =>
                                              !updatedChallengeTarget.some(
                                                (item) =>
                                                  item.activity_id === id
                                              )
                                          )
                                          .map((id) => ({
                                            activity_id: id,
                                            quota: "",
                                          })),
                                      ];
                                    }

                                    formik.handleChange({
                                      target: {
                                        name: "challangeTarget",
                                        value: updatedChallengeTarget,
                                      },
                                    });
                                  } else if (
                                    !checked &&
                                    existingItemIndex !== -1
                                  ) {
                                    const updatedChallengeTarget = [
                                      ...formik?.values?.challangeTarget.slice(
                                        0,
                                        existingItemIndex
                                      ),
                                      ...formik?.values?.challangeTarget.slice(
                                        existingItemIndex + 1
                                      ),
                                    ];

                                    formik.handleChange({
                                      target: {
                                        name: "challangeTarget",
                                        value: updatedChallengeTarget,
                                      },
                                    });
                                  }
                                }}
                              />
                            }
                            label={activity.activity_name}
                          />
                          {/* {formik?.values?.challangeTarget.some(
                            (item) => item.activity_id === activity.id
                          ) && (
                            <TextField
                              fullWidth
                              placeholder="Distance"
                              size="small"
                              type="number"
                              onChange={(e) => {
                                const updatedChallengeTarget =
                                  formik?.values?.challangeTarget.map((item) =>
                                    item.activity_id === activity.id
                                      ? { ...item, quota: e.target.value }
                                      : item
                                  );
                                formik.handleChange({
                                  target: {
                                    name: "challangeTarget",
                                    value: updatedChallengeTarget,
                                  },
                                });
                              }}
                            />
                          }
                          label={activity.activity_name}
                        /> */}
                          {formik?.values?.challangeTarget.some((item) => item.activity_id === activity.id) && (
                            <div className="flex">
                              <TextField
                                sx={{ maxWidth: "150px" }}
                                placeholder="Distance*"
                                size="small"
                                type="number"
                                value={formik.values.challangeTarget.find(item => item.activity_id === activity.id)?.quota || ''}
                                onChange={(e) => {
                                  const updatedChallengeTarget = formik?.values?.challangeTarget.map((item) =>
                                    item.activity_id === activity.id ? { ...item, quota: e.target.value } : item
                                  );
                                  formik.handleChange({
                                    target: {
                                      name: 'challangeTarget',
                                      value: updatedChallengeTarget,
                                    },
                                  });
                                }}
                              />
                              <TextField
                                sx={{ minWidth: "150px", marginLeft: "5px" }}
                                size="small"
                                select
                                name="durationunit"
                                value={formik.values.challangeTarget.find(item => item.activity_id === activity.id)?.unit || ''}
                                onChange={(e) => {
                                  const updatedChallengeTarget = formik?.values?.challangeTarget.map((item) =>
                                    item.activity_id === activity.id ? { ...item, unit: e.target.value } : item
                                  );
                                  formik.handleChange({
                                    target: {
                                      name: 'challangeTarget',
                                      value: updatedChallengeTarget,
                                    },
                                  });
                                }}
                                id="form-layouts-separator-select"
                                labelId="form-layouts-separator-select-label"
                                input={<OutlinedInput id="select-multiple-language" />}
                              >
                                <MenuItem value={""} disabled>
                                  Select
                                </MenuItem>
                                <MenuItem value={"km"}>Km</MenuItem>
                                <MenuItem value={"mtr"}>Mtr</MenuItem>
                              </TextField>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>

                    <Grid item xs={12} sm={5.5} className="spcl">
                      <Button
                        className="btn"
                        key="submit"
                        type="primary"
                        loading={isLoading}
                        onClick={() => formik.handleSubmit()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
              <div className="slick-container">
                <SlickCarousel />
              </div>
            </div>
          </Modal>
          {isAssignUserModalOpen
            &&
            <AssignChllenge allChalengesData={allChalengesData} modalName={modalName} isAssignUserModalOpen={isAssignUserModalOpen} setIsAssignUserModalOpen={setIsAssignUserModalOpen} isLoading={isLoading} fetchAllActivities={fetchAllActivities} handleOnSubmitForm={handleOnSubmitForm} />}
{isAssignedGroupModal
            &&
            <AssignChallengeGroup allChalengesData={allChalengesData} modalName={modalName} isAssignUserModalOpen={isAssignedGroupModal} setIsAssignUserModalOpen={setIsAssignedGroupModal} isLoading={isLoading} fetchAllActivities={fetchAllActivities} handleOnSubmitForm={handleOnSubmitForm} />}

          {showCreateTrackModal
            &&
            <CreateChallengeTrack allChalengesData={allChalengesData} modalName={modalName} isAssignUserModalOpen={showCreateTrackModal} setIsAssignUserModalOpen={setShowCreateTrackModal} isLoading={isLoading} fetchAllActivities={fetchAllActivities} handleOnSubmitForm={handleOnSubmitForm} />}



        </div>
        &nbsp;
        {assignedChallengeModal &&
          <AssignedChallenge />
        }
        {showTrackChellenges &&
          <ChallengeTrack />
        }
        {!showTrackChellenges && !assignedChallengeModal && (
          <div>
            <Table
              scroll={{ y: 280, x: 'max-content' }}
              columns={columns}
              dataSource={filteredList}
              pagination={true}
              className="thin-scrollbar"
            />
          </div>

        )}

      </div>
    </div>
  );
};

export default AdminCreateChallenge;
