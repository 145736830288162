import { Grid, TextField } from '@mui/material'
import React, { useMemo } from 'react'
import {  getAllSwimingData, URL } from '../../API/api-endpoint'
import { useEffect } from 'react'
import { Button } from 'antd'
import { useState } from 'react'
import Header from '../../components/Header'
import Background from "../../Images/ChallengeTracker.png";
import moment from 'moment';
import SlickCarousel from '../SlickCarousel';
import { capitalizeFirstLetter } from '../../utils/Resubale';
import StarRating from '../../utils/StarRating'


const SwimmingPool = ({ isEnrolledChallengesOpen }) => {
    const [allSwimmingPoolData, setAllSwimmingData] = useState()
    const [searchTerm, setSearchTerm] = useState("");
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);
    useEffect(() => {
        const handleResize = () => {
          setIsMobileView(window.innerWidth <= 500);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    
    useEffect(() => {
        fetchAllSwimmingData()

    }, [])


    const fetchAllSwimmingData = async () => {
        const response = await getAllSwimingData()
        console.log("response", response);
        if (response?.length > 0) {

            setAllSwimmingData(response)
        }
    }

    const filteredList = useMemo(() => {
        return allSwimmingPoolData?.filter((row) => {
            // Implement your search logic here
            // For example, if you want to search by a specific property like 'name':
            const firstnameMatches = row?.swimming_pool_name?.toLowerCase().includes(searchTerm?.toLowerCase());
            const cityMatch = row?.city?.name?.toLowerCase().includes(searchTerm?.toLowerCase());

            return firstnameMatches  ||cityMatch;
        });
    }, [allSwimmingPoolData, searchTerm]);
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4 "></div>
            <div style={isEnrolledChallengesOpen?.assignCoachId ? { padding: "20px", display: "flex", background: "#FFEADC", justifyContent: "center", alignItems: "center" } : { marginTop: "80px", padding: "20px", display: "flex", background: "#FFEADC", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "95%", minHeight: "90vh", padding: "20px" }}>
                    <div className='flex justify-between flexCol gap10'>
                        <div>
                            <h1 style={{ fontSize: "24px" }}><strong>Swimming Pool Details:</strong></h1>
                        </div>
                        <div>

                        <TextField
                        type="text"
                        size="small"
                        value={searchTerm}
                        onChange={(e)=>setSearchTerm(e.target.value)}
                        placeholder="Search By Swimming Pool,City.."
                    />
                    </div>
                        
                    </div>
                    {filteredList?.map((poolData) => {

                        return (
                            <div className='w-[100%] bg-[white] mt-6 p-2'>
                                <Grid container>
                                    {poolData?.user?.firstname &&

                                        <Grid item xs={12} sm={12} sx={{ textAlign: "end" }}>
                                            <Button disabled variant="outlined" style={{ backgroundColor: "white", cursor: "default", borderColor: "#E67E22", color: "#E67E22", fontWeight: 700 }}>Created By: {poolData?.user?.firstname} {poolData?.user?.lastname}</Button>
                                        </Grid>
                                    }
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} sm={7}>
                                            {Background &&
                                                <div style={{
                                                    // background: `url(${Background}) center no-repeat`,
                                                    backgroundSize: "100% 100%",
                                                    "height": "100%",
                                                    marginLeft: isMobileView ? "0px" : "14px",
                                                    padding: "12px",
                                                    minHeight: isMobileView ? "unset" : "70vh",
                                                    backgroundColor: "white"
                                                }}>
                                                    <Grid sx={{ position: "relative" }} container spacing={2}>
                                                        <Grid item xs={12} sm={4} >
                                                            <Grid item xs={12} sm={12} style={{ fontSize: "25px", margin: "0% 0% 0% 0%", lineHeight: "20px",  zIndex: "5" }}>
                                                                {capitalizeFirstLetter(poolData?.swimming_pool_name)}
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} style={{ fontSize: "15px", margin: "15% 0% 0% 2%", lineHeight: "20px",marginTop:"0px" }}>
                                                            Rating: <StarRating numStars={parseInt(poolData?.swimming_pool_rating)}/>
                                                            </Grid>

                                                        </Grid>
                                                        <Grid item xs={12} sm={7} style={{ fontSize: "15px" }}>
                                                        {poolData?.image ?
                                                            <div style={{ outline: 'none' }}>
                                                        <img style={{ outline: 'none',height:isMobileView ? "auto" :"400px",width:isMobileView ? "auto" :"400px",marginTop:isMobileView ? "5px":"40px",padding:isMobileView? "0" : "0.75rem" }}
                                                         src={`${URL}/static/public/userimages/${poolData?.image}`}
                                                         className="p-3 d-block img" alt="Slide 1" />
                                                    </div>
                                                            :null}

                                                        </Grid>

                                                    </Grid>
                                                </div>
                                            }

                                        </Grid>
                                        <Grid item xs={11.5} sm={4} style={isMobileView ? {"borderTop": "1px solid #E67E22", "margin": " 0 12px", fontSize: "13px",padding:"14px"} : { "borderLeft": "1px solid #E67E22", "margin": "auto", fontSize: "13px",marginLeft:"0" }}
                                        >
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} sm={6}>
                                                  Contact Person Name
                                                </Grid>
                                                <Grid className='textAlignUnset pt8' item xs={12} sm={6} sx={{ textAlign: "end" }}>
                                                    {capitalizeFirstLetter(poolData?.contact_name)}
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                  Contact Person Email
                                                </Grid>
                                                <Grid  className='textAlignUnset pt8' item xs={12} sm={5} sx={{ textAlign: "end" }}>
                                                    {poolData?.email_id}
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                  Contact Person Number
                                                </Grid>
                                                <Grid  className='textAlignUnset pt8' item xs={12} sm={5} sx={{ textAlign: "end" }}>
                                                    {poolData?.contact_number}
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    Place
                                                </Grid>
                                                <Grid className='textAlignUnset pt8' item xs={12} sm={5} sx={{ textAlign: "end" }}>
                                                    {poolData?.city?.name}
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    Map Link
                                                </Grid>
                                                <Grid  className='textAlignUnset pt8' item xs={10} sm={5} sx={{ textAlign: "end" }}>
                                                    <a  onClick={ ()=>window.open(poolData?.map_link, '_blank')} style={{cursor:"pointer",color:"blue", textDecoration:"underline",wordWrap:"break-word"}}>{poolData?.map_link}</a>
                                                </Grid>
                                                <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                                                    <Button variant="outlined" style={{ borderColor: "#E67E22", color: "#E67E22", fontWeight: 700 }} onClick={() => {
                                                        window.open(poolData?.website, '_blank');
                                                    }}>Check Link</Button>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        )
                    })}


                </div>

            </div>
        </div>
    )
}

export default SwimmingPool

