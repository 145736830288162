import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, CircularProgress, Grid, Pagination, TextField } from "@mui/material";
import "../../components/AssignCoach/Assign.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from "../../components/Header";
import { deleteGoalNameYTA, deleteGoalsData, getAllGoalNameForYTA, getAllGoalsData } from "../../API/api-endpoint";
import { IconEdit, IconTrash } from "@tabler/icons";
import { Modal } from "antd";
import CreateYrsca from "../../components/FeedData/CreateYrsca";
import CreateGoals from "../../components/FeedData/CreateGoals";
import { useLocation } from 'react-router-dom';
import MenuItem from "@mui/material/MenuItem";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const YrcsafeedData = () => {
    const { confirm } = Modal;
    const [reportData, setReportData] = useState()
    const [isOpen, setIsOpen] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [ActivityName, setActivityName] = useState()

    const location = useLocation();
    const [editData, setEditData] = useState()
    const [searchTerm, setSearchTerm] = useState('');
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);

   
    const fetchReport = async (activityName) => {
        const response = await getAllGoalsData()
        setIsLoading(false)
        let actvityData = response?.filter((filterData)=>filterData?.activity?.activity_name ==activityName)
        setReportData(actvityData)
    }
    const getAllGoalsDataa = async (activityName) => {
        const response = await getAllGoalNameForYTA()
        setIsLoading(false)
        console.log("response55", response);
        setReportData(response)
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const activityName = searchParams.get('activityname');
        setActivityName(activityName)
        if (isOpen) {
            fetchReport(activityName)
        }
        if (!isOpen) {
            getAllGoalsDataa(activityName)
        }
    }, [isOpen,location.search])
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedActivityFilter, setSelectedActivityFilter] = useState('All');

    const handleActivityFilterChange = (event) => {
        setSelectedActivityFilter(event.target.value);
        setCurrentPage(1); // Reset the current page when the filter changes
    };

    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            if (selectedActivityFilter === 'All') {
                return  row?.goalnamesforytum?.goalname?row?.goalnamesforytum?.goalname?.toLowerCase().includes(searchTerm.toLowerCase()):row?.goalname?.toLowerCase().includes(searchTerm.toLowerCase());
            } else {
                return (
                    row?.goalnamesforytum?.goalname?.toLowerCase().includes(searchTerm.toLowerCase())  ?
                    row?.goalnamesforytum?.goalname?.toLowerCase().trim() === selectedActivityFilter.toLowerCase():
                    row?.goalname?.toLowerCase().trim() === selectedActivityFilter.toLowerCase()
                );
            }
        });
    }, [reportData, selectedActivityFilter, searchTerm]);
    let PageSize = searchTerm || selectedActivityFilter !== 'All' ? filteredList?.length : 15;
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            // Adjusting index on the first page and adding count from the second page onward
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData,filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const DeleteZones = async (id) => {
        if (isOpen) {
            const response = await deleteGoalsData(id)
            setCurrentPage(1)
            fetchReport(ActivityName)
        } else {
            const response = await deleteGoalNameYTA(id)
            setCurrentPage(1)
            getAllGoalsDataa()
        }
    }
    const showDeleteConfirm = (id) => {
        confirm({
            title: `Are you sure delete this ${ ActivityName?.toLowerCase()} feed data?`,
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                DeleteZones(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    const runningMenuItems = [
        { value: "All", label: "All" },
        { value: "Marathon", label: "Marathon" },
        { value: "10K", label: "10K" },
        { value: "GoalX", label: "GoalX" },
        { value: "Half Marathon", label: "Half Marathon" },
    ];

    const cyclingMenuItems = [
        { value: "All", label: "All" },
        { value: "75K", label: "75K" },
        { value: "100K", label: "100K" },
        { value: "125K", label: "125K" },
        { value: "150K", label: "150K" },
        { value: "200K", label: "200K" },
        { value: "300K", label: "300K" },
    ];

    const swimmingMenuItems = [
        { value: "All", label: "All" },
        { value: "swim1", label: "swim1" },
    ];
    const fitnessMenuItems = [
        { value: "All", label: "All" },
        { value: "fit1", label: "fit1" },
    ];

    const triathlonMenuItems = [
        { value: "All", label: "All" },
        { value: "tri1", label: "tri1" },
    ];
    const duathlonMenuItems = [
        { value: "All", label: "All" },
        { value: "duathlon1", label: "duathlon1" },
    ];
    const nutritionMenuItems = [
        { value: "All", label: "All" },
        { value: "nutri1", label: "nutri1" },
    ];
    const noteTipMenuItems = [
        { value: "All", label: "All" },
        { value: "tip1", label: "tip1" },
    ];

    // Render menu items based on activity name
    const renderMenuItems = () => {
        switch (ActivityName) {
            case "Running":
                return runningMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
            case "Cycling":
                return cyclingMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Swimming":
                return swimmingMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Fitness":
                return fitnessMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Triathlon":
                return triathlonMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Duathlon":
                return duathlonMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Nutrition":
                return nutritionMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
                case "Note/Tip":
                return noteTipMenuItems.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ));
            // Add more cases for other activity types if needed
            default:
                return [];
        }
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "10px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container >
                        <Grid className="mt15" item xs={12} sm={2} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => setShowAssesmentModal(true)}>{isOpen ? `Create ${ActivityName} Goals` : "Create Goals"}</Button>
                            </Grid>
                            <Grid className="mt20" item xs={12} sm={2} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => setIsOpen(!isOpen)}>{isOpen ? "Get Goals" : `Get ${ActivityName} Goals`}</Button>
                            </Grid>
                            <Grid  className="mt30 textStart" item xs={12} sm={6} sx={{ textAlign: "end", marginTop: "30px" }}>
                                <TextField
                                    select
                                    size="small"
                                    className="width220"
                                    label="Filter by Activity"
                                    value={selectedActivityFilter}
                                    onChange={handleActivityFilterChange}
                                    style={{ width: '150px' }} 
                                >
                                     {renderMenuItems()}
                                </TextField>
                            </Grid>
                            <Grid  className="mt20 textStart" item xs={9} sm={2} sx={{ textAlign: "end",marginTop: "30px" }}>
                            <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Goal Name.." />
                        </Grid>
                        </Grid>
                    </div>
                    {isOpen ? (
                        <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                        <Table stickyHeader sx={{ minWidth: 700, }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">Sr ID</StyledTableCell>
                                    <StyledTableCell align="left">Activity Name</StyledTableCell>
                                    <StyledTableCell align="left">Goal Name</StyledTableCell>
                                    <StyledTableCell align="left">First</StyledTableCell>
                                    <StyledTableCell align="left">Second</StyledTableCell>
                                    <StyledTableCell align="left">Third</StyledTableCell>
                                    <StyledTableCell align="left">Fourth</StyledTableCell>
                                    <StyledTableCell align="left">Fifth</StyledTableCell>
                                    <StyledTableCell align="left">Sixth</StyledTableCell>
                                    <StyledTableCell align="left">Seventh</StyledTableCell>
                                    <StyledTableCell align="left">Week No</StyledTableCell>
                                    <StyledTableCell align="left">Total</StyledTableCell>
                                    <StyledTableCell align="left">Action</StyledTableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <CircularProgress className="m-6" />) : (
                                    <>
                                        {checkLastPage?.length > 0 ? (
                                            <>
                                                {checkLastPage?.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">
                                                            {row?.srID}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.activity?.activity_name}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.goalnamesforytum?.goalname}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.first}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.second}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.third}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.fourth}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.fifth}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.sixth}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.seventh}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.week}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            {row?.total}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <div className="flex ">
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconEdit
                                                                        color="blue"
                                                                        onClick={() => {
                                                                            setShowAssesmentModal(true);
                                                                            setEditData(row);
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconTrash
                                                                        color="red"
                                                                        onClick={() => showDeleteConfirm(row?.id)}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="p-4">No data found</div>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    ) : (
                        <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="left">Sr ID</StyledTableCell>
                                    <StyledTableCell align="left">Goal Name</StyledTableCell>

                                    <StyledTableCell align="left">Action</StyledTableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <CircularProgress className="m-6" />) : (
                                    <>
                                        {checkLastPage?.length > 0 ? (
                                            <>
                                                {checkLastPage?.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">
                                                            {row?.srID}
                                                        </StyledTableCell>

                                                        <StyledTableCell align="left">
                                                            {row?.goalname}

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <div className="flex ">
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconEdit
                                                                        color="blue"
                                                                        onClick={() => {
                                                                            setShowAssesmentModal(true);
                                                                            setEditData(row);
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="px-2 cursor-pointer">
                                                                    <IconTrash
                                                                        color="red"
                                                                        onClick={() => showDeleteConfirm(row?.id)}
                                                                    />
                                                                </span>
                                                            </div>

                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </>
                                        ) : (
                                            <div className="p-4">No data found</div>
                                        )}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )}
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            {
                isOpen ?
                    <CreateYrsca setEditData={setEditData} ActivityName={ActivityName} editData={editData} fetchReport={fetchReport} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />
                    :
                    <CreateGoals setEditData={setEditData} editData={editData} fetchReport={getAllGoalsDataa} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />

            }
        </div>
    )
}
export default YrcsafeedData
