import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { createSwim, getAllCities, getAllCountry, getAllState, updateSwimmingPoolData, uploadsaveFile } from '../../API/api-endpoint';
import { Button, Modal } from 'antd';
import { Autocomplete, FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import SlickCarousel from '../SlickCarousel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactSelect from 'react-select';
import { IoCloseSharp } from 'react-icons/io5';

const CreateSwimming = ({ setEditSwimmingPooldata, editSwimmingPooldata, fetchAllSwimmingData, setShowAssignedChallengeModal, assignedChallengeModal, allChalengesData }) => {
    const [isLoading, setIsLoading] = useState();
    const [CountryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const formik = useFormik({
        initialValues: {

            swimming_pool_name: "", city_id: "", favourite_tag: true, swimming_pool_rating: "",
            address: "", added_by: "", contact_name: "", email_id: "", contact_number: "", map_link: "", website: "", added_datetime: ""
            ,country_id:"",state_id:"",city_id:"",image:""
        },
        validate: (values) => {
            const errors = {};
            if (!values.swimming_pool_name) {
                errors.swimming_pool_name = "Name is required";
            }

            if (!values.favourite_tag) {
                errors.favourite_tag = "Tag is required";
            } if (!values.swimming_pool_rating) {
                errors.swimming_pool_rating = "Rating is required";
            }
            else if (values.swimming_pool_rating < 1 || values.swimming_pool_rating > 5) {
                errors.swimming_pool_rating = "Rating must be between 1 and 5";
            }
            if (!values.address) {
                errors.address = "Address is required";
            } if (!values.contact_name) {
                errors.contact_name = "Contact name is required";
            }
            else if (!/^[0-9]{10}$/i.test(values.contact_number)) {
                errors.contact_number = 'Invalid contact number. Please enter a 10-digit number.';
            } if (!values.email_id) {
                errors.email_id = "Email is required";
            }
            else if (!/^\S+@\S+\.\S+$/.test(values.email_id)) {
                errors.email_id = "Invalid email address";
            } if (!values.contact_number) {
                errors.contact_number = "Contact number is required";
            }
            if (!values.map_link) {
                errors.map_link = "Map link is required";
            }
            if (!values.website) {
                errors.website = "Website link is required";
            }
            if (!values.added_by) {
                errors.added_by = "Person name is required";
            }
            if (!values.added_datetime) {
                errors.added_datetime = "Date is required";
            }
            if (!values.state_id) {
                errors.state_id = "State is required";
              }
              if (!values.city_id) {
                errors.city_id = "City is required";
              }
              if (!values.country_id) {
                errors.country_id = "Country is required";
              }
              if (!values.image) {
                errors.image = "Image is required";
              }
              
            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            handleOnSubmitForm(values, resetForm)

        },
    });

    const handleOnSubmitForm = async () => {
        try {
            setIsLoading(true)
            if (editSwimmingPooldata?.swimming_pool_id) {
                const Result = await updateSwimmingPoolData(formik?.values);
                if (Result.status) {
                    setIsLoading(false)
                    Swal.fire({
                        title: "Success",
                        text: Result.message,
                        icon: "success",
                    });
                    setShowAssignedChallengeModal(false)
                    fetchAllSwimmingData()
                    formik.setValues()
                    formik.resetForm()
                    setEditSwimmingPooldata({})
                    formik.setValues()

                    // if (resetForm) {
                    formik.resetForm()
                    // }
                }
                else {
                    setIsLoading(false)
                    Swal.fire({
                        title: "Error",
                        text: Result.message,
                        icon: "error",
                    });
                    setShowAssignedChallengeModal(false)


                }
            } else {
                const Result = await createSwim(formik?.values)
                if (Result.status) {
                    setIsLoading(false)
                    Swal.fire({
                        title: "Success",
                        text: Result.message,
                        icon: "success",
                    });
                    setShowAssignedChallengeModal(false)
                    fetchAllSwimmingData()
                    formik.setValues()
                    formik.resetForm()
                    setEditSwimmingPooldata({})
                }
                else {
                    setIsLoading(false)
                    Swal.fire({
                        title: "Error",
                        text: Result.message,
                        icon: "error",
                    });
                }
            }

        } catch (error) {
            setIsLoading(false)

        }
    }
    const options = allChalengesData?.users?.map((value) => ({
        label: value['firstname'],
        value: value['id'],
    })) || [];

    useEffect( () => {
        if (editSwimmingPooldata?.swimming_pool_id) {
        getCountry()
            fetchData(editSwimmingPooldata?.country_id, editSwimmingPooldata?.state_id)
            formik?.setValues(editSwimmingPooldata)

        }
        getCountry()
    }, [editSwimmingPooldata?.swimming_pool_id])

    const getCountry = async () => {
        let response = await getAllCountry();
        setCountryList(response);
    };
    let fetchData = async(country, state)=>{
        const response = await getAllState(country);
        setStateList(response);
        const responsecity = await getAllCities(country,state);
        setCityList(responsecity);
    }
    const handleCountryChange = async (event, newValue) => {
        const countryId = newValue;
        formik?.setFieldValue("country_id", countryId?.id)
        const response = await getAllState(countryId?.id);
        setStateList(response);
    };
    const handleStateChange = async (event, newValue) => {
        const stateID = newValue;
        formik?.setFieldValue("state_id", stateID?.id)

        // Fetch states based on the selected country ID
        const response = await getAllCities
            (formik?.values?.country_id, stateID?.id);
        setCityList(response);
    };
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
    
          const responst = await uploadsaveFile(formData);
          if (responst?.status) {
            formik.setFieldValue("image", responst?.file);
          }
        }
      };
      const filterOptions = (options, { inputValue }) => {
        return options.filter(option =>
          option.name.toLowerCase().startsWith(inputValue.toLowerCase())
        );
      };
      const filterStateOptions = (options, { inputValue }) => {
        return options.filter(option =>
          option.name.toLowerCase().startsWith(inputValue.toLowerCase())
        );
      };
      const filterCityOptions = (options, { inputValue }) => {
        return options.filter(option =>
          option.name.toLowerCase().startsWith(inputValue.toLowerCase())
        );
      };
    return (
        <Modal
            width={1200}
            // title="Add Swimming"
            open={assignedChallengeModal}
            onCancel={() => {
                setShowAssignedChallengeModal(false)
                formik?.setValues({

                    swimming_pool_name: "", city_id: "", favourite_tag: true, swimming_pool_rating: "",
                    address: "", added_by: "", contact_name: "", email_id: "", contact_number: "", map_link: "", website: "", added_datetime: ""
                    ,country_id:"",state_id:"",city_id:"" ,image:""})
                setEditSwimmingPooldata({})
                formik?.resetForm()
            }}
            footer={
                <div></div>}
        >
            <div className="headingCont">
                <span className="heading">Add</span>{" "}
                <span className="orange heading">Swimming Pool</span>
            </div>
            <div className="parentCont">
                <form  className="form1" onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={11} >
                            <FormLabel >Swimming Pool Name:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Swimming Pool Name"
                                size="small"
                                type="text"
                                name="swimming_pool_name"
                                value={formik?.values?.swimming_pool_name}
                                onChange={formik.handleChange}
                                error={formik.touched.swimming_pool_name && formik.errors.swimming_pool_name}
                                helperText={
                                    formik.touched.swimming_pool_name && formik.errors.swimming_pool_name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Favourite Tag:</FormLabel>
                            <TextField
                                fullWidth
                                size="small"
                                select
                                name="favourite_tag"
                                value={formik?.values?.favourite_tag}
                                onChange={formik.handleChange}
                                error={formik.touched.favourite_tag && formik.errors.favourite_tag}
                                helperText={
                                    formik.touched.favourite_tag && formik.errors.favourite_tag
                                }
                                id="form-layouts-separator-select"
                                labelId="form-layouts-separator-select-label"
                                input={<OutlinedInput id="select-multiple-language" />}
                            >
                                <MenuItem value={true} >
                                    Yes
                                </MenuItem>
                                <MenuItem value={"false"} >
                                    No
                                </MenuItem>

                            </TextField>

                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Swimming Pool Rating:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Swimming Pool Rating"
                                size="small"
                                type="text"
                                name="swimming_pool_rating"
                                value={formik?.values?.swimming_pool_rating}
                                onChange={formik.handleChange}
                                error={formik.touched.swimming_pool_rating && formik.errors.swimming_pool_rating}
                                helperText={
                                    formik.touched.swimming_pool_rating && formik.errors.swimming_pool_rating
                                }

                            />
                        </Grid>
                        <Grid item xs={12} sm={3.5} >


                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={CountryList}
                                getOptionLabel={(option) => option.name}
                                value={CountryList.find((option) => option.id === formik?.values?.country_id) || null}
                                onChange={handleCountryChange}
                      filterOptions={filterOptions}
                                className="running-step-input1"
                                renderInput={(params) => <TextField {...params} label={!formik?.values?.country_id && 'Country*'} />}
                            />
                             {formik.touched.country_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.country_id}</div>
                  }
                        </Grid>
                        <Grid item xs={12} sm={3.5} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={stateList}
                      filterOptions={filterStateOptions}
                                getOptionLabel={(option) => option.name}
                                value={stateList.find((option) => option.id === formik?.values.state_id) || null}
                                onChange={handleStateChange}
                                className="running-step-input1"
                                renderInput={(params) => <TextField {...params} label={!formik?.values?.state_id && 'State*'} />}
                            />
                            {formik.touched.state_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.state_id}</div>
                  }
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={cityList}
                      filterOptions={filterCityOptions}

                                getOptionLabel={(option) => option.name}
                                value={cityList.find((option) => option.id === formik?.values.city_id) || null}
                                onChange={(e, newValue) => {
                                    formik?.setFieldValue("city_id", newValue?.id)
                                }}
                                className="running-step-input1"
                                renderInput={(params) => <TextField {...params} label={!formik?.values?.city_id && 'City*'} />}
                            />
                              {formik.touched.city_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.city_id}</div>
                  }
                        </Grid>
                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Address:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Address"
                                size="small"
                                type="text"
                                name="address"
                                value={formik?.values?.address}
                                onChange={formik.handleChange}
                                error={formik.touched.address && formik.errors.address}
                                helperText={
                                    formik.touched.address && formik.errors.address
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Contact Name:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Contact Name"
                                size="small"
                                type="text"
                                name="contact_name"
                                value={formik?.values?.contact_name}
                                onChange={formik.handleChange}
                                error={formik.touched.contact_name && formik.errors.contact_name}
                                helperText={
                                    formik.touched.contact_name && formik.errors.contact_name
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Email ID:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Email ID"
                                size="small"
                                type="text"
                                name="email_id"
                                value={formik?.values?.email_id}
                                onChange={formik.handleChange}
                                error={formik.touched.email_id && formik.errors.email_id}
                                helperText={
                                    formik.touched.email_id && formik.errors.email_id
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Contact Number:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Contact Number"
                                size="small"
                                type="text"
                                name="contact_number"
                                value={formik?.values?.contact_number}
                                onChange={formik.handleChange}
                                error={formik.touched.contact_number && formik.errors.contact_number}
                                helperText={
                                    formik.touched.contact_number && formik.errors.contact_number
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Website:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Website"
                                size="small"
                                type="text"
                                name="website"
                                value={formik?.values?.website}
                                onChange={formik.handleChange}
                                error={formik.touched.website && formik.errors.website}
                                helperText={
                                    formik.touched.website && formik.errors.website
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Map Link:</FormLabel>
                            <TextField
                                fullWidth
                                placeholder="Map Link"
                                size="small"
                                type="text"
                                name="map_link"
                                value={formik?.values?.map_link}
                                onChange={formik.handleChange}
                                error={formik.touched.map_link && formik.errors.map_link}
                                helperText={
                                    formik.touched.map_link && formik.errors.map_link
                                }
                            />
                        </Grid>


                        <Grid item xs={12} sm={5.5} >
                            <FormLabel >Added By:</FormLabel>
                            <FormControl
                                fullWidth
                                error={formik.touched.added_by && Boolean(formik.errors.added_by)}
                            >
                                <ReactSelect
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: formik.touched.added_by && formik.errors.added_by ? 'red' : provided.borderColor,
                                            '&:hover': {
                                                borderColor: formik.touched.added_by && formik.errors.added_by ? 'red' : provided['&:hover'].borderColor,
                                            },
                                            // boxShadow: formik.touched.added_by && formik.errors.added_by ? '0 0 0 1px red' : provided.boxShadow,
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            scrollbarColor: '#E67E22 white',
                                            scrollbarWidth: 'thin',
                                            '&::-webkit-scrollbar': {
                                                width: '12px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: 'white',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#E67E22',
                                                borderRadius: '20px',
                                                border: '3px solid transparent',
                                                backgroundClip: 'content-box',
                                            },
                                        }),
                                    }}
                                    name="added_by"
                                    classNamePrefix="custom-select"
                                    //   isMulti
                                    // value={formik?.values?.added_by}
                                    options={options}
                                    value={options.find(option => option?.value === formik?.values?.added_by) || []}
                                    onChange={(selectedOption) => {
                                        formik.setFieldValue('added_by', selectedOption ? selectedOption.value : '');
                                    }}

                                    placeholder="Select Person"
                                    isClearable
                                />
                                {formik.touched.added_by && formik.errors.added_by ? (
                                    <FormHelperText>{formik.errors.added_by}</FormHelperText>
                                ) : null}
                            </FormControl>


                        </Grid>

                        <Grid className="dateWrap" item xs={12} sm={5.5} >
                            <FormLabel >Added Date and Time:</FormLabel>
                            <DatePicker
                                selected={formik.values.added_datetime ? new Date(formik.values.added_datetime) : null}
                                onChange={(date) => {
                                    formik.setFieldValue(
                                        "added_datetime",
                                        moment(date).format("YYYY-MM-DD")
                                    );
                                }}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                minDate={new Date()}
                                customInput={
                                    <TextField
                                        fullWidth
                                        placeholder="Added Date and Time"
                                        size="small"
                                        type="text"
                                        name="added_datetime"
                                        error={formik.touched.added_datetime && formik.errors.added_datetime}
                                        helperText={formik.touched.added_datetime && formik.errors.added_datetime}
                                    />
                                }
                            />


                        </Grid>
                         <Grid item xs={12} sm={11} className="relative">
              <FormLabel>Upload Image<span className="text-[red]">*</span></FormLabel>

              <TextField
                fullWidth
                placeholder="Zone"
                size="small"
                type={formik?.values?.image ? "text" : "file"}
                name="image"
                disabled={formik?.values?.image}
                value={formik?.values?.image}
                onChange={handleFileUpload}
                error={formik.touched.image && formik.errors.image}
                helperText={formik.touched.image && formik.errors.image}
                inputProps={{
                  accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                }}
              />
              {formik?.values?.image && (
                <IoCloseSharp
                  onClick={() => {
                    formik.setFieldValue("image", "");
                  }}
                  color="darkgray"
                  className="cursor-pointer absolute top-[5px] right-[-10px]"
                  style={{ fontSize: "24px" }}
                />
              )}
            </Grid>
                        <Grid item xs={4} sm={4}>
                            <Button className="btn" key="submit" type="primary" onClick={() => formik.handleSubmit()}>Submit</Button>
                        </Grid>
                    </Grid>
                </form>
                <div className="slick-container">
                    <SlickCarousel />
                </div>
            </div>
        </Modal>
    )
}

export default CreateSwimming
