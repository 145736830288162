import React, { useEffect, useRef } from "react";
//componants
import Calendar from "./componants/calender/calender";
// import MiniCalender from "./componants/calender/minicalender";
import Filter from "./componants/Filter/filter";
import WorkoutForm from "./componants/workoutForm/WorkoutDrawer"; //WorkoutLibraryDrawer
import Searchbar from "./componants/Searchbar/searchbar";
import WorkoutLibraryDrawer from "./componants/WorkoutLibrary/WorkoutLibraryDrawer";
import { useState } from "react";
import { Grid, Card } from "@mui/material";
import Header from "../../components/Header";
import ManageWorkouts from "./componants/ManageWorkouts/ManageWorkouts";
// import { DndProvider } from "react-dnd";
import Example from "../TraningBlock/example";
import { useSelector,useDispatch  } from "react-redux";
import DropZone from "../TraningBlock/DropZone";
import Row from "../TraningBlock/Row";
import WorkoutCalender from "./componants/workoutCalender";
import { getAllWorkoutLibrary1, getAllworkoutsDetails } from "../../API/api-endpoint";
import { IoCloseSharp } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import "../Workouts/componants/ManageWorkouts/AddWorkout.css"
import { getCurrentUserChatsAction } from "../Chat/redux/action/userChats";
import { getAllUsersAction } from "../Chat/redux/action/usersAction";
import AddWorkout from "./componants/ManageWorkouts/AddWorkout";
// import { db } from "../../../API/firebase.config";
import {
  onValue,
  query,
  limitToLast,
  orderByKey,
  orderByChild,
  equalTo,
} from "firebase/database";
import {
  getDatabase,
  ref,
  get,
  set,
  update,
  serverTimestamp,
  off,
  onDisconnect
} from "firebase/database";
import useMediaQuery from '@mui/material/useMediaQuery';

const Workouts = () => {
  let layout = useSelector((state) => state.MultiTrainingBlocksSlice.layout);
  const dispatch = useDispatch();

  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("allEvents")) !== null
      ? JSON.parse(localStorage.getItem("allEvents"))
      : []
  );
  const roleID = localStorage.getItem("roleID")

  const { allUsers } = useSelector((state) => state.users);
  const { currentUser } = useSelector((state) => state.auth);
  const [filteredData, setFilteredData] = useState(data);
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);

  const [fetchAllActivities, setFetchActivies] = useState();
  //for binding mini calender with big calender
  const [dateForworkout, setDate] = useState(false);

  //event form state
  const [allEvents, setAllevents] = useState(false);
  const [workoutformdrawerState, setworkoutformdrawerState] = useState(false);
  const [workoutID, setWorkoutID] = useState();
  const [CopyWorkoutData, setCopyWorkoutData] = React.useState({
    year: "",
    month: "",
    copyweekindex: "",
    pasteweekindex: "",
  });
  useEffect(() => {
    if (workoutformdrawerState === false) {
      setWorkoutID("")
    }
  }, [workoutformdrawerState])

  const [workoutLibraryDrawerState, setWorkoutLibraryDrawerState] =
    useState(false);
  const [formData, setformData] = useState();
  const [isOpenHandleWorkOut, setIsOpenHandleWorkOut] = useState(false);
  const [isOpenDirect, setOpenEditDirect] = useState(false);

  const [isOpenWorkOut, setIsOpenWorkOut] = useState("showTable");
  const [selectedLibraryItem, setSelectedLibraryItem] = useState(null);
  const [workoutLibrary, setWorkoutLibrary] = useState([]);
  const [fetchActivePrograms, setFetchActivePrograms] = useState();
  const [workouts, setWorkouts] = useState(undefined);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 850) {
        setIsOpenSideBar(true);
      } else {
        setIsOpenSideBar(false);
      }
    };

    // Call handleResize immediately to set the initial state
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [draggedEvent, setdraggedEvent] = useState(null);
  const [isLibraryChange, setIsLibraryChange] = useState(false);
  const [editFormData, setEditFormData] = useState({
    workout_id: null,
    isEditForm: false,
  });
  const [items, setItems] = useState("training_page");
  console.log("isOpenWorkOut", isOpenWorkOut);

  const mediaQuery = useMediaQuery('(max-width:500px)');

// Initialize state based on media query
const [matches, setMatches] = useState(mediaQuery);

useEffect(() => {
  // Update state based on media query
  setMatches(mediaQuery);
}, [mediaQuery]);

useEffect(() => {
  if (matches) {
    setIsOpenSideBar(true);
  }
}, [matches]);



  useEffect(() => {
    const items = localStorage.getItem("training_page");
    if (items) {
      setItems(items);
    }
  }, []);

  const renderRow = (row, currentPath) => {
    return (
      <Row
        key={row.id}
        data={row}
        // handleDrop={handleDrop}
        // components={components}
        path={currentPath}
      />
    );
  };

  const getAllWorkouts = async () => {
    let result = await getAllworkoutsDetails();
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
  
    // Map through the events to update the `start` property
    const updatedEvents = result.map(event => ({
      ...event,
      start: formatDate(event.datetf)
    }));
  
      setAllevents(updatedEvents);
  };
  useEffect(() => {
    // Initial call when the component mounts
    getAllWorkouts();

    // Set up an interval to call the API every 1 hour (3600000 milliseconds)
    const intervalId = setInterval(() => {
      getAllWorkouts();
    }, 3600000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [fetchActivePrograms?.program_id]);

  useEffect(() => {
    dispatch(getCurrentUserChatsAction(currentUser?.uid));
    dispatch(getAllUsersAction(roleID));
  }, [currentUser?.uid, roleID]);

  useEffect(() => {
    dispatch(getCurrentUserChatsAction(currentUser?.uid));
    dispatch(getAllUsersAction(roleID));
  }, []);

  useEffect(() => {
    getLibraryList();
  }, []);

  const getLibraryList = async () => {
    let result = await getAllWorkoutLibrary1();
    setWorkoutLibrary(result?.library);
  };
  const draggableEl = useRef(null);

const handleDragStart = (e, data) => {
  e.dataTransfer.setData('text/plain', JSON.stringify(data));
  setdraggedEvent(data)
};

const db = getDatabase();
const isOnlineRef = ref(db, ".info/connected");
const userStatusRef = ref(db, `/users/${currentUser?.uid}/onlineStatus/isOnline`);

onValue(isOnlineRef, (snapshot) => {
  if (snapshot.val() === false) {
    return;
  }

  set(userStatusRef, true);
  onDisconnect(userStatusRef).set(false);
});
  return (
    <Card>
      <Header setFetchActivePrograms={setFetchActivePrograms}/>
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div  className="work-calendar" style={{ fontSize: "18px", width: "100%", marginTop: "96px",height:"90vh" }}>
        <Grid container spacing={0} >
          {!isOpenSideBar && roleID != 5
            &&
            <Grid className="workSidebar" item md={2} xs={0} sm={3} style={matches ? {zIndex: "3", background: "#FFEADC", height: "90vh", padding: "10px", position:"relative" } : { background: "#FFEADC", height: "90vh", padding: "10px", position:"relative" }}>
              <div className="scroll-container"
              >
                <WorkoutForm
                dateForworkout={dateForworkout}
                  workoutID={workoutID}
                  getAllWorkouts={getAllWorkouts}
                  setworkoutformdrawerState={setworkoutformdrawerState}
                  workoutformdrawerState={workoutformdrawerState}
                  formData={formData}
                  setmasterData={setData}
                  masterData={data}
                  setFilteredData={setFilteredData}
                />
                <WorkoutLibraryDrawer
                  getLibraryList={getLibraryList}
                  setFetchActivies={setFetchActivies}
                  fetchAllActivities={fetchAllActivities}
                  workoutLibraryDrawerState={workoutLibraryDrawerState}
                  setWorkoutLibraryDrawerState={setWorkoutLibraryDrawerState}
                  formData={formData}
                  setmasterData={setData}
                  masterData={data}
                  setFilteredData={setFilteredData}
                  selectedLibraryItem={selectedLibraryItem}
                  setSelectedLibraryItem={setSelectedLibraryItem}
                />

                {/*
          <Filter data={data} setFilteredData={setFilteredData} />
          */}

                <Searchbar
                getLibraryList={getLibraryList}
                setWorkouts={setWorkouts}
                workouts={workouts}
                handleDragStart={handleDragStart}
                draggableEl={draggableEl}
                  workoutLibrary={workoutLibrary}
                  setdraggedEvent={setdraggedEvent}
                  setIsOpenHandleWorkOut={setIsOpenHandleWorkOut}
                  selectedLibraryItem={selectedLibraryItem}
                  setSelectedLibraryItem={setSelectedLibraryItem}
                  workoutLibraryDrawerState={workoutLibraryDrawerState}
                  setWorkoutLibraryDrawerState={setWorkoutLibraryDrawerState}
                  setIsLibraryChange={setIsLibraryChange}
                  setIsOpenWorkOut={setIsOpenWorkOut}
                  editFormData={editFormData}
                  setEditFormData={setEditFormData}
                  isOpenDirect={isOpenDirect}
                  setOpenEditDirect={setOpenEditDirect}
                  matches={matches}
                  setIsOpenSideBar={setIsOpenSideBar}

                />
              </div>
              {!isOpenSideBar &&
                <IoCloseSharp onClick={()=>{
                  setIsOpenSideBar(!isOpenSideBar)
                }}  color="#E67E22" className="cursor-pointer absolute top-4 right-[10px]" style={{fontSize:"30px"}} />
                
              }
            </Grid>
          }

          <Grid item md={!isOpenSideBar && roleID != 5 ? 10 : 12} xs={12} sm={!isOpenSideBar && roleID != 5 ? 9 : 12} >
       
            <div  >
            {isOpenDirect && (
              <AddWorkout
              setOpenEditDirect={setOpenEditDirect}
                fetchAllActivities={fetchAllActivities}
                setIsOpenWorkOut={setIsOpenWorkOut}
                libraryName={selectedLibraryItem["workout-library-name"]}
                libraryID={selectedLibraryItem["id"]}
                setIsLibraryChange={setIsLibraryChange}
                workoutsData={workouts}
                editFormData={editFormData}
                setWorkouts={setWorkouts}
                isOpenDirect={isOpenDirect}
                // OptionListFormAddWorkoutForm={OptionListFormAddWorkoutForm}
              />
            )}
              {isOpenHandleWorkOut ? (
                <div>
                  {/* <DndProvider backend={HTML5Backend}>
                  <ManageWorkouts
                    setIsOpenWorkOut={setIsOpenWorkOut}
                    isOpenWorkOut={isOpenWorkOut}
                    selectedLibraryItem={selectedLibraryItem}
                    setIsLibraryChange={setIsLibraryChange}
                    isLibraryChange={isLibraryChange}
                  />
                </DndProvider> */}
                  <ManageWorkouts
                    setIsOpenWorkOut={setIsOpenWorkOut}
                    isOpenWorkOut={isOpenWorkOut}
                    selectedLibraryItem={selectedLibraryItem}
                    fetchAllActivities={fetchAllActivities}
                    setIsLibraryChange={setIsLibraryChange}
                    isLibraryChange={isLibraryChange}
                    editFormData={editFormData}
                    setEditFormData={setEditFormData}
                    workoutsData={workouts}
                    matches={matches}
                  />
                </div>
              ) : (
                <div >
                  {!items ? (
                    layout.map((row, index) => {
                      const currentPath = `${index}`;
                      return (
                        <React.Fragment key={row.id}>
                          <DropZone
                            data={{
                              path: currentPath,
                              childrenCount: layout.length,
                            }}
                            // onDrop={handleDrop}
                            path={currentPath}
                          />
                          {renderRow(row, currentPath)}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <div>
                    {!isOpenDirect &&
                      <WorkoutCalender setCopyWorkoutData={setCopyWorkoutData} CopyWorkoutData={CopyWorkoutData} setdraggedEvent={setdraggedEvent} isOpenSideBar={isOpenSideBar} setIsOpenSideBar={setIsOpenSideBar} draggableEl={draggableEl} workoutID={workoutID} getAllWorkouts={getAllWorkouts} setDate={setDate} dateForworkout={dateForworkout} setAllevents={setAllevents} setWorkoutID={setWorkoutID} setworkoutformdrawerState={setworkoutformdrawerState} workoutformdrawerState={workoutformdrawerState} eventsData={allEvents} />
                    }
                 </div> )}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>


    </Card >
  );
};

export default Workouts;
