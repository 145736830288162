import React, { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { COMPONENT } from "./constants";
import { useSelector } from "react-redux";
import { DeleteWorkoutFromTrainingPlanMasterMaping, createTrainingPlanMasterMaping, deleteTrainingPlanMasterMaping } from "../../API/api-endpoint";
import { MdOutlineAutoDelete } from "react-icons/md";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getTrainingPlanMasterMappingByTrainingId, updateColumnsinTrainingData, updateLastColumnDayInRow } from "../../store/slices/MultiTrainingBlocksSlice";
import { useDispatch } from "react-redux";
import { IoEyeOutline } from "react-icons/io5";
import ViewTrainingBlock from "./ViewTrainingBlock";
import { FiEdit } from "react-icons/fi";
import EditWorkout from "./EditWorkout";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { showInfo, showSuccess } from "../../components/Messages";
const style = {
  cursor: "move",
};
const Component = ({ data,handleCopy,showDeleteConfirm,handlePastProgram, path, id, CurrentTrainingBlock,openBox,setOpenBox,isEditOpen,setIsEditOpen,selectedID,setSelectedID,sectedCopyData,setSelectCopyData }) => {
  useEffect(()=>{
    localStorage.removeItem("workoutID")
  },[])
  // const [openBox, setOpenBox] = useState(false);
  // const [isEditOpen, setIsEditOpen] = useState(false);
  // const [selectedID, setSelectedID] = useState();
  // const [sectedCopyData, setSelectCopyData] = useState();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 500);


  const { confirm } = Modal;
  let dispatch = useDispatch();

  let components = useSelector(
    (state) => state.MultiTrainingBlocksSlice.componant
  );
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: COMPONENT, id: data.id, path, mappingID: data?.training_plan_master_mapping_id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);
  const component = components[data.id];
  let isHidden = component?.status == 1 ? false : true;
  

  const deleteAllEventFromSelectedSlot = async (data) => {
    let response = await deleteTrainingPlanMasterMaping(
      data.training_plan_master_mapping_id
    );
    if (
      response.message === "Training plan master mapping deleted successfully"
    ) {
      dispatch(
        getTrainingPlanMasterMappingByTrainingId(data.trainingblock_id)
      );
      setTimeout(() => {
        dispatch(updateColumnsinTrainingData());
        dispatch(updateLastColumnDayInRow());
      }, 300);
    }

  };
  
  // const handleCopy = (id) => {
  //   setSelectCopyData([id])
  //   localStorage.setItem("workoutID", id)
  //   localStorage.removeItem("workoutIDArray")
  //   showInfo("Copied")
  // }
 
  return (
    component !== undefined && (
      isMobileView ? (
        <div className="flex justify-center mb-2">
      <div style={{width: '10px', height: '10px', backgroundColor: 'orange', borderRadius: '50%'}}></div>
      </div>
    ) :
      (
      <div
        style={{
          border: "1px dashed black",
          padding: "10px",
          backgroundColor: "white",
          wordBreak: "break-word"
        }}
      >
        <div ref={ref}
          style={{
            ...style,
            opacity,
          }} className="component draggable">{component?.content}</div>
        <div style={{ display: "flex", justifyContent:"space-between" ,paddingTop:"10px"}}>
          <IoEyeOutline className="" style={{ fontSize: "16px",  color: "#E67E22" }}
            onClick={() => {
              setOpenBox(!openBox);
              setSelectedID(data.workout_id);
            }}
          />
          <FiEdit style={{ fontSize: "14px",  color: "#E67E22" }}
            onClick={() => {
              setIsEditOpen(true);
              setSelectedID(data.workout_id);
              console.log("data.workout_id",data.workout_id);
            }}
          />
          <MdOutlineAutoDelete style={{ fontSize: "14px", color: "#E67E22" }} onClick={() => showDeleteConfirm(data)} />
          <ContentCopyIcon style={{ fontSize: "14px", color: "#E67E22" }} onClick={() => { handleCopy(data.id) }} />
          <ContentPasteIcon style={{ fontSize: "14px", color: "#E67E22" }} 
          onClick={() => { handlePastProgram(path,data)
             console.log("currentPathpaste",path)}} />

        </div>


        {openBox && (
          <ViewTrainingBlock
            selectedValue={selectedID}
            open={openBox}
            onClose={setOpenBox
            }
          />
        )}
        {isEditOpen &&
          <EditWorkout
            trainingBlock={data}
            selectedValue={selectedID}
            open={isEditOpen}
            onClose={setIsEditOpen
            }
          />
        }


      </div>
      )
    )
  );
};
export default Component;