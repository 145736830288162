import React, { useEffect, useMemo, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Button, Grid, Pagination, TextField } from "@mui/material";
import "../../components/AssignCoach/Assign.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Header from "../../components/Header";
import {  deleteUserAdminGoalData, deleteUserGoalData,  getAllUserAdminGoalData, getAllUserGoalData } from "../../API/api-endpoint";
import { IconEdit, IconTrash } from "@tabler/icons";
import { Modal } from "antd";
import CreateUserGoal from "../../components/FeedData/CreateUserGoal";
import CreateUserAdminGoal from "../../components/FeedData/CreateUserAdminGoal";
import moment from "moment";

// let PageSize = 15;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#1e40af",
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const UserAdminGoal = () => {
    const { confirm } = Modal;
    const [reportData, setReportData] = useState()
    const [isOpen, setIsOpen] = useState(true)

    const [editData, setEditData] = useState()
    const [searchTerm, setSearchTerm] = useState('');
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);


    const fetchReport = async () => {
        const response = await getAllUserGoalData()
        setReportData(response.data)
    }
    const getAllGoalsDataa = async () => {
        const response = await getAllUserAdminGoalData()
        console.log("response", response);
        setReportData(response)
    }
    useEffect(() => {
        if (isOpen) {
            fetchReport()
            setCurrentPage(1)
        }
        if (!isOpen) {
            getAllGoalsDataa()
            setCurrentPage(1)
        }
    }, [isOpen])
    const [currentPage, setCurrentPage] = useState(1);
    const filteredList = useMemo(() => {
        return reportData?.filter((row) => {
            // Implement your search logic here
            // For example, if you want to search by a specific property like 'name':
            return row?.goal?.toLowerCase().includes(searchTerm?.toLowerCase());
        });
    }, [reportData, searchTerm]);
    let PageSize = searchTerm  ? filteredList?.length : 15;
    const checkLastPage = useMemo(() => {
        let frstPgae = (currentPage - 1) * PageSize;
        let lastPage = frstPgae + PageSize;
        return filteredList?.slice(frstPgae, lastPage)?.map((row, index) => ({
            ...row,
            // Adjusting index on the first page and adding count from the second page onward
            srID: index + 1 + (currentPage > 1 ? frstPgae : 0),
        }));
    }, [currentPage, reportData,filteredList]);
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const DeleteZones = async (id) => {
        if (isOpen) {
            
            const response = await deleteUserGoalData(id)
            setCurrentPage(1)
            fetchReport()
        }else{
            const response = await deleteUserAdminGoalData(id)
            setCurrentPage(1)
            getAllGoalsDataa()
        }
    }
    const showDeleteConfirm = (id) => {
        confirm({
            title: 'Are you sure delete this goal?',
            icon: <ExclamationCircleFilled />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                DeleteZones(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when the search term changes
    };
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
            <div style={{ marginTop: "100px", padding: "20px" }}>
                <TableContainer component={Paper}>
                    <div style={{ fontSize: "18px", background: "#FFEADC", width: "100%", padding: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid className="mt15" item xs={12} sm={2} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => setShowAssesmentModal(true)}>{isOpen ?"Create User Goal":"Create User Admin Goal"}</Button>
                            </Grid>
                            <Grid className="mt0" item xs={12} sm={2} sx={{ marginTop: "30px" }}>
                                <Button variant="contained" onClick={() => setIsOpen(!isOpen)}>{isOpen ? "Get User Admin Goal" : "Get User Goal"}</Button>
                            </Grid>
                            <Grid  className="mt0 textStart" item xs={12} sm={8} sx={{ textAlign: "end",marginTop: "30px" }}>
                            <TextField type="text" size="small" value={searchTerm} onChange={handleSearchChange} placeholder="Search By Goal Name.." />
                        </Grid>
                        </Grid>
                    </div>
                 {isOpen?(
                    <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                    <Table stickyHeader sx={{ minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Sr ID</StyledTableCell>
                            {/*
                            <StyledTableCell align="left">Activity Name</StyledTableCell>
                        
                        */}
                            <StyledTableCell align="left">Goal Name</StyledTableCell>
                            <StyledTableCell align="left">Comments</StyledTableCell>
                            <StyledTableCell align="left">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {checkLastPage?.length > 0 ? (
                            <>
                                {checkLastPage?.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">
                                            {row?.srID}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row?.goal}

                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                        {row?.comments?row?.comments:"NA"}

                                    </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="flex ">
                                                <span className="px-2 cursor-pointer">
                                                    <IconEdit
                                                        color="blue"
                                                        onClick={() => {
                                                            setShowAssesmentModal(true);
                                                            setEditData(row);
                                                        }}
                                                    />
                                                </span>
                                                <span className="px-2 cursor-pointer">
                                                    <IconTrash
                                                        color="red"
                                                        onClick={() => showDeleteConfirm(row?.id)}
                                                    />
                                                </span>
                                            </div>

                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        ) : (
                            <div className="p-4">No data found</div>
                        )}
                    </TableBody>
                </Table>
                </TableContainer>
                 ):(
                    <TableContainer style={{ maxHeight: 550,scrollbarWidth:"none" }}>
                    <Table stickyHeader sx={{ minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">Sr ID</StyledTableCell>
                            <StyledTableCell align="left">Goal Name</StyledTableCell>
                            <StyledTableCell align="left">Created On</StyledTableCell>
                            <StyledTableCell align="left">Action</StyledTableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {checkLastPage?.length > 0 ? (
                            <>
                                {checkLastPage?.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">
                                            {row?.srID}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {row?.goal}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                        {moment(row?.createdAt).format('DD-MM-YYYY')}
                                    </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="flex ">
                                                <span className="px-2 cursor-pointer">
                                                    <IconEdit
                                                        color="blue"
                                                        onClick={() => {
                                                            setShowAssesmentModal(true);
                                                            setEditData(row);
                                                        }}
                                                    />
                                                </span>
                                                <span className="px-2 cursor-pointer">
                                                    <IconTrash
                                                        color="red"
                                                        onClick={() => showDeleteConfirm(row?.id)}
                                                    />
                                                </span>
                                            </div>

                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        ) : (
                            <div className="p-4">No data found</div>
                        )}
                    </TableBody>
                </Table>
                </TableContainer>
                 )}
                </TableContainer>
                &nbsp;
                <div className="flex justify-end">
                    <Pagination
                        count={Math.ceil(filteredList?.length / PageSize)} // Calculate total number of pages
                        color="primary"
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
            {
                isOpen?
                <CreateUserGoal setEditData={setEditData} editData={editData} fetchReport={fetchReport} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />
                :
                <CreateUserAdminGoal setEditData={setEditData} editData={editData} fetchReport={getAllGoalsDataa} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal} />

            }
        </div>
    )
}

export default UserAdminGoal
