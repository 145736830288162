import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import { Button } from 'antd'
import {  formReviewCoach, getAllActivities } from '../../API/api-endpoint';
import { FormLabel, Grid, MenuItem, OutlinedInput, TextField } from '@mui/material';
import SelfAssesment from './SelfAssesment';
import Swal from 'sweetalert2';
import SlickCarousel from '../SlickCarousel';
import { capitalizeFirstLetter } from '../../utils/Resubale';

const AthleteFormReview = () => {
    const [fetchAllActivities, setFetchActivies] = useState();
    const [showAssesmentModal, setShowAssesmentModal] = useState(false);
    const [formValue, setFormValue] = useState();
    const getAllActivitiesDetails = async (id) => {
        let result = await getAllActivities();
        setFetchActivies(result);
    }
    const validateVideoUrl = (url) => {
        const regex = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
        return regex.test(url);
    }


    useEffect(() => {
        getAllActivitiesDetails()
    }, [])
    const submitForm = async () => {
        if (formValue?.program_id) {
            if (formValue?.videourl) {
                if (validateVideoUrl(formValue.videourl)) {
                    const response = await formReviewCoach(formValue);
                    if (response?.status) {
                        const message = capitalizeFirstLetter(response.message);
                        Swal.fire({
                            title: "Success",
                            text: message,
                            icon: "success",
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: response.message,
                            icon: "error",
                        });
                    }
                } else {
                    Swal.fire({
                        title: "Info",
                        text: "Please add a valid video URL",
                        icon: "info",
                    });
                }
            } else {
                Swal.fire({
                    title: "Info",
                    text: "Please add video URL",
                    icon: "info",
                });
            }
        } else {
            Swal.fire({
                title: "Info",
                text: "Please select activity",
                icon: "info",
            });
        }
    }
   
    return (
        <div>
            <Header />
            <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4 " style={{ marginTop: "100px" }}>

            </div>
            <div className='flex flexColRev'>
            <div className="add-challenges-modal p-8" style={{width:"70%"}}>
                <Button  className="form-btn" type="primary"
                //  onClick={showModal}
                >
                <a href='/saved-review'>
                All Form Reviews Saved
                </a>
                </Button> &nbsp;
                {/* <Button  className="form-btn" type="primary"
                disabled={!formValue?.program_id}
                 onClick={() => setShowAssesmentModal(true)}
                >
                    Self Assessment
                </Button>  */}
               
            <br />
            &nbsp;
            <div className='mt10' style={{marginTop:"100px"}}>

                    <FormLabel >Activity<span className="text-[red]">*</span></FormLabel>
                    <TextField
                        fullWidth
                        size="small"
                        select
                        name="challengeActivity"
                        value={formValue?.program_id || (fetchAllActivities?.length > 0 ? "TextField Activity" : '')}
                        onChange={
                            (event) => {
                                setFormValue({ ...formValue, program_id: event.target.value })
                            }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        input={<OutlinedInput id="select-multiple-language" />}
                    >
                   
                        <MenuItem value={""} disabled>
                            Select Activity
                        </MenuItem>
                        {fetchAllActivities?.map((value, index) => {
                            return (
                                <MenuItem value={value?.id}>
                                    {value?.activity_name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                    <div className='mt-6'>
                    <FormLabel  >Video url<span className="text-[red]">*</span></FormLabel>
                    <TextField
                        fullWidth
                        size="small"
                        name="challengeActivity"
                        value={formValue?.videourl}
                        onChange={
                            (event) => {
                                setFormValue({ ...formValue, videourl: event.target.value })
                            }}
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                        
                        input={<OutlinedInput id="select-multiple-language" />}
                    />
                    </div>
                    
                    <Button  className="form-btn" type="primary"
                        onClick={submitForm}
                    >
                        Notify Coach
                    </Button>
            </div>
            </div>
            <div className="slick-container">
            <SlickCarousel />
          </div>
            </div>
            {showAssesmentModal &&
            <SelfAssesment formValue={formValue} showAssesmentModal={showAssesmentModal} setShowAssesmentModal={setShowAssesmentModal}/>}
        </div>
    )
}

export default AthleteFormReview
