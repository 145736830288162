import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { UserGrowthReportData, getAllPrograms } from "../../API/api-endpoint";
import {
  Button,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import PieChar from "../../components/Chart/PieChar";
import BarChart from "../../components/Chart/BarChart";
import LineBar from "../../components/Chart/LineBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";

const UserGrowthReport = () => {
    const [showWidth, setShowWidth] = useState(window.innerWidth < 500 ? '100%' : '60%');

    const [chartData, setChartData] = useState({});
    const [pieChartData, setPieChartdata] = useState({});
    const [cityChart, setCityChart] = useState({});
    const [countryChart, setCountryChart] = useState({});
    const [lineChartByYear, setlineChartByYear] = useState({});
    const [lineChartByMonth, setlineChartByMonth] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [programList, setProgramList] = useState([]);

  const [formValue, setFormValue] = useState({});
  const fetPrograms = async () => {
    const response = await getAllPrograms();
    setProgramList(response?.rows);
  };
  useEffect(() => {
    fetPrograms();
  }, []);
  const fetchReport = async () => {
    setIsLoading(true);
    if (formValue?.start_date && formValue?.end_date && formValue?.program_id) {
      const response = await UserGrowthReportData(formValue);
      setIsLoading(false);
      // generate bar and pie for results
      console.log("response", response);
      if (response?.data) {
        let data = response?.data;
        const pieChartData = [
          data.newsingups,
          data.onhold,
          data.activeusers,
          data.inactiveusers,
          data.paiduser,
          data.freeusers,
          // ... (other variables for the pie chart)
        ];

        // Labels for the pie chart
        const pieChartLabels = [
          "New Signups",
          "On Hold",
          "Active Users",
          "Inactive Users",
          "Paid Users",
          "Free Users",
          // ... (labels for other variables)
        ];

        // Data for the academy-wise bar chart
        const academyData = data.academywiseusers;
        const academyLabels = Object.keys(academyData);
        const academyValues = Object.values(academyData)?.map(
          (arr) => arr.length
        );

        // Data for city-wise and country-wise bar charts
        const cityWiseData = data.citywise;
        const countryWiseData = data.countrywise;
        const cityLabels = Object.keys(cityWiseData);
        const cityValues = Object.values(cityWiseData)?.map(
          (arr) => arr.length
        );
        const countryLabels = Object.keys(countryWiseData);
        const countryValues = Object.values(countryWiseData)?.map(
          (arr) => arr.length
        );
        const groupbyyearsData = data.groupbyyears;
        console.log(
          "Object.entries(groupbyyearsData)",
          Object.entries(groupbyyearsData),
          groupbyyearsData
        );

        const groupbyyeardatalengthonly = Object.entries(
          data?.groupbyyears
        )?.map((e) => e[1]?.length);
        const groupbymonthsData = data.groupbymonths;

        setPieChartdata({
          labels: pieChartLabels,
          datasets: [
            {
              data: pieChartData,
              backgroundColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderWidth: 1,
            },
          ],
        });
        setChartData({
          labels: academyLabels,
          datasets: [
            {
              label: "Number of Users",
              data: academyValues,
              backgroundColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderWidth: 1,
            },
          ],
        });
        setCountryChart({
          labels: countryLabels,
          datasets: [
            {
              label: "Number of Users per Country",
              data: countryValues,
              backgroundColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderWidth: 1,
            },
          ],
        });
        setCityChart({
          labels: cityLabels,
          datasets: [
            {
              label: "Number of Users per city",
              data: cityValues,
              backgroundColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderColor: [
                "rgba(36, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(128, 0, 128, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(125, 159, 64, 0.2)",
                // Add more colors as needed...
              ],
              borderWidth: 1,
            },
          ],
        });
        setlineChartByYear({
          labels: Object.entries(groupbyyearsData),
          datasets: [
            {
              label: "No of users",
              data: groupbyyeardatalengthonly,
              borderColor: "blue",
              borderWidth: 1,
              lineTension: 0.1,
            },
          ],
        });

        setlineChartByMonth({
          labels: [
            "Jan",
            "Feb",
            "March",
            "April",
            "May",
            "Jun",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ], // Update with appropriate month labels
          datasets: [
            {
              label: "Users in Month",
              data: [
                groupbymonthsData["1"]?.length,
                groupbymonthsData["2"]?.length,
                groupbymonthsData["3"]?.length,
                groupbymonthsData["4"]?.length,
                groupbymonthsData["5"]?.length,
                groupbymonthsData["6"]?.length,
                groupbymonthsData["7"]?.length,
                groupbymonthsData["8"]?.length,
                groupbymonthsData["9"]?.length,
                groupbymonthsData["10"]?.length,
                groupbymonthsData["11"]?.length,
                groupbymonthsData["12"]?.length,
              ],
              borderColor: "red",
              borderWidth: 1,
            },
          ],
        });
      }
    } else {
      setIsLoading(false);
      Swal.fire({
        title: "Error",
        text: "Please Fill All Fields!",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setShowWidth(window.innerWidth < 500 ? '100%' : '60%');
    };
  
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-4"></div>
      <div
        style={{
          fontSize: "18px",
          background: "#FFEADC",
          width: "100%",
          padding: "10px",
        }}
      >
        <div style={{ marginTop: "100px" }}>
          <h1 className="mb-2" style={{ fontWeight: "700", fontSize: "20px" }}>
            User Growth Report
          </h1>
          <Grid className="dateWrap" container spacing={2}>
            <Grid item xs={12} sm={2}>
              <FormLabel>
                <b>Start Date</b>
              </FormLabel>

              <DatePicker
                selected={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    start_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                onKeyDown={(e) => e.preventDefault()}
                maxDate={
                  formValue?.end_date
                    ? new Date(formValue?.end_date)
                    : new Date()
                }
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid className="dateWrap" item xs={12} sm={2}>
              <FormLabel>
                <b>End Date</b>
              </FormLabel>
              <DatePicker
                selected={
                  formValue?.end_date ? new Date(formValue?.end_date) : null
                }
                onChange={(date) => {
                  setFormValue({
                    ...formValue,
                    end_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                dateFormat="dd-MM-yyyy"
                placeholderText="DD-MM-YYYY"
                onKeyDown={(e) => e.preventDefault()}
                minDate={
                  formValue?.start_date ? new Date(formValue?.start_date) : null
                }
                maxDate={new Date()}
                customInput={
                  <TextField
                    fullWidth
                    placeholder="Added Date and Time"
                    size="small"
                    type="text"
                    name="added_datetime"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormLabel>
                <b>Select Programs</b>
              </FormLabel>
              <TextField
                fullWidth
                size="small"
                select
                name="program_id"
                value={formValue?.program_id}
                onChange={(e) => {
                  setFormValue({ ...formValue, program_id: e.target.value });
                }}
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
                input={<OutlinedInput id="select-multiple-language" />}
              >
                <MenuItem value={""} disabled>
                  Select Program
                </MenuItem>
                {programList?.map((value, index) => {
                  return (
                    <MenuItem value={value?.program_id}>
                      {value?.program_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid className="mt10" item xs={12} sm={2} sx={{ marginTop: "26px" }}>
              <Button
                className="report-btn"
                variant="contained"
                onClick={fetchReport}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          {/*

<Pie data={chartData} />

*/}
        </div>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {pieChartData?.datasets?.length > 0 && (
            <Grid container spacing={2} className="p-6">
              <Grid item xs={12} sm={6}>
                <PieChar
                  chartData={pieChartData}
                  showWidth={"60%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BarChart
                  chartData={chartData}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BarChart
                  chartData={cityChart}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <BarChart
                  chartData={countryChart}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LineBar
                  chartData={lineChartByYear}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LineBar
                  chartData={lineChartByMonth}
                  showWidth={"80%"}
                  displayLegend={false}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default UserGrowthReport;
