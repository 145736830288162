import React from 'react'
import Sidebar from './Sidebar'
import { Box } from '@mui/system'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useState } from 'react';
import { useFormik } from 'formik';
import { Button, CardContent, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactQuill from "react-quill";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { DisconnectStrava, getAllCities, getAllCountry, getAllState, getDayoptionValues, getPrograms, getStravaURL, updatePersonlise } from '../../API/api-endpoint';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { removeNullValues } from '../../utils/Resubale';
import { showError } from '../Messages';
import useMediaQuery from '@mui/material/useMediaQuery';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import BackupIcon from '@mui/icons-material/Backup';
import { useRef } from 'react';

let daysInWeek = ["2", "3", "4", "5"]

const ProfileMobile = ({fetchGoals, setfetchDaysoptionList, getDaysoptionList, setFormGoalValue, formGoalValue, assignedCocahId, formik, setIsEditProfile, handleOnSubmitForm, isEditProfile, usersDetails, getUsersProfile, setUserDetails }) => {
  // getUsersProfile
  const [CountryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isOpenGoal, setIsOpenGoal] = useState(false)
  const [isOpenChangeGoal, setIsOpenChangeGoal] = useState(false)

  const [cityList, setCityList] = useState([]);
  const calculateAge = () => {
    const birthDate = moment(formik?.values?.dateofbirth, 'YYYY-MM-DD');
    const currentDate = moment();
    const age = currentDate.diff(birthDate, 'years');
    return age;
  };
  console.log("formik", fetchGoals,formik?.values, formik?.errors);
  const [stravaURL, setstravaURL] = useState()
  const userId = localStorage.getItem('userId')
  const matches = useMediaQuery('(max-width:500px)');

  const handleCountryChange = async (event) => {
    const countryId = event.target.value;
    formik.setFieldValue("country", countryId)

    // Fetch states based on the selected country ID
    fetchState(countryId)
  };
  const fetchState = async (countryId) => {
    const response = await getAllState(countryId);
    setStateList(response);
  }
  const handleStateChange = async (event) => {
    const stateID = event.target.value;
    formik.setFieldValue("state", stateID)

    fetchCity(formik, stateID)
    // Fetch states based on the selected country ID

  };

  const fetchCity = async (formik, stateID) => {
    const response = await getAllCities(formik?.values?.country, stateID);
    setCityList(response);
  }



  const getCountry = async () => {
    let response = await getAllCountry();
    setCountryList(response);
  };

  const getURLByLibraryId = async () => {
    // console.log("result",userId);

    if (assignedCocahId !== null) {
      let result = await getStravaURL(assignedCocahId);
      setstravaURL(result)
      // setWorkouts(result);

    }

  };
  useEffect(() => {
    if (assignedCocahId) {

      getURLByLibraryId();
    }
    if (formGoalValue?.daysinweek) {
      fetchDaysOptuon(formGoalValue?.daysinweek);
    }
    getCountry();
    if (formik?.values?.country) {
      fetchState(formik?.values?.country)

    }
    if (formik?.values?.state) {
      fetchCity(formik, formik.values.state)

    }
  }, [formGoalValue?.daysinweek, assignedCocahId, formik?.values?.country, formik?.values?.state]);
  const fetchDaysOptuon = async (week, activity) => {
    const response = await getDayoptionValues(
      week ? week : formGoalValue?.daysinweek,
      activity ? activity : formGoalValue?.activities
    );
    console.log("response", response);
    if (response.length > 0) {
      setfetchDaysoptionList(response);
    } else {
      setFormGoalValue({ ...formGoalValue, daysoption: "" })
    }
  };
  const handleDisconnect = async () => {
    const disconnectResponse = await DisconnectStrava(assignedCocahId)
    if (disconnectResponse?.status) {
      Swal.fire({
        title: "Sucess",
        text: disconnectResponse.message,
        icon: "success",
      });
    let coachProgramID = localStorage.getItem("programID");

      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data)=>data.active==1)
      let coachID = filter[0]?.program_id?filter[0]?.program_id:coachProgramID;
      console.log("actProgram",coachID,coachProgramID); 
      if (coachID) {
      const response = await getUsersProfile(coachID,assignedCocahId)
      let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
      let destructer = removeNullValues(data)
      formik.setValues(destructer)
      setUserDetails(response)
    }}
  }
  const handleConnect = () => {
    setTimeout(async () => {
    let coachProgramID = localStorage.getItem("programID");

      const actProgram = await getPrograms(assignedCocahId);
      let filter = actProgram?.filter((data)=>data.active==1)
      let coachID = filter[0]?.program_id?filter[0]?.program_id:coachProgramID;
      console.log("actProgram",coachID,coachProgramID); 
      if (coachID) {
      const response = await getUsersProfile(coachID,assignedCocahId)
      let data = { ...response?.existingUserContacts, ...response?.personalization_profile, ...response?.user }
      formik.setValues(data)
      setUserDetails(response)}
    }, 3000);
  }
  const updateGoals = async () => {
    if (formGoalValue?.goal && formGoalValue?.daysinweek && formGoalValue?.daysoption) {
      let response = await updatePersonlise(formGoalValue, assignedCocahId); //its valdiate terms and conditions
      if (response?.status) {
        Swal.fire({
          title: "Success",
          text: response?.message,
          icon: "success",
        });
        setIsOpenGoal(false)
        setIsOpenChangeGoal(false)
        formik.setFieldValue("daysoption", formGoalValue.daysoption)
        formik.setFieldValue("daysinweek", formGoalValue.daysinweek)
        setFormGoalValue({
          ...formGoalValue, daysoption: "",
        })

      }
      // setIsOpenGoal
    } else {
      showError("Please fill all details")
    }
  }
  const handleChangeGoal = (event, newAlignment) => {
    setFormGoalValue({
      ...formGoalValue,
      daysinweek: event.target.value,
      goal: formik?.values?.goal,

    })

  };
  const control = {
    value: formGoalValue?.daysinweek,
    onChange: handleChangeGoal,
    exclusive: true,
  };
  const fileInputRef = useRef(null);
  const tShirtSizes = ["XS", "S", "M", "L", "XL", "XXL"];

  console.log("usersDetails", usersDetails);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("profile_image", file)

    if (file) {
      handleOnSubmitForm(file)
    }
  };
  console.log("profile_image", formik?.values);

  // bg-[#f1f5f9]   for background
  const calculateBMI = (height, weight) => {
    console.log("snbanshe", height, weight);
    if (height && weight, weight) {
      // Convert height to meters (since it's in cm)
      const heightInMeters = height / 100;

      // Calculate BMI
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2); // Calculate BMI rounded to 2 decimal places
      return bmi;
    }

    return 0; // Return null if height or weight is missing
  };
  // const bmi = formik.values?.height&&calculateBMI();
  // console.log('BMI:', bmi); // Output the calculated BMI
  return (
    <div  style={matches ? {flexDirection : "column"} : {}} className='flex bg-[#f1f1f1] text-[18px]'>

<div className={`${( matches) ? "w-[100vw]" : "w-[25vw]"}`}>
<div className='h-[100%] bg-[#f1f1f1]  text-[black] '>
        <div className='flex  justify-between border-b-2 p-4 mr-4 ml-2'>
          <div>
            Profile
          </div>
          <div>
            {isEditProfile ? (
              <div>
                <Button variant='contained' style={{backgroundColor:"white",border:"1px solid white"}} onClick={() => handleOnSubmitForm()}>Save</Button>
                <CloseIcon className='cursor-pointer justifyEnd' onClick={() => setIsEditProfile(!isEditProfile)} />
              </div>
            ) : (

              <EditIcon className='cursor-pointer' onClick={() => setIsEditProfile(!isEditProfile)} />
            )}
          </div>
        </div>

        <div className='flex  justify-center  space-x-8 p-4 relative' >
          <img
            src={formik?.values?.profile ? `${URL}/static/public/userimages/${formik?.values?.profile}` : 'https://i.ibb.co/5xCF7vx/u-https-spng-pngfind-com-pngs-s-610-6104451-image-placeholder-png-user-profile-placeholder-image-png.jpg'}

            alt="..."
            className="w-44 h-44 rounded-full border-2 ml55 rem9"
          // onClick={handleProfile}
          />
          <div className='relative  right-20 '>
            <label htmlFor="fileInput">
              <BackupIcon className='cursor-pointer' />
            </label>
            <input
              id="fileInput"
              type="file"
              accept="image/*,.jfif"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />

          </div>

        </div>
        <Box sx={{margin: "0 auto"}} className=" w-[90%] h-[36%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
        <div className='flex justify-between p-4 items-center'>
          <p>
            Height (Cms)
          </p>
          <p>
            {isEditProfile ? (
              <TextField

                id="filled-basic" variant="filled"
                placeholder="Height"
                InputProps={{
                  style: { backgroundColor:"#FFEADC",maxWidth:"130px" ,alignSelf:"end"} // Text color will be white
                }}
                type="number"
                name="height"
                value={formik?.values?.height}
                onChange={formik.handleChange}
              />
            ) : (
              <>
                {formik?.values?.height ? formik?.values?.height : "NA"}
              </>
            )}

          </p>
        </div>
        <div className='flex justify-between p-4 items-center'>
          <p>
            Weight (Kgs)
          </p>
          {isEditProfile ? (
            <TextField

              id="filled-basic" variant="filled"
              placeholder="Weight"
              InputProps={{
                style: {backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end" }, // Text color will be white
              }}
              type="number"
              name="weight"
              value={formik?.values?.weight}
              onChange={formik.handleChange}
            />
          ) : (
            <>
              {formik?.values?.weight ? formik?.values?.weight : "NA"}
            </>
          )}

        </div>

        <div className='flex justify-between p-4 items-center'>
          <p>
            Waist (Inches)
          </p>
          {isEditProfile ? (
            <TextField

              id="filled-basic" variant="filled"
              placeholder="Waist"
              InputProps={{
                style: {backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end" }, // Text color will be white
              }}
              type="number"
              name="waist"
              value={formik?.values?.waist}
              onChange={formik.handleChange}
            />
          ) : (
            <>
              {formik?.values?.waist ? formik?.values?.waist : "NA"}
            </>
          )}

        </div>
        <div className='flex justify-between p-4  items-center'>
          <p>
            BMI
          </p>
          <>
            {formik?.values?.height ? calculateBMI(formik?.values?.height, formik?.values?.weight) :
              calculateBMI(formik?.values?.height, formik?.values?.weight)}
          </>

        </div>
        <div className='flex justify-between p-4 items-center'>
          <p>
            T-Shirt Size
          </p>
          {/*  */}
          {isEditProfile ? (
             <TextField
             id="filled-basic"
             variant="filled"
             select
             fullWidth
             InputProps={{
               style: {backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end" }, // Text color will be white
             }}
             name="tshirtsize"
             value={formik.values.tshirtsize}
             onChange={formik.handleChange} 
           >
             {tShirtSizes?.map((size) => (
               <MenuItem key={size} value={size}>
                 {size}
               </MenuItem>
             ))}
           </TextField>
          ) : (
            <>
              {formik?.values?.tshirtsize ? formik?.values?.tshirtsize : "NA"}
            </>
          )}

        </div>
        <div className='flex justify-between p-4  items-center'>
          <p>
            Short Size
          </p>
          {isEditProfile ? (
            <TextField

            id="filled-basic"
            variant="filled"
            select
            fullWidth
            InputProps={{
              style: {backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end" }, // Text color will be white
            }}
              name="shortsize"
              value={formik?.values?.shortsize}
              onChange={formik.handleChange}
            >
            {tShirtSizes?.map((size) => (
               <MenuItem key={size} value={size}>
                 {size}
               </MenuItem>
             ))}
             </TextField>
          ) : (
            <>
              {formik?.values?.shortsize ? formik?.values?.shortsize : "NA"}
            </>
          )}

        </div>
        <div className='flex  justify-between p-4 items-center'>
          <p>
            GST
          </p>
          {isEditProfile ? (
            <TextField

              id="filled-basic" variant="filled"
              placeholder="GST"
              InputProps={{
                style: { backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end"  }, // Text color will be white
              }}
              type="text"
              name="gst"
              value={formik?.values?.gst}
              onChange={(event) => {
                const { value } = event.target;
                const isValid = /^[a-zA-Z0-9]*$/.test(value);
                if (isValid) {
                  formik.handleChange(event);
                }
              }}
            />
          ) : (
            <>
              {formik?.values?.gst ? formik?.values?.gst : "NA"}
            </>
          )}

        </div>
        <div className='flex justify-between p-4 items-center'>
          <p>
            Process
          </p>
          {isEditProfile ? (
            <Select
            fullWidth
            style={{backgroundColor:"#FFEADC",maxWidth:"130px",alignSelf:"end" }}
                          placeholder="123"
                          name="process"
                          value={formik.values.process?formik.values.process:""}
                          onChange={formik.handleChange}
                          id="form-layouts-separator-select"
                          labelId="form-layouts-separator-select-label"
                          input={
                            <OutlinedInput id="select-multiple-language" />
                          }
                        >
                          <MenuItem value={"a"}>Automatic</MenuItem>
                          <MenuItem value={"m"}>Manual</MenuItem>

                        </Select>
          ) : (
            <>
            {formik?.values?.process=="m"?"Manual":formik?.values?.process=="a"?"Automatic":"NA"}
            </>
          )}

        </div>
        </Box>

        {/* <div>
      <IconX size={24} color='dodgerblue' />
    </div> */}
      </div>
      </div>
      <div className={`flex w-[60vw]  mt20 ${matches ? "flex-col" : ""}`}>
      <div  className={` justify-center items-center ${matches ? "w-[100vw]" : "w-[35vw]"}`} style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        paddingBottom: "20px",
        
      }}>
        <Box className=" w-[90%] h-[36%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4" >
          <b>{formik?.values?.firstname} {formik?.values?.lastname}</b>
          <p>{formik?.values?.gender}</p>
          <p><strong>{`${calculateAge()} Years`}</strong> Date Of Birth <strong>{moment(formik?.values?.dateofbirth).format("DD-MM-YYYY")}</strong></p>


        </Box>
        {!isEditProfile &&

          <Box className=" w-[90%] h-[25%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4" >
            <b>Address</b>
            <p>{formik?.values?.address1 ? formik?.values?.address1 : "NA"}</p>
          </Box>
        }
       
        {!isEditProfile && formik?.values?.daysoption &&

          <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4" >
            <b>Schedule Details</b><br />
            <p><b>Program Name </b>  {formik?.values?.activity}</p>
            {formik?.values?.goal &&
              <p><b>Goal</b>  {formik?.values?.goal}</p>
            }
            <p><b>Days in week</b>  {formGoalValue?.daysinweek}</p>

            {isOpenGoal &&
              <>
                <ToggleButtonGroup className='w-[45vw]' size="small" value={formGoalValue?.daysinweek} {...control} aria-label="Small sizes">
                  {fetchGoals?.availibledaysoption?.map((ele) => {
                    return (
                      <ToggleButton
                        style={{
                          backgroundColor: ele.rundays==formGoalValue.daysinweek ? '#2563eb' : 'inherit',
                            color: ele.rundays==formGoalValue.daysinweek ? 'white' : 'inherit',
                            width: "50px"
                        }}
                        value={ele?.rundays} key={ele?.rundays}>
                        {ele?.rundays}
                      </ToggleButton>
                    )
                  })}
                </ToggleButtonGroup>
              </>
            }
            {isOpenGoal ? (
              <div >
                {getDaysoptionList?.length > 0 ?
                  <div className={getDaysoptionList?.length > 0 && "flex w-[100%] overflow-scroll"}>
                    {getDaysoptionList?.map((ele) => {
                      return (
                        <div
                        className=' width150 font14'
                          style={
                            {
                              minWidth: "200px",
                              border: "1px solid gray",
                              margin: "10px",
                              borderRadius: "7px",
                              padding: "10px 0px 10px 10px",
                              boxShadow:
                                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }
                          }
                        >
                          <div>
                            <div className="flex justify-between items-center">
                              <div >Monday </div>
                              {isOpenGoal &&
                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  defaultValue="female"
                                  name="radio-buttons-group"
                                  value={formGoalValue.daysoption}

                                  onChange={(e) =>

                                    setFormGoalValue({
                                      ...formGoalValue,
                                      daysoption: e.target.value,
                                    })
                                  }
                                >
                                  <FormControlLabel
                                    value={ele?.options}
                                    control={<Radio />}
                                  />
                                </RadioGroup>
                              }

                            </div>
                            <div>{ele?.mondayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Tuesday -</div>
                            <div>{ele?.tuesdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div  className='mr-2'>Wednesday -</div>
                            <div>{ele?.wednesdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Thursday -</div>
                            <div>{ele?.thursdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Friday =</div>
                            <div>{ele?.fridayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Saturday -</div>
                            <div>{ele?.saturdayworkout}</div>
                          </div>
                          &nbsp;
                          <div className=" mr-4">
                            <div className='mr-2'>Sunday -</div>
                            <div>{ele?.sundayworkout}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  : <div className='p-4 text-lg font-semibold'>No Options Found</div>}
              </div>
            ) : (
              <div className={getDaysoptionList?.length > 0 && "flex w-[100%] overflow-scroll"}>
                {getDaysoptionList?.filter((ele) => {
                  return ele.options == formik?.values?.daysoption;
                }).map((ele) => {
                  return (
                    <div
                    className='width150 font14'
                      style={
                        {
                          minWidth: "200px",
                          border: "1px solid gray",
                          margin: "10px",
                          borderRadius: "7px",
                          padding: "10px 0px 10px 10px",
                          boxShadow:
                            "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                        }
                      }
                    >
                      <div>
                        <div className="flex justify-between items-center">
                          <div>Monday </div>
                          {isOpenGoal &&
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              value={formGoalValue.daysoption}

                              onChange={(e) =>

                                setFormGoalValue({
                                  ...formGoalValue,
                                  daysoption: e.target.value,

                                })
                              }
                            >
                              <FormControlLabel
                                value={ele?.options}
                                control={<Radio />}
                              />
                            </RadioGroup>
                          }

                        </div>
                        <div>{ele?.mondayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Tuesday </div>
                        <div>{ele?.tuesdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Wednesday </div>
                        <div>{ele?.wednesdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Thursday </div>
                        <div>{ele?.thursdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Friday </div>
                        <div>{ele?.fridayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Saturday </div>
                        <div>{ele?.saturdayworkout}</div>
                      </div>
                      &nbsp;
                      <div className=" mr-4">
                        <div className='mr-2'>Sunday </div>
                        <div>{ele?.sundayworkout}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}



            <div className='mt10'  style={{ display: "flex", justifyContent: "end" }}>
              {isOpenGoal ?
                <Button style={{color:"white"}} variant='contained' disabled={!formGoalValue?.daysoption} onClick={updateGoals}>Submit</Button>
                :
                <Button variant='contained' onClick={() => setIsOpenGoal(true)}>Change Schedule</Button>
              }

            </div>
          </Box>
        }

        {isEditProfile &&

          <Box className=" w-[90%]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
            <b>Personal Information</b>
            <CardContent>
              <Grid container>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">First Name</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="First Name"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      type="text"
                      name="firstname"
                      value={formik?.values?.firstname}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">Last Name</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      type="text"
                      name="lastname"
                      value={formik?.values?.lastname}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label variant="h6">DOB</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="date"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      error={formik.errors?.dateofbirth && formik.errors?.dateofbirth}
                        helperText={
                          formik.errors.dateofbirth && formik.errors.dateofbirth
                      }
                      name="dateofbirth"
                      value={formik?.values?.dateofbirth}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <label variant="h6">Email</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      disabled
                      name="email"
                      value={formik?.values?.email}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Qualification</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Workout Title"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      name="qualification"
                      value={formik?.values?.qualification}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Gender</label>
                    <Select
                      fullWidth
                      id="filled-basic" variant="filled"
                      // style={{color:"white"}}
                      style= {{backgroundColor:"#FFEADC"} }
                      placeholder="123"
                      name="gender"
                      value={formik.values.gender ? formik.values.gender : ""}
                      onChange={formik.handleChange}
                      // id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={
                        <OutlinedInput id="select-multiple-language" />
                      }
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>

                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Occupation</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Occupation"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      name="occupation"
                      value={formik?.values?.occupation}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Organization</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Organization"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      name="organization"
                      value={formik?.values?.organization}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                  </Grid>
                  <Grid item xs={12} sm={12} >
                    <b>Address Information</b>
                  </Grid>


                  <Grid   className='profileAutocomplete' item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{ marginBottom:"15px", width: "100%" }}>
                      {/* <InputLabel id="demo-simple-select-standard-label">Country</InputLabel> */}
                      <Select
                      fullWidth
                      variant="filled"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.country}
                        onChange={handleCountryChange}
                        style= {{backgroundColor:"#FFEADC"} }
                        label="Age"
                      >
                        {CountryList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid   className='profileAutocomplete' item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{ marginBottom:"15px", width: "100%" }}>
                      {/* <InputLabel id="demo-simple-select-standard-label">State</InputLabel> */}
                      <Select
                      fullWidth
                      variant="filled"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.state}
                        onChange={handleStateChange}
                        style= {{backgroundColor:"#FFEADC"} }
                        label="Age"
                      >
                        {stateList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid   className='profileAutocomplete' item xs={12} sm={4} >
                    <FormControl variant="standard" sx={{  width: "100%" }}>
                      {/* <InputLabel id="demo-simple-select-standard-label">City</InputLabel> */}
                      <Select
                      fullWidth
                       variant="filled"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={formik?.values?.city}
                        style= {{backgroundColor:"#FFEADC"} }
                        onChange={(e) => formik.setFieldValue("city", e.target.value)}
                      >
                        {cityList?.map((option) => (
                          <MenuItem key={option.id} value={option?.id}>
                            {option?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Address</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="Address"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      name="address1"
                      value={formik?.values?.address1}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <label variant="h6">Address2</label>
                    <TextField
                      fullWidth
                      id="filled-basic" variant="filled"
                      placeholder="address2"
                      type="text"
                      InputProps={{
                        style: {backgroundColor:"#FFEADC"}, // Text color will be white
                      }}
                      name="address2"
                      value={formik?.values?.address2}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Box>
        }
        {/*
             {isEditProfile &&
                    <Box className=" w-[90%] h-[40vh]  bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
                        <b className='mb-2'>Achievements</b>
                        <ReactQuill style={{ height: "50%" }}
                            value={formik?.values?.workout_description}
                            name="workout_description"
                            onChange={(value) => formik.setFieldValue("workout_description", value)}
                            modules={{
                                toolbar: [
                                    [{ header: "1" }, { header: "2" }, { font: [] }],
                                    [{ size: [] }],
                                    ["bold", "italic", "underline", "strike", "blockquote"],
                                    [
                                        { list: "ordered" },
                                        { list: "bullet" },
                                        { indent: "-1" },
                                        { indent: "+1" },
                                    ],
                                    ["link", "image", "video"],
                                    ["clean"],
                                ],
                            }}
                        />


                    </Box>
                }

            */}

      </div>
      
      <div className={`p-4 ${matches ? "w-[100vw]" : "w-[25vw]"}`}>
        <Box className=" w-[100%] min-h-[40%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4">
          <div className='mb-5'><b>Contact</b></div>
          <p className='flexRow itemsCenter'><PhoneIcon className='text-[#E67E22] mb-2 mt-2  ' style={{color:"#E67E22"}} />&nbsp; <strong>

            {isEditProfile ? (
              <TextField
              fullWidth
                id="filled-basic" variant="filled"
                placeholder="Contact"
                type="text"
                name="mobile"
                value={formik?.values?.mobile}
                onChange={formik.handleChange}
                InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
              />
            ) : (
              <>{formik?.values?.mobile ? formik?.values?.mobile : "NA"}</>
            )}
          </strong></p> &nbsp;


          <p className='flexRow itemsCenter'><PhoneIcon className='text-[blue] mb-2 mt-2' style={{color:"#E67E22"}} />&nbsp;<strong>

            {isEditProfile ? (
              <TextField
              fullWidth
                id="filled-basic" variant="filled"
                placeholder="Emergency Contact"
                type="text"
                name="econtact"
                value={formik?.values?.econtact}
                onChange={formik.handleChange}
                InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
              />
            ) : (
              <>  Emergency Contact {formik?.values?.econtact? formik?.values?.econtact: "NA"}</>
            )}
          </strong></p>
          &nbsp;
          {isEditProfile ? (
            <p className='flexRow itemsCenter'><EmailIcon className='text-[blue]' style={{color:"#E67E22"}}  /> &nbsp;<strong>
              <TextField
              fullWidth
                id="filled-basic" variant="filled"
                placeholder="Contact"
                type="text"
                name="email"
                disabled
                value={formik?.values?.email}
                onChange={formik.handleChange}
                InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
              />
            </strong></p>

          ) : (

            <p className='flexRow itemsCenter' style={{ wordBreak: 'break-word' }}><EmailIcon className='text-[blue]'  style={{color:"#E67E22"}} /> &nbsp;<strong>{formik?.values?.email}</strong></p>
          )}
          &nbsp;
          {isEditProfile ? (
            <p className='flex rounded-md  itemsCenter'>

              <img
                src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                alt="..."
                className="w-6 h-6  border-2 cursor-pointer"
              />
              &nbsp;<strong>

                <TextField
                fullWidth
                  id="filled-basic" variant="filled"
                  placeholder="Strava Name"
                  type="text"
                  name="strava"
                  value={formik?.values?.strava}
                  onChange={formik.handleChange}
                  InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
                />
              </strong></p>
          ) : (
            <p className='flex rounded-md  itemsCenter'  style={{ wordBreak: 'break-word' }}>

              <img
                src="https://play-lh.googleusercontent.com/j-ZV144PlVuTVsLuBzIKyEw9CbFnmWw9ku2NJ1ef0gZJh-iiIN1nrNPmAtvgAteyDqU"
                alt="..."
                className="w-6 h-6  border-2 cursor-pointer"
              />
              &nbsp;<strong>
                Strava Name: {formik?.values?.strava_username ? formik?.values?.strava_username : "NA"}
              </strong></p>

          )}
          &nbsp;
          <p className='flex  itemsCenter'  style={{ wordBreak: 'break-word' }}><InstagramIcon className='text-[#E4405F]   ' />&nbsp; <strong>

            {isEditProfile ? (
              <TextField
              fullWidth
                id="filled-basic" variant="filled"
                placeholder="Insta"
                type="text"
                name="insta"
                value={formik?.values?.insta}
                onChange={formik.handleChange}
                InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
              />
            ) : (
              <>{usersDetails?.usercontact?.insta ? usersDetails?.usercontact?.insta : "NA"}</>
            )}
          </strong></p>
          &nbsp;
          <p className='flex  itemsCenter'  style={{ wordBreak: 'break-word' }}><FacebookIcon className='text-[blue] mb-2 mt-2  ' />&nbsp; <strong>

            {isEditProfile ? (
              <TextField
              fullWidth
                id="filled-basic" variant="filled"
                placeholder="Facebook"
                type="text"
                name="facebook"
                value={formik?.values?.facebook}
                onChange={formik.handleChange}
                InputProps={{
                    style: {backgroundColor:"#FFEADC",marginLeft: "10px"}, // Text color will be white
                  }}
              />
            ) : (
              <>{usersDetails?.usercontact?.facebook ? usersDetails?.usercontact?.facebook : "NA"}</>
            )}
          </strong></p>
        </Box>
        {!isEditProfile &&

<Box className=" w-[100%] h-[10%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4">
  <b>Emergency Contact</b>
  <p>{formik?.values?.contact ? formik?.values?.contact : "NA"}</p>

</Box>
}
        <Box className=" w-[100%] h-[30%] bg-[#fffdfd] rounded-xl shadow-lg shadow-#fffdfd-500 p-4 mt-4"  sx={matches ? {height:"25%"} : {}}>
          <div> <b>Connect <InfoOutlinedIcon /> </b></div>
          <p>Strava&nbsp;</p>
          <p><strong>Status:</strong> &nbsp;<strong>{formik?.values?.strava_id ? "Connected" : "Disconnected"}</strong></p>

          <Button variant='contained'>
            {formik?.values?.strava_id ? <a onClick={() => handleDisconnect()}>Disconnect</a> :

              <a href={stravaURL?.url} target='_blank' onClick={() => handleConnect()}>Connect with strava</a>
            }
          </Button>
        </Box>
      </div>
      </div>
    </div>
  )
}

export default ProfileMobile
