import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Drawer,
  TextField,
  CardHeader,
  Grid,
  Divider,
  IconButton,
  Box,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import AddIcon from "@mui/icons-material/Add";
import "react-quill/dist/quill.snow.css";
import { styled } from "@mui/material/styles";
import SellIcon from "@mui/icons-material/Sell";
import CheckIcon from "@mui/icons-material/Check";
import {
  createWorkoutLibrary1,
  getAllActivities,
  updateWorkoutLibrary,
} from "../../../../API/api-endpoint";
import Swal from "sweetalert2";
import useMediaQuery from '@mui/material/useMediaQuery';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const wlakJogTags = [
  "Running",
  "Basic Fitness",
  "Walking",
  "Beginner",
  "Swimming",
  "ETC",
  "Playing",
];

// { title: "Running" },
// { title: "Basic Fitness" },
// { title: "Walking" },
// { title: "Beginner" },
// { title: "Swimming" },
// { title: "ETC" },
// { title: "Playing" },

const WorkoutLibraryDrawer = (props) => {
  const {
    workoutLibraryDrawerState,
    setWorkoutLibraryDrawerState,
    formData,
    setmasterData,
    masterData,
    setFilteredData,
    setSelectedLibraryItem,
    selectedLibraryItem,setFetchActivies
    ,fetchAllActivities,
    getLibraryList
  } = props;

  const [formValue, setFormValue] = useState({
    "workout-library-name": undefined,
    tags: [],
  });

  const matches = useMediaQuery('(max-width:500px)');


console.log("selectedLibraryItem",formValue,selectedLibraryItem);
  useEffect(() => {
    const inputString = selectedLibraryItem?.tags;
    console.log("inputString",inputString);
    const resultArray =
      inputString !== undefined ? inputString?.split(",") : inputString;
    let newObejct = {
      ...selectedLibraryItem,
      tags: resultArray,
      id:selectedLibraryItem?.id,
      ["workout-library-name"]:selectedLibraryItem?.workout_library_name

    };
    setFormValue(selectedLibraryItem?.id === undefined ? {} : newObejct);
  }, [selectedLibraryItem?.tags]);

  const handleChange = (event, name) => {
    const newName = name;
    const value = event.target.value;
    setFormValue((values) => ({ ...values, [newName]: value }));
  };

  const drawerStateChange = (state) => {
    setWorkoutLibraryDrawerState(state);
    setFormValue({});
  };
console.log("formValue",formValue);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let newTagsArray = [];
    const uniqueArray = [...new Set(formValue.tags)];
    console.log(uniqueArray);
    //this logic for changing array to comma saprated string
    uniqueArray.map((tag) => {
      return newTagsArray.push(tag);
    });

    const commaSeparatedString = newTagsArray.join(",");
    console.log(commaSeparatedString);
    if (formValue["workout-library-name"] && formValue?.tags?.length>0) {
      
    let Result;
    console.log("formValue",formValue, commaSeparatedString);

    if (formValue?.isEditForm) {

      Result = await updateWorkoutLibrary(
        formValue["workout-library-name"],
        commaSeparatedString,
        formValue["id"]
      );
    } else {
      Result = await createWorkoutLibrary1(
        formValue["workout-library-name"],
        commaSeparatedString
      );
    }
    if (Result.status ) {
      getLibraryList()
      drawerStateChange(false);
      setSelectedLibraryItem({});
      Swal.fire({
      title: "Success",
      text: Result?.message,
      icon: "success",
    });
    }
  }else{
    alert("Please fill all details")
    // Swal.fire({
    //   title: "Error",
    //   text: "Please fill all details",
    //   icon: "error",
    // });
  }

  };
  const getAllActivitiesDetails = async (id) => {
    let result = await getAllActivities();
    setFetchActivies(result);   
  };
  useEffect(()=>{
    getAllActivitiesDetails()
  },[])
  const DrawerHeader = () => (
    <div style={{ display: "flex", justifyContent: "space-between",backgroundColor:"#FFEADC" }}>
      <div>
        <CardHeader
          title={
            formValue?.isEditForm
              ? "Edit Workout Library"
              : "Add Workout Library"
          }
          style={{color:"#E67E22",fontSize:"23px"}}
          
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "right", marginTop: "12px" }}
      >
        {/* <span>
          {formValue?.isEditForm ? (
            <Button style={{ color: "black" }}>
              {" "}
              {/*onClick={() => onDelete()} 
              <DeleteForeverIcon />
            </Button>
          ) : (
            ""
          )}
        </span> */}
        <span>
          <Button
            onClick={() => drawerStateChange(false)}
            style={{ color: "#E67E22",backgroundColor:"white !important" }}
            className="white-bg-btn"
          >
            <CloseIcon />
          </Button>
        </span>
      </div>
    </div>
  );

  return (
    <div>
      <div >
        <Button
          startIcon={<AddIcon />}
          onClick={() => drawerStateChange(true)}
          style={{ color: "white", backgroundColor: "#E67E22",fontSize:"10px" }}
        >
          {" "}
          {formValue?.isEditForm
            ? "Edit Workout Library"
            : "Add Workout Library"}
        </Button>
      </div>

      <Drawer
        anchor={"right"}
        open={workoutLibraryDrawerState}
        onClose={() => drawerStateChange(false)}
        PaperProps={{
          sx: { width: matches ? "100%" : "50%" },
        }}
      >
        <DrawerHeader />
        <Divider sx={{ margin: 0 }} />
        <form onSubmit={handleSubmit}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Workout Library Name"
                  placeholder="Walking"
                  InputLabelProps={{
                    shrink: Boolean(formValue["workout-library-name"]), // Set to true if value exists, otherwise false
                  }}
                  value={formValue["workout-library-name"]}
                  onChange={(e) => handleChange(e, "workout-library-name")}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="walkjog-tag-input">
                  <div>
                    <FormControl style={{ width: 400 }}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Tags
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={formValue?.tags || []}
                        onChange={(e) => handleChange(e, "tags")}
                        input={<Input />}
                       
                        MenuProps={MenuProps}
                        //style={{ minWidth: "400px" }}
                      >
                        {fetchAllActivities?.map((name) => (
                          <MenuItem
                            key={name?.activity_name}
                            value={name.id}
                            // style={getStyles(name, personName, theme)}
                          >
                            {name?.activity_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  style={{ backgroundColor: "#E67E22" }}
                  fullWidth
                >
                  {formValue?.isEditForm ? "Edit" : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      </Drawer>
    </div>
  );
};

export default WorkoutLibraryDrawer;
