import React, {  useEffect, useState } from "react";
import { Form,  Modal, Table, Tag, Button } from "antd";
import {  createRace, deleteRaceData, deleteswimmingPoolData,  getAllCities, getAllCountry, getAllRacesData,  getAllRenderSaveRaceData, getAllState, getAllSwimingData,  updateRaceData,  uploadsaveFile } from "../../API/api-endpoint";
import { ExclamationCircleFilled } from '@ant-design/icons';
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import {  FormControl, FormHelperText,  FormLabel, Grid, MenuItem, OutlinedInput, TextField, Autocomplete } from "@mui/material";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import CreateSwimming from "./CreateSwimming";
import SlickCarousel from "../SlickCarousel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactSelect from 'react-select';
import { IconEdit, IconTrash } from "@tabler/icons";
import { IoCloseSharp } from "react-icons/io5";



const SwimmingPage = () => {
  const { confirm } = Modal;

  const [allracesData, setAllRacesData] = useState();
  const [allSwimmingData, setAllSwimmingDat] = useState();
  const [editSwimmingPooldata, setEditSwimmingPooldata] = useState();

  const [allChalengesData, setAllchalanngesData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
  const [assignedChallengeModal, setShowAssignedChallengeModal] = useState(false);

  const [form] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [CountryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);


  const showAssigned = () => {
    setShowAssignedChallengeModal(true);
  };

  const showAssignUserModal = (name) => {
    setIsAssignUserModalOpen(true)

  }

  const fetchAllraces = async () => {
    const response = await getAllRacesData()

    if (response?.length > 0) {

      setAllRacesData(response)
    }
  }
  const fetchAllSwimmingData = async () => {
    const response = await getAllSwimingData()
    console.log("response", response);
    if (response?.length > 0) {

      setAllSwimmingDat(response)
    }
  }
  const fetchAllRacesData = async () => {
    const response = await getAllRenderSaveRaceData()
    console.log("response", response);
    setAllchalanngesData(response)
  }



  const handleDelete = async (record) => {
    const response = await deleteRaceData(record)
    if (response?.status === "success") {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
    }
    fetchAllraces()
  };
  const handleDeleteSwimmingPoolData = async (record) => {
    const response = await deleteswimmingPoolData(record)
    if (response?.status === "success") {
      Swal.fire({
        title: "Success",
        text: response.message,
        icon: "success",
      });
    }
    fetchAllSwimmingData()
  };
  const showDeleteConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this race?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const showDeleteSwimmingConfirm = (id) => {
    confirm({
      title: 'Are you sure delete this swimming pool data?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteSwimmingPoolData(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleOnSubmitForm = async (resetForm) => {
    console.log("formik.values", formik.values);
    // if (!isFormValid(formik.values)) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please fill all details",
    //     icon: "error",
    //   });
    // } else {

    try {
      setIsLoading(true)
      if (formik.values.race_id) {
        const Result = await updateRaceData(formik?.values);
        if (Result.status) {
          setIsLoading(false)
          Swal.fire({
            title: "Success",
            text: Result.message,
            icon: "success",
          });
          setIsModalOpen(false)
          fetchAllraces()
          formik.setValues()

          // if (resetForm) {
          formik.resetForm()
          // }
        }
        else {
          setIsLoading(false)
          Swal.fire({
            title: "Error",
            text: Result.message,
            icon: "error",
          });
          setIsModalOpen(false)

        }
      } else {

        const Result = await createRace(formik?.values);
        if (Result.status) {
          setIsLoading(false)
          Swal.fire({
            title: "Success",
            text: Result.message,
            icon: "success",
          });
          setIsModalOpen(false)
          fetchAllraces()
          formik.setValues()
          // if (resetForm) {
          formik.resetForm()
          // }
        }
        else {
          setIsLoading(false)
          Swal.fire({
            title: "Error",
            text: Result.message,
            icon: "error",
          });
        }
      }

    } catch (error) {
      setIsLoading(false)



      // }
    }

  };

  const formik = useFormik({
    initialValues: {
      race_name: "", is_featured: "", event_slug: "", race_type_id: "", race_website: "",
      race_city_id: "", distance_field_1: "", race_ratings: "", race_registration_link: "", created_by: "", created_at: ""
      ,race_state_id:"",race_country_id:"",image:"",unit:""
    }, validate: (values) => {
      const errors = {};
      if (!values.race_name) {
        errors.race_name = "Race name is required";
      }
      if (!values.race_website) {
        errors.race_website = "Website link is required";
      }
      if (!values.is_featured) {
        errors.is_featured = "Is featured is required";
      }
      if (!values.race_type_id) {
        errors.race_type_id = "Race type is required";
      }
      if (!values.race_type_id) {
        errors.race_type_id = "Race type is required";
      }

      if (!values.distance_field_1) {
        errors.distance_field_1 = "Distance is required";
      }
      if (!values.race_ratings) {
        errors.race_ratings = "Race rating is required";
      }else if (values.race_ratings < 1 || values.race_ratings > 5) {
        errors.race_ratings = "Race rating must be between 1 and 5";
    }
      if (!values.created_by) {
        errors.created_by = "Name is required";
      }
      if (!values.created_at) {
        errors.created_at = "Date is required";
      }
      if (!values.race_state_id) {
        errors.race_state_id = "Race state is required";
      }
      if (!values.race_city_id) {
        errors.race_city_id = "Race city is required";
      }
      if (!values.race_country_id) {
        errors.race_country_id = "Race country is required";
      }
      if (!values.image) {
        errors.image = "Image is required";
      }
      if (!values.unit) {
        errors.unit = "Distance units is required";
      }
      return errors;
    },
    // validationSchema: {},
    onSubmit: (values, { resetForm }) => {
      handleOnSubmitForm(values, resetForm)

    },
  });

  console.log("formik", formik?.values);
  useEffect(() => {
    fetchAllraces()
    fetchAllRacesData()
    fetchAllSwimmingData()
    getCountry()
  }, [])
  const filterOptions = (options, { inputValue }) => {
    return options.filter(option =>
      option.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };
  const filterStateOptions = (options, { inputValue }) => {
    return options.filter(option =>
      option.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };
  const filterCityOptions = (options, { inputValue }) => {
    return options.filter(option =>
      option.name.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };
  const handleCancel = () => {
    setEditData(null);
    form.resetFields();
    formik?.setValues({
      race_name: "", is_featured: "", event_slug: "", race_type_id: "", race_website: "",
      race_city_id: "", distance_field_1: "", race_ratings: "", race_registration_link: "", created_by: "", created_at: "", race_comments: "", yoska_program_link: ""
      ,race_state_id:"",race_city_id:"",race_country_id:"",image:"",unit:""
    })
    setIsModalOpen(false);

  };

  const options = allChalengesData?.users?.map((value) => ({
    label: value['firstname'],
    value: value['id'],
  })) || [];

  const showModal = () => {
    setEditData(null);
    setIsAssignUserModalOpen(false)

    if (editData === null) {
      form.resetFields();
    }
    formik?.setValues({
      race_name: "", is_featured: "", event_slug: "", race_type_id: "", race_website: "",
      race_city_id: "", distance_field_1: "", race_ratings: "", race_registration_link: "", created_by: "", created_at: "", race_comments: "", yoska_program_link: ""
      ,race_state_id:"",race_country_id:"",image:"",unit:""
    })
    // setValues(initialChallengeValues);
    setIsModalOpen(true);
  };

  const columns = [

    {
      title: "Race Name",
      dataIndex: "race_name",
      key: "race_name",

    },
    {
      title: "Race Type",
      dataIndex: "RaceType",
      key: "RaceType",
      render: (RaceType) => (
        <span>{`${RaceType?.race_type_name}`}</span>
      ),

    },
    {
      title: "Race City",
      dataIndex: "city",
      key: "city",
      render: (city) => (
        <span>{`${city?.name}`}</span>
      ),

    },
    {
      title: "Race Website",
      dataIndex: "race_website",
      key: "race_website",

    },
    {
      title: "Race Rating",
      dataIndex: "race_ratings",
      key: "race_ratings",

    },
    {
      title: "Status",
      dataIndex: "race_id",
      key: "race_id",
      render: (race_id) => (
        <div className="flex">
          <span className="px-2 cursor-pointer">
            <IconEdit
              color="blue"
              onClick={() => {
                let data = allracesData?.filter((ele) => ele?.race_id == race_id)
                const { city, user, ...alldata } = data[0]
                let showData = { ...alldata, id: race_id }
                console.log("showData", showData);
                fetchData(data[0]?.race_country_id, data[0]?.race_state_id)

                console.log("showData", showData);
                formik.setValues(showData)
                setIsModalOpen(true);

              }}
            />
          </span>
          <span>
            <IconTrash
              color="red"
              onClick={() => showDeleteConfirm(race_id)}
            />

          </span>
        </div>
      ),

    },

  ];
  const columnsSwiming = [

    {
      title: "ID",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Name",
      dataIndex: "swimming_pool_name",
      key: "swimming_pool_name",

    },
    {
      title: "Rating",
      dataIndex: "swimming_pool_rating",
      key: "swimming_pool_rating",

    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",

    },
    {
      title: "Contact Name",
      dataIndex: "contact_name",
      key: "contact_name",

    },
    {
      title: "Email ID",
      dataIndex: "email_id",
      key: "email_id",

    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",

    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",

    },
    {
      title: "Map Link",
      dataIndex: "map_link",
      key: "map_link",

    },
    {
      title: "Added By",
      dataIndex: "user",
      key: "user",
      render: (user) => (
        <span>{`${user?.firstname ? user?.firstname : "NA"} ${user?.lastname ? user?.lastname : ""}`}</span>
      ),

    },
    {
      title: "Status",
      dataIndex: "swimming_pool_id",
      key: "swimming_pool_id",
      render: (swimming_pool_id) => (
        <div className="flex">
          <span className="px-2 cursor-pointer">
            <IconEdit
              color="blue"
              onClick={() => {
                let data = allSwimmingData?.filter((ele) => ele?.swimming_pool_id == swimming_pool_id)
                const { Country, city, State, user, ...alldata } = data[0]
                let showData = { ...alldata, added_by: data[0]?.user?.id, id: swimming_pool_id }
                console.log("showData", showData);
                setShowAssignedChallengeModal(true)
                setEditSwimmingPooldata(showData)

              }}
            />
          </span>
          <span>
            <IconTrash
              color="red"
              onClick={() => showDeleteSwimmingConfirm(swimming_pool_id)}
            />

          </span>
        </div>
      ),

    },

  ];
  const getCountry = async () => {
    let response = await getAllCountry();
    setCountryList(response);
  };
  let fetchData = async (country, state) => {
    const response = await getAllState(country);
    console.log("country", response);
    setStateList(response);
    const responsecity = await getAllCities(country, state);
    setCityList(responsecity);
  }
  const handleCountryChange = async (event, newValue) => {
    const countryId = newValue;
    formik?.setFieldValue("race_country_id", countryId?.id)
    const response = await getAllState(countryId?.id);
    setStateList(response);
  };
  const handleStateChange = async (event, newValue) => {
    console.log("newValue", newValue);
    const stateID = newValue;
    formik?.setFieldValue("race_state_id", stateID?.id)

    // Fetch states based on the selected country ID
    const response = await getAllCities
      (formik?.values?.race_country_id, stateID?.id);
    setCityList(response);
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      const responst = await uploadsaveFile(formData);
      if (responst?.status) {
        formik.setFieldValue("image", responst?.file);
      }
    }
  };
  return (
    <div>
      <Header />
      <div className="grid grid-cols-1 xl:grid-cols-5 items-start gap-x-6"></div>
      <div style={{ marginTop: "100px", padding: "20px" }}>
        <div className="add-challenges-modal">
          <Button type="primary" onClick={showModal} style={{ marginTop: "10px" }}>
            Add a new Race
          </Button>
          &nbsp;
          <Button type="primary" onClick={showAssigned} style={{ marginTop: "10px" }}>
            Add a new Swimming Pool
          </Button>
          &nbsp;
          <Button type="primary" onClick={() => showAssignUserModal("User")} style={{ marginTop: "10px" }}>
            Get Swimming Pools
          </Button>

          &nbsp;
          <Modal
            width={1200}
            // title={editData ? "Edit new Race" : "Add new Race"}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={
              <div></div>
              //   loading={isLoading}
            }


          >
            <div className="headingCont">
              <span className="heading">{editData ? "Edit " : "Add"}</span>{" "}
              <span className="orange heading">New Race</span>
            </div>
            <div className="parentCont">
              <form className="form1" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Race Name:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Race Name"
                      size="small"
                      type="text"
                      name="race_name"
                      value={formik?.values?.race_name}
                      onChange={formik.handleChange}
                      error={formik.touched.race_name && formik.errors.race_name}
                      helperText={
                        formik.touched.race_name && formik.errors.race_name
                      }
                    />
                  </Grid>


                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Is Featured:</FormLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      name="is_featured"
                      value={formik?.values?.is_featured}
                      onChange={
                        (event) => {
                          formik.setFieldValue("is_featured", event.target.value)
                        }}
                      error={formik.touched.is_featured && formik.errors.is_featured}
                      helperText={
                        formik.touched.is_featured && formik.errors.is_featured
                      }
                      id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={<OutlinedInput id="select-multiple-language" />}
                    >
                      <MenuItem value={true} >
                        Yes
                      </MenuItem>
                      <MenuItem value={"false"} >
                        No
                      </MenuItem>

                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Event Slug:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Event Slug"
                      size="small"
                      type="text"
                      name="event_slug"
                      value={formik?.values?.event_slug}
                      onChange={formik.handleChange}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Race Type:</FormLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      name="race_type_id"
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              scrollbarColor: "#E67E22 white",
                              scrollbarWidth: "thin"
                            },
                          },
                        },
                      }}
                      value={formik?.values?.race_type_id || (allChalengesData?.racetypes?.length > 0 ? "TextField Activity" : '')}
                      onChange={
                        (event) => {
                          formik.setFieldValue("race_type_id", event.target.value)

                        }}
                      error={formik.touched.race_type_id && formik.errors.race_type_id}
                      helperText={
                        formik.touched.race_type_id && formik.errors.race_type_id
                      }
                      id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={<OutlinedInput id="select-multiple-language" />}
                    >
                      <MenuItem value={""} disabled>
                        Select Activity
                      </MenuItem>
                      {allChalengesData?.racetypes?.map((value, index) => {
                        return (
                          <MenuItem value={value["race_type_id"]}>
                            {value["race_type_name"]}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={3.5} >


                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={CountryList}
                      getOptionLabel={(option) => option.name}
                      value={CountryList.find((option) => option.id === formik?.values?.race_country_id) || null}
                      onChange={handleCountryChange}
                      filterOptions={filterOptions}
                      className="running-step-input1"
                      renderInput={(params) => <TextField {...params} label={!formik?.values?.race_country_id && 'Country*'} />}
                    />
                    {formik.touched.race_country_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.race_country_id}</div>
                  }
                  </Grid>
                  <Grid item xs={12} sm={3.5} >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      filterOptions={filterStateOptions}
                      options={stateList}
                      getOptionLabel={(option) => option.name}
                      value={stateList.find((option) => option.id === formik?.values.race_state_id) || null}
                      onChange={handleStateChange}
                      className="running-step-input1"
                      renderInput={(params) => <TextField {...params} label={!formik?.values?.race_state_id && 'State*'} />}
                    />
                     {formik.touched.race_state_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.race_state_id}</div>
                  }
                  </Grid>
                  <Grid item xs={12} sm={4} >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={cityList}
                      filterOptions={filterCityOptions}
                      getOptionLabel={(option) => option.name}
                      value={cityList.find((option) => option.id === formik?.values.race_city_id) || null}
                      onChange={(e, newValue) => {
                        formik?.setFieldValue("race_city_id", newValue?.id)
                      }}
                      className="running-step-input1"
                      renderInput={(params) => <TextField {...params} label={!formik?.values?.race_city_id && 'City*'} />}
                    />
                     {formik.touched.race_city_id &&
                    <div style={{color:"#d32f2f"}}>{ formik.errors.race_city_id}</div>
                  }
                  </Grid>




                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Distance:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Distance"
                      size="small"
                      type="text"
                      name="distance_field_1"
                      value={formik?.values?.distance_field_1}
                      onChange={formik.handleChange}
                      error={formik.touched.distance_field_1 && formik.errors.distance_field_1}
                      helperText={
                        formik.touched.distance_field_1 && formik.errors.distance_field_1
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Distance Unit:</FormLabel>
                    <TextField
                      fullWidth
                      size="small"
                      select
                      name="unit"
                      value={formik?.values?.unit}
                      onChange={
                        (event) => {
                          formik.setFieldValue("unit", event.target.value)
                        }}
                      error={formik.touched.unit && formik.errors.unit}
                      helperText={
                        formik.touched.unit && formik.errors.unit
                      }
                      id="form-layouts-separator-select"
                      labelId="form-layouts-separator-select-label"
                      input={<OutlinedInput id="select-multiple-language" />}
                    >
                      <MenuItem value={"km"} >
                        KM
                      </MenuItem>
                      

                    </TextField>
                  </Grid>



                  

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Race Registration Link:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Race Registration Link"
                      size="small"
                      type="text"
                      name="race_registration_link"
                      value={formik?.values?.race_registration_link}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Race Website:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Race Website"
                      size="small"
                      type="text"
                      name="race_website"
                      value={formik?.values?.race_website}
                      onChange={formik.handleChange}
                      error={formik.touched.race_website && formik.errors.race_website}
                      helperText={
                        formik.touched.race_website && formik.errors.race_website
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Yoska Program Link:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Yoska Program Link"
                      size="small"
                      type="text"
                      name="yoska_program_link"
                      value={formik?.values?.yoska_program_link}
                      onChange={formik.handleChange}
                    />
                  </Grid>



                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Created By:</FormLabel>
                    <FormControl
                      fullWidth
                      error={formik.touched.created_by && Boolean(formik.errors.created_by)}
                    >
                      <ReactSelect
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderColor: formik.touched.created_by && formik.errors.created_by ? 'red' : provided.borderColor,
                            '&:hover': {
                              borderColor: formik.touched.created_by && formik.errors.created_by ? 'red' : provided['&:hover'].borderColor,
                            },
                            // boxShadow: formik.touched.created_by && formik.errors.created_by ? '0 0 0 1px red' : provided.boxShadow,
                          }),
                          menu: (provided, state) => ({
                            ...provided,
                            scrollbarColor: '#E67E22 white',
                            scrollbarWidth: 'thin',
                            '&::-webkit-scrollbar': {
                              width: '12px',
                            },
                            '&::-webkit-scrollbar-track': {
                              background: 'white',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#E67E22',
                              borderRadius: '20px',
                              border: '3px solid transparent',
                              backgroundClip: 'content-box',
                            },
                          }),
                        }}
                        name="created_by"
                        classNamePrefix="custom-select"
                        //   isMulti
                        // value={formik?.values?.created_by}
                        options={options}
                        value={options.find(option => option?.value === formik?.values?.created_by) || []}
                        onChange={(selectedOption) => {
                          formik.setFieldValue('created_by', selectedOption ? selectedOption.value : '');
                        }}

                        placeholder="Select Person"
                        isClearable
                      />
                      {formik.touched.created_by && formik.errors.created_by ? (
                        <FormHelperText>{formik.errors.created_by}</FormHelperText>
                      ) : null}
                    </FormControl>
                    {/* <TextField
                    fullWidth
                    size="small"
                    select
                    name="created_by"
                    value={formik?.values?.created_by }
                    onChange={
                      (event) => {
                        formik.setFieldValue("created_by", event.target.value)

                      }}
                      error={formik.touched.created_by && formik.errors.created_by}
                      helperText={
                        formik.touched.created_by && formik.errors.created_by
                      }
                    id="form-layouts-separator-select"
                    labelId="form-layouts-separator-select-label"
                    input={<OutlinedInput id="select-multiple-language" />}
                  >
                    <MenuItem value={""} disabled>
                      Select Activity
                    </MenuItem>
                    {allChalengesData?.users?.map((value, index) => {
                      return (
                        <MenuItem value={value?.id}>
                          {value?.firstname}
                        </MenuItem>
                      );
                    })}
                  </TextField> */}
                  </Grid>

                  <Grid className="dateWrap" item xs={12} sm={5.5} >
                    <FormLabel >Race Commence Date:</FormLabel>
                    <DatePicker
                      selected={formik.values.created_at ? new Date(formik.values.created_at) : null}
                      onChange={(date) => {
                        formik.setFieldValue(
                          "created_at",
                          moment(date).format("YYYY-MM-DD")
                        );
                      }}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="DD-MM-YYYY"
                      minDate={new Date()}
                      customInput={
                        <TextField
                          fullWidth
                          placeholder="Created At"
                          size="small"
                          type="text"
                          name="created_at"
                          error={formik.touched.created_at && formik.errors.created_at}
                          helperText={formik.touched.created_at && formik.errors.created_at}
                        />
                      }
                    />

                  </Grid>
                  <Grid item xs={12} sm={5.5} >
                    <FormLabel >Race Ratings:</FormLabel>
                    <TextField
                      fullWidth
                      placeholder="Race Ratings"
                      size="small"
                      type="text"
                      name="race_ratings"
                      value={formik?.values?.race_ratings}
                      onChange={formik.handleChange}
                      error={formik.touched.race_ratings && formik.errors.race_ratings}
                      helperText={
                        formik.touched.race_ratings && formik.errors.race_ratings
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={11} >
                    <FormLabel >Description:</FormLabel>
                    <ReactQuill
                      style={{ height: "50%", borderRadius: "10px !important" }}
                      value={formik?.values?.race_comments}
                      name="race_comments"
                      onChange={(value) => {
                        const trimmedValue = value.trimStart();
                        formik.setFieldValue("race_comments", trimmedValue);
                      }}
                      modules={{
                        toolbar: [
                          [{ header: "1" }, { header: "2" }, { font: [] }],
                          [{ size: [] }],
                          ["bold", "italic", "underline", "strike", "blockquote"],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          ["link", "image", "video"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={11} ></Grid>
                  <Grid item xs={12} sm={11} className="relative">
                    <FormLabel>Upload Image<span className="text-[red]">*</span></FormLabel>

                    <TextField
                      fullWidth
                      placeholder="Zone"
                      size="small"
                      type={formik?.values?.image ? "text" : "file"}
                      name="image"
                      disabled={formik?.values?.image}
                      value={formik?.values?.image}
                      onChange={handleFileUpload}
                      error={formik.touched.image && formik.errors.image}
                      helperText={formik.touched.image && formik.errors.image}
                      inputProps={{
                        accept: ".jpg, .png .jpeg", // Specify accepted file formats here
                      }}
                    />
                    {formik?.values?.image && (
                      <IoCloseSharp
                        onClick={() => {
                          formik.setFieldValue("image", "");
                        }}
                        color="darkgray"
                        className="cursor-pointer absolute top-[5px] right-[-10px]"
                        style={{ fontSize: "24px" }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      className="btn"
                      key="submit"
                      type="primary"
                      onClick={() => formik.handleSubmit()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <div className="slick-container">
                <SlickCarousel />
              </div>
            </div>
          </Modal>
        </div>
        &nbsp;
        {assignedChallengeModal &&
          <CreateSwimming setEditSwimmingPooldata={setEditSwimmingPooldata} editSwimmingPooldata={editSwimmingPooldata} fetchAllSwimmingData={fetchAllSwimmingData} allChalengesData={allChalengesData} assignedChallengeModal={assignedChallengeModal} setShowAssignedChallengeModal={setShowAssignedChallengeModal} />
        }

        {!isAssignUserModalOpen && (
          <div>
            <Table
              columns={columns?.map((col, index) => ({
                ...col,
                onHeaderCell: () => ({ style: { fontWeight: "bold" } }),
                
              }))}
              dataSource={allracesData?.map((race, index) => ({
                ...race,
                index: index + 1,
              }))}
              pagination={true}
              scroll={{ x: 'max-content' }}
            />
          </div>

        )}
        {isAssignUserModalOpen &&
          <div>
            <Table
              columns={columnsSwiming?.map((col, index) => ({
                ...col,
                onHeaderCell: () => ({ style: { fontWeight: "bold" } }),
              }))}
              dataSource={allSwimmingData?.map((race, index) => ({
                ...race,
                index: index + 1,
              }))}

              pagination={true}
              scroll={{ x: 'max-content' }}
            />
          </div>
        }

      </div>
    </div>
  );
};

export default SwimmingPage;

